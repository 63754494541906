import { Icon, Theme } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function SideNavItemTile({ sideNavItem, selected, itemKey, handleClick, enabled = true }: {
    sideNavItem: {
        icon: string;
        label: string;
        href: string;
    },
    selected?: boolean,
    itemKey: number,
    handleClick: (targetId: string) => void,
    enabled?: boolean,
}): JSX.Element {
    return (
        <MDBox key={`item-${itemKey}`} component="li" pt={itemKey === 0 ? 0 : 1}>
            <MDTypography
                component="a"
                variant="button"
                fontWeight="regular"
                textTransform="capitalize"
                onClick={enabled ? () => handleClick(sideNavItem.href) : undefined}
                style={{
                    color: enabled ? 'dark' : 'gray',
                    cursor: "pointer"
                }}
                sx={({
                    borders: { borderRadius },
                    functions: { pxToRem },
                    palette: { light },
                    transitions,
                }: Theme) => ({
                    display: "flex",
                    alignItems: "center",
                    borderRadius: borderRadius.md,
                    padding: `${pxToRem(10)} ${pxToRem(16)}`,
                    transition: transitions.create("background-color", {
                        easing: transitions.easing.easeInOut,
                        duration: transitions.duration.shorter,
                    }),
                    backgroundColor: selected ? light.main : null,
                    "&:hover": {
                        backgroundColor: light.main,
                    },
                })}
            >
                <MDBox mr={1.5} lineHeight={1} color={enabled ? 'dark' : 'gray'}>
                    <Icon fontSize="small">{sideNavItem.icon}</Icon>
                </MDBox>
                {sideNavItem.label}
            </MDTypography>
        </MDBox>
    );
}

export default SideNavItemTile;