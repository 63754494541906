import { Box, DialogContent, DialogContentText } from "@mui/material";
import video from "assets/forge/videos/relationship5.mp4";

function Relationship5Slide({ }: {}) {

    return (
        <DialogContent>
            <Box style={{
                textAlign: "center"
            }} >
                <DialogContentText align='center' display="inline">
                    {"Then discover their real problems and"}
                </DialogContentText>
                <DialogContentText color="#3D79EE" fontWeight="bold" align='center' display="inline">
                    {" solve them "}
                </DialogContentText>
            </Box>
            <video
                autoPlay
                muted
                loop
                style={{
                    height: "356px",
                    aspectRatio: 16 / 9,
                    width: "50%",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                }} >
                <source src={video} type="video/mp4" />
            </video>
        </DialogContent>
    );
};

export default Relationship5Slide;