import {
  doc,
  collection,
  getDoc,
  getDocs,
  query,
  orderBy,
  where,
  onSnapshot,
  Unsubscribe,
} from "firebase/firestore";
import { firestoreDb } from "firebase.init";
import { ForgeOpportunity } from "types/pipeline/opportunity";
import { User } from "firebase/auth";
import { UserProfileData } from "types/user/user-profile-data";
import { firestoreDebounce } from "forge/core/utils/firestore";
import { ForgeEncryption } from "forge/core/services/encryption";

class PipelineFirestoreService {
  private user: User;
  private userProfileData: UserProfileData;
  private encryptionService: ForgeEncryption;

  constructor(user: User, userProfileData: UserProfileData, encryptionService: ForgeEncryption) {
    this.user = user;
    this.userProfileData = userProfileData;
    this.encryptionService = encryptionService;
  }

  getPersonalOpportunitiesLive = (
    onEvent: (opportunities: ForgeOpportunity[]) => void
  ): Unsubscribe => {
    const collectionRef = query(
      collection(firestoreDb, `opportunities`),
      where("teamUsersIds", "array-contains", this.user.uid),
      orderBy("updatedAt", "desc")
    );

    return onSnapshot(collectionRef, async (snapshot) => {
      const opportunitiesPromises = snapshot.docs.map((doc) =>
        (ForgeOpportunity.fromMap(doc.data(), this.encryptionService))
      );

      let decrypted = await Promise.allSettled(opportunitiesPromises);
      const opportunities: ForgeOpportunity[] = decrypted
        .filter((e: any) => e.status === "fulfilled")
        .map((e: any) => e.value);

      onEvent(opportunities);
    });
  }

  getOrganizationOpportunitiesLive = (
    onEvent: (opportunities: ForgeOpportunity[]) => void
  ): Unsubscribe => {
    if (this.userProfileData?.organization?.id) {
      const collectionRef = query(
        collection(firestoreDb, `organizations/${this.userProfileData?.organization?.id}/opportunities`),
        orderBy("updatedAt", "desc")
      );

      return firestoreDebounce(
        collectionRef,
        async (snapshot) => {
          const opportunitiesPromises = snapshot.docs.map((doc) =>
            (ForgeOpportunity.fromMap(doc.data(), this.encryptionService))
          );

          let decrypted = await Promise.allSettled(opportunitiesPromises);
          const opportunities: ForgeOpportunity[] = decrypted
            .filter((e: any) => e.status === "fulfilled")
            .map((e: any) => e.value);

          onEvent(opportunities);
        }
      );
    }
  }

  getOpportunity = (
    opportunityRefPath: string,
    onEvent: (opportunities: ForgeOpportunity) => void
  ): Unsubscribe => {
    const docRef = doc(firestoreDb, opportunityRefPath);

    return onSnapshot(docRef, async (snapshot) => {
      if (snapshot.exists()) {
        try {
          let newOpportunity = await ForgeOpportunity.fromMap(snapshot.data(), this.encryptionService);
          console.log(newOpportunity);
          onEvent(newOpportunity);
        } catch (error) {
          console.error(error);
        }
      }
    });
  }
}

export default PipelineFirestoreService;