import { createContext, useContext, useState, useEffect, useRef } from "react";
import { AuthContext, ContactsContext } from "context";
import { Commitment } from "types/commitment";
import CommitmentsFirestore from "forge/commitments/services/firestore";

interface CommitmentsContextType {
    commitments: Commitment[];
    pastDueCommitments: Commitment[];
}

export const CommitmentsContext = createContext<CommitmentsContextType>({
    commitments: [],
    pastDueCommitments: [],
});

export const CommitmentsContextProvider = ({ contact, opportunityId, children }: { contact?: any, opportunityId?: string, children: any }) => {
    const { getCurrentUser, isEncryptionInitialized } = useContext(AuthContext);
    const { contacts, getContacts } = useContext(ContactsContext);
    const contactsContextRef = useRef(getContacts); // Ref to hold the context value

    // Services
    const { user, encryptionService, userProfileData } = getCurrentUser();
    const commitmentsFirestoreService = new CommitmentsFirestore(user, userProfileData, encryptionService)

    // State
    const [loading, setLoading] = useState<boolean>(true);
    const [commitments, setCommitments] = useState<Commitment[]>([]);
    const [personalCommitments, setPersonalCommitments] = useState<Commitment[]>([]);
    const [organizationCommitments, setOrganizationCommitments] = useState<Commitment[]>([]);
    const [pastDueCommitments, setPastDueCommitments] = useState<Commitment[]>([]);

    useEffect(() => {
        setCommitments([...personalCommitments, ...organizationCommitments]);
    }, [personalCommitments, organizationCommitments]);

    useEffect(() => {
        const unsubscribe = commitmentsFirestoreService.getPersonalCommitmentsLive(
            (newCommitments) => {
                newCommitments = handleRecurrentCommitments(newCommitments);
                setPersonalCommitments(newCommitments)
            },
            contact?.ref,
            opportunityId,
        );

        return () => unsubscribe();
    }, [isEncryptionInitialized, contact?.ref]);

    useEffect(() => {
        if (userProfileData?.organization?.id) {
            const unsubscribe = commitmentsFirestoreService.getOrganizationCommitmentsLive(
                (newCommitments) => {
                    newCommitments = handleRecurrentCommitments(newCommitments);
                    setOrganizationCommitments(newCommitments)
                },
                contact?.ref,
                opportunityId,
            );

            return () => unsubscribe();
        }
    }, [contact?.ref, userProfileData?.organization?.id]);

    useEffect(() => {
        contactsContextRef.current = getContacts; // Update the ref whenever the context value changes
        setPersonalCommitments(handleRecurrentCommitments(personalCommitments));
        setOrganizationCommitments(handleRecurrentCommitments(organizationCommitments));
    }, [contacts]);

    useEffect(() => {
        setPastDueCommitments(commitments.filter((e) => e.startDate.toDate() < new Date()));
    }, [commitments]);

    const handleRecurrentCommitments = (commitments: Commitment[]): Commitment[] => {
        // ---------------------Handle Recurrent Events-----------------------
        let childCommitmentsToAdd: Commitment[] = [];
        let commitmentsToRemove: Commitment[] = [];

        for (let commitment of commitments) {
            commitment = matchContacts(commitment);
            if (commitment.childCommitments !== null && commitment.childCommitments.length > 0) {
                // Remove Parent Commitment
                commitmentsToRemove.push(commitment);

                for (let i = 0; i < commitment.childCommitments.length; i++) {
                    const childCommitment = commitment.childCommitments[i];
                    const daysDifference = Math.floor((new Date(childCommitment.startDate.toDate()).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
                    if (daysDifference < 7) {
                        childCommitment.name = commitment.name;
                        childCommitment.isChildCommitment = true;
                        childCommitment.childIndex = i;
                        childCommitment.contacts = commitment.contacts;
                        childCommitmentsToAdd.push(childCommitment);
                    }
                }
            }
        }

        for (const commitmentToRemove of commitmentsToRemove) {
            commitments = commitments.filter(commitment => commitment !== commitmentToRemove);
        }

        commitments = commitments.concat(childCommitmentsToAdd);
        commitments.sort((a, b) => a.startDate.toDate().getTime() - b.startDate.toDate().getTime());
        commitments = commitments.filter(commitment => !commitment.isArchived);
        // -------------------------------------------------------------------

        return commitments;
    }

    const matchContacts = (commitment: Commitment): Commitment => {
        commitment.contacts = contactsContextRef.current(commitment.contactRefs.map(e => e.id))
        return commitment;
    }

    return (
        <CommitmentsContext.Provider
            value={{ commitments, pastDueCommitments }}
        >
            {children}
        </CommitmentsContext.Provider>
    );
};