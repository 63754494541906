import { DocumentReference, Timestamp, doc } from "firebase/firestore";
import { ForgeEvent } from "types/forge-event";
import { ForgeOpportunity } from "./opportunity";
import { firestoreDb } from "firebase.init";
import { ForgeEncryption } from "forge/core/services/encryption";

export class Call extends ForgeEvent {
    subject: string;
    details: string;
    date: Date;
    contactRefs: DocumentReference[];
    opportunity?: ForgeOpportunity;

    /// If the event is related to an opportunity, a call can be created from
    /// the event card when this one has passed. Keep a reference to the original
    /// event.
    eventRef: DocumentReference;

    isOrganizationCall?: boolean;

    constructor({
        ref,
        subject,
        details,
        date,
        contacts,
        opportunityId,
        contactRefs = [],
        opportunity,
        isProcessing = false,
        eventRef,
        isOrganizationCall = false,
    }: {
        ref?: DocumentReference;
        subject: string;
        details: string;
        date: Date;
        contacts: any[];
        opportunityId?: string;
        contactRefs?: DocumentReference[];
        opportunity?: ForgeOpportunity;
        isProcessing?: boolean;
        eventRef?: DocumentReference;
        isOrganizationCall?: boolean;
    }) {
        super(
            subject,
            Timestamp.fromDate(date),
            isProcessing,
            ref,
            Timestamp.fromDate(date),
            contacts,
            opportunityId
        );
        this.subject = subject;
        this.details = details;
        this.date = date;
        this.contactRefs = contactRefs;
        this.opportunity = opportunity;
        this.eventRef = eventRef;
        this.isOrganizationCall = isOrganizationCall;
    }

    copyWith({
        ref,
        subject,
        details,
        date,
        contacts,
        contactRefs,
        opportunityId,
        opportunity,
        isProcessing,
        eventRef,
        isOrganizationCall
    }: {
        ref?: DocumentReference;
        subject?: string;
        details?: string;
        date?: Date;
        contacts?: any[];
        contactRefs?: DocumentReference[];
        opportunityId?: string;
        opportunity?: ForgeOpportunity;
        isProcessing?: boolean;
        eventRef?: DocumentReference;
        isOrganizationCall?: boolean;
    }): Call {
        return new Call({
            ref: ref ?? this.ref,
            subject: subject ?? this.subject,
            details: details ?? this.details,
            date: date ?? this.date,
            contacts: contacts ?? this.contacts,
            contactRefs: contactRefs ?? this.contactRefs,
            opportunityId: opportunityId ?? this.opportunityId,
            opportunity: opportunity ?? this.opportunity,
            isProcessing: isProcessing ?? this.isProcessing,
            eventRef: eventRef ?? this.eventRef,
            isOrganizationCall: isOrganizationCall ?? this.isOrganizationCall,
        });
    }

    toMap(): {
        [key: string]: any;
    } {
        return {
            ref: this.ref?.path,
            subject: this.subject,
            details: this.details,
            date: this.date.getTime(),
            contacts: this.contacts.map((x) => (x.ref?.path)),
            opportunityId: this.opportunityId,
            isProcessing: this.isProcessing,
            eventRef: this.eventRef?.path,
        };
    }

    static async fromMap(
        map: { [key: string]: any; },
        encryptionService: ForgeEncryption,
    ): Promise<Call> {
        const isEncrypted = map.encrypted ?? false;

        const subject = isEncrypted
            ? await encryptionService.decrypt({
                data: map.subject,
                encrypter: map.encrypter
            }) ?? ''
            : map.subject;

        const details = isEncrypted
            ? await encryptionService.decrypt({
                data: map.details,
                encrypter: map.encrypter
            }) ?? ''
            : map.details;

        return new Call({
            ref: map.ref instanceof DocumentReference
                ? map.ref
                : doc(firestoreDb, map.ref),
            subject,
            details,
            date: map.date ? (map.date instanceof Timestamp ? map.date.toDate() : new Date(map.date)) : null,
            contacts: [],
            contactRefs: map.contacts ?? [],
            opportunityId: map.opportunityId,
            isProcessing: map.isProcessing ?? false,
            eventRef: map.eventRef ? map.eventRef instanceof DocumentReference
                ? map.eventRef
                : doc(firestoreDb, map.eventRef) : undefined,
            isOrganizationCall: map.isOrganizationCall ?? false,
        });
    }
}
