import { useContext, useState } from "react";
import { createUseStyles } from "react-jss";
import { AuthContext, ContactsContext } from "context";

// MUI
import {
    Backdrop,
    Divider,
    Drawer,
    FormControl,
    Icon,
    Stack,
    useMediaQuery,
} from "@mui/material";

// Material Dashboard
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Images
import spinningSphere from "assets/forge/gifs/spinning-sphere.gif";
import theme from "assets/theme";
import ContactsApi from "../../services/api";
import SelectContact from "forge/core/components/SelectContact";

function MergeContactsDrawer({ openDrawer, handleCloseDrawer, contact }: { openDrawer: boolean, handleCloseDrawer: () => void, contact: any }) {
    // UI
    const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const largeScreen = useMediaQuery(theme.breakpoints.up("lg"));

    // Context
    const { getCurrentUser } = useContext(AuthContext);

    // Services
    const { user, encryptionService, userProfileData } = getCurrentUser();
    const contactsApi = new ContactsApi(user, userProfileData, encryptionService)

    // State
    const [loading, setLoading] = useState<boolean>(false);
    const [contactsToMerge, setContactsToMerge] = useState<any[]>([]);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setLoading(true);

        try {
            if (contactsToMerge.length > 0) {
                await contactsApi.mergeContact(contact, contactsToMerge);
            }
        } catch (error) {
            console.error(error);
        }

        setLoading(false);
        handleCloseDrawer();
    };

    const onContactSelected = (contact: any) => {
        setContactsToMerge([...contactsToMerge, contact]);
    }

    const onContactUnselected = (contact: any) => {
        let toMerge = [...contactsToMerge];
        toMerge = toMerge.filter(e => e.ref?.id !== contact.ref?.id)
        setContactsToMerge(toMerge);
    }

    return (
        <Drawer
            anchor="right"
            open={openDrawer}
            onClose={handleCloseDrawer}
            PaperProps={{
                sx: {
                    height: "fit-content",
                    maxHeight: "96%",
                    width: largeScreen ? "40%" : mediumScreen ? "70%" : "90%",
                },
            }}
        >
            <FormControl component="form" onSubmit={handleSubmit} style={{ display: "contents" }}>
                <Backdrop
                    sx={{ background: 'rgba(255, 255, 255, 0.5)', zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute' }}
                    open={loading}
                >
                    <img src={spinningSphere} style={{ width: "200px" }} alt="Loading..." />
                </Backdrop>

                {/* Header */}
                < MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="baseline"
                    pt={4}
                    pb={0.5}
                    px={3}
                    style={{ background: "#F7F8FB" }}
                >
                    <MDBox>
                        <MDTypography variant="h5">Merge Contact</MDTypography>
                    </MDBox>
                    <MDButton variant="gradient" color="info" type="submit">Merge</MDButton>
                </MDBox >
                <MDBox pt={1} style={{ background: "#F7F8FB" }} />
                <Divider style={{ background: "#F7F8FB", margin: 0, opacity: 1 }} />
                <MDBox pt={2} style={{ background: "#F7F8FB" }} />

                {/* Body */}
                <div style={{ background: "#F7F8FB" }}>
                    <SelectContact
                        onContactSelected={onContactSelected}
                        onContactUnselected={onContactUnselected}
                        filteredIds={[contact?.ref?.id]}
                    />
                </div>
            </FormControl>
        </Drawer >
    );
}

// Setting default values for the props of MergeContactsDrawer
MergeContactsDrawer.defaultProps = {
    handleCloseDrawer: null,
};

export default MergeContactsDrawer;