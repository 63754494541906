export class OutlookRecurrence {
    pattern: Record<string, any>;
    range: Record<string, any>;

    constructor({
        pattern,
        range,
    }: {
        pattern: Record<string, any>;
        range: Record<string, any>;
    }) {
        this.pattern = pattern;
        this.range = range;
    }

    copyWith({
        pattern,
        range,
    }: {
        pattern?: Record<string, any>;
        range?: Record<string, any>;
    }): OutlookRecurrence {
        return new OutlookRecurrence({
            pattern: pattern ?? this.pattern,
            range: range ?? this.range,
        });
    }

    toMap(): Record<string, any> {
        return {
            'pattern': this.pattern,
            'range': this.range,
        };
    }

    static fromMap(map: Record<string, any>): OutlookRecurrence {
        return new OutlookRecurrence({
            pattern: map['pattern'],
            range: map['range'],
        });
    }
}
