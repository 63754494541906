/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, ReactNode, useContext } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavList from "examples/Sidenav/SidenavList";
import SidenavItem from "examples/Sidenav/SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";
import forgeLogo from "assets/forge/images/forge.png";
import forgeFLogo from "assets/forge/images/forge_f.png";
import { SealdSDK } from "@seald-io/sdk";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  AuthContext,
  setFixedNavbar,
} from "context";
import { Add, EmojiObjects, EmojiObjectsOutlined, Handshake, HandshakeOutlined, MenuOpen, TaskAlt } from "@mui/icons-material";
import { Box, Drawer, IconButton, Stack, useMediaQuery } from "@mui/material";
import theme from "assets/theme";
import CreateOpportunity from "forge/pipeline/opportunities/components/CreateOpportunity";
import CreateCommitmentDrawer from "forge/commitments/components/CreateCommitmentDrawer";
import AddKnowledgeDrawer from "forge/knowledge/components/AddKnowledgeDrawer";
import ConfirmationDialog from "forge/core/components/ConfirmationDialog";
import { AbilityContext } from "forge/organization/rbac/CanContext";
import { useAbility } from "@casl/react";

// Declaring props types for Sidenav
interface Props {
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
  brand?: string;
  brandName: string;
  routes: {
    [key: string]:
    | ReactNode
    | string
    | {
      [key: string]:
      | ReactNode
      | string
      | {
        [key: string]: ReactNode | string;
      }[];
    }[];
  }[];
  [key: string]: any;
}

function Sidenav({ color, brand, brandName, routes, handleMiniSidenavParent, ...rest }: Props): JSX.Element {
  const { getCurrentUser, logout } = useContext(AuthContext);
  const [collapsedSidenav, setCollapsedSidenav] = useState<boolean>(false);
  const [openCollapse, setOpenCollapse] = useState<boolean | string>(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState<boolean | string>(false);
  const [controller, dispatch] = useMaterialUIController();
  const { fixedNavbar, miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];

  // Context
  const ability = useAbility(AbilityContext);

  // Actions - Opportunity
  const [openSaveInsightDrawer, setOpenSaveInsightDrawer] = useState(false);
  const handleOpenInsightDrawer = () => setOpenSaveInsightDrawer(true);
  const handleCloseInsightDrawer = () => setOpenSaveInsightDrawer(false);

  // Actions - Opportunity
  const [openCreateCommitmentDrawer, setOpenCreateCommitmentDrawer] = useState(false);
  const handleOpenCommitmentDrawer = () => setOpenCreateCommitmentDrawer(true);
  const handleCloseCommitmentDrawer = () => setOpenCreateCommitmentDrawer(false);

  // Actions - Opportunity
  const [openCreateOpportunityDrawer, setOpenCreateOpportunityDrawer] = useState(false);
  const handleOpenOpportunityDrawer = () => setOpenCreateOpportunityDrawer(true);
  const handleCloseOpportunityDrawer = () => setOpenCreateOpportunityDrawer(false);

  // Logout Dialog
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const handleOpenLogoutDialog = () => setOpenLogoutDialog(true);

  const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  let textColor:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "dark"
    | "white"
    | "inherit"
    | "text"
    | "light" = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, []);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse: any) => {
    const template = collapse.map(({ name, route, key, href }: any) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} style={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses: any) =>
    collapses.map(({ name, collapse, route, href, key }: any) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            color={color}
            name={name}
            active={key === itemParentName ? "isParent" : false}
            open={openNestedCollapse === key}
            onClick={({ currentTarget }: any) =>
              openNestedCollapse === key && currentTarget.classList.contains("MuiListItem-root")
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem color={color} name={name} active={key === itemName} />
          </Link>
        ) : (
          <NavLink to={route} key={key} style={{ textDecoration: "none" }}>
            <SidenavItem color={color} name={name} active={key === itemName} />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, collapse, noCollapse, key, href, route }: any) => {
      if (name && !ability.can("use", name.toLowerCase())) {
        return;
      }

      let returnValue;

      if (type === "collapse") {
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName || key === pathname}
                noCollapse={noCollapse}
              />
            </Link>
          );
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink
              to={route}
              key={key}
              onClick={() => {
                if (route === "/home") localStorage.setItem("homeIndex", "0");
              }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName || key === pathname}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else if (name !== "Logout") {
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName || key === pathname}
              open={openCollapse === key}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        } else {
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              noCollapse={noCollapse}
              active={key === collapseName || key === pathname}
              open={openCollapse === key}
              onClick={handleOpenLogoutDialog}
            />
          );
        }
      } else if (type === "title") {
        if (miniSidenav) {
          returnValue = <Box />;
        } else {
          returnValue = (
            <MDTypography
              key={key}
              color={textColor}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {title}
            </MDTypography>
          );
        }
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );

  const renderDrawers = (
    <MDBox>
      <AddKnowledgeDrawer
        openDrawer={openSaveInsightDrawer}
        handleCloseDrawer={handleCloseInsightDrawer}
      />
      <CreateCommitmentDrawer
        openDrawer={openCreateCommitmentDrawer}
        handleCloseDrawer={handleCloseCommitmentDrawer}
      />
      <Drawer
        anchor="right"
        open={openCreateOpportunityDrawer}
        onClose={handleCloseOpportunityDrawer}
        PaperProps={{
          sx: {
            height: "fit-content",
            maxHeight: "96%",
            width: largeScreen ? "40%" : mediumScreen ? "70%" : "90%",
          },
        }}
      >
        <CreateOpportunity handleCloseDrawer={handleCloseOpportunityDrawer} />
      </Drawer>
    </MDBox>
  )

  const handleCloseLogoutDialog = (result: boolean): any => {
    setOpenLogoutDialog(false);
    if (result) {
      try {
        // await AuthService.logout();
        logout();
      } catch (err) {
        console.error(err);
        return null;
      }
    }
  };

  const renderLogoutDialog = (
    <ConfirmationDialog
      openDialog={openLogoutDialog}
      handleCloseDialog={handleCloseLogoutDialog}
      title="Are you sure you want to logout?"
      description="Logging out will disconnect this device from your Forge account and you will be required to re-enter your Cross-Device Access Key the next time you log in."
      confirmText="Logout"
      confirmColor="error"
      denyVariant="contained"
    />
  );


  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      {renderDrawers}
      {renderLogoutDialog}
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {/* {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" />} */}
          <Stack direction="row">
            <MDBox
              style={{ margin: miniSidenav ? "0 auto" : "0", height: miniSidenav ? "24px" : "0%", width: miniSidenav ? "auto" : "0%" }}
              component="img"
              src={forgeFLogo}
              alt="Forge"
              mt={1}
            />
            <MDBox
              style={{ margin: miniSidenav ? "0" : "0 auto", width: miniSidenav ? "0%" : "70%" }}
              component="img"
              src={forgeLogo}
              alt="Forge"
              mt={1}
            />
          </Stack>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      {/* <MDBox mx={3} display="flex" justifyContent="space-between" alignItems="center"> */}
      <Stack
        direction="row"
        useFlexGap
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        spacing={0.7}
        style={{ marginLeft: 24, marginRight: 24 }}>
        <MDBox
          variant="gradient"
          bgColor={"white"}
          color={"dark"}
          width="3rem"
          height="3rem"
          borderRadius="md"
          display="flex"
          justifyContent="center"
          alignItems="center"
          shadow="md"
          style={{ cursor: "pointer", position: "relative" }}
          onClick={handleOpenInsightDrawer}
        >
          <EmojiObjectsOutlined style={{ position: "absolute" }} />
          <Add style={{ width: "0.5em", position: "absolute", top: "4px", right: "10px" }} />
        </MDBox>
        <MDBox
          variant="gradient"
          bgColor={"white"}
          color={"dark"}
          width="3rem"
          height="3rem"
          borderRadius="md"
          display="flex"
          justifyContent="center"
          alignItems="center"
          shadow="md"
          style={{ cursor: "pointer", position: "relative" }}
          onClick={handleOpenCommitmentDrawer}
        >
          <TaskAlt style={{ position: "absolute" }} />
          <Add style={{ width: "0.5em", position: "absolute", top: "4px", right: "8px" }} />
        </MDBox>
        <MDBox
          variant="gradient"
          bgColor={"white"}
          color={"dark"}
          width="3rem"
          height="3rem"
          borderRadius="md"
          display="flex"
          justifyContent="center"
          alignItems="center"
          shadow="md"
          style={{ cursor: "pointer", position: "relative" }}
          onClick={handleOpenOpportunityDrawer}
        >
          <HandshakeOutlined style={{ position: "absolute" }} />
          <Add style={{ width: "0.5em", position: "absolute", top: "4px", right: "9px" }} />
        </MDBox>
      </Stack>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
      <MDBox flex={1} />
      {window.innerWidth >= 1200 && <IconButton style={{ marginBottom: 16 }} onClick={() => {
        setCollapsedSidenav(!collapsedSidenav);
        return setMiniSidenav(dispatch, !collapsedSidenav);
      }}>
        <MenuOpen style={{ color: "white" }} />
      </IconButton>}
    </SidenavRoot>
  );
}

// Declaring default props for Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

export default Sidenav;
