import { useState } from "react";

// @mui material components
import { Tab, Tabs, useMediaQuery } from "@mui/material";
import { CalendarIcon } from "@mui/x-date-pickers";
import { Flag, Handshake, Notifications } from "@mui/icons-material";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import CalendarView from "./components/CalendarView";
import CommitmentsView from "./components/CommitmentsView";
import MilestonesView from "./components/MilestonesView";

// Firebase
import { CustomTabPanel, a11yProps } from "forge/core/components/TabPanel";
import AlertsView from "./components/AlertsView";
import { AlertsContextProvider } from "./services/AlertsContext";
import { CommitmentsContextProvider } from "forge/commitments/services/CommitmentsContext";
import theme from "assets/theme";
import { MilestonesContextProvider } from "forge/milestones/services/MilestonesContext";

function Home() {
    const bigScreen = useMediaQuery(theme.breakpoints.up("sm"));

    // Tabs
    const [tabValue, setTabValue] = useState(() => {
        let homeIndex = localStorage.getItem("homeIndex");
        if (homeIndex && !isNaN(parseInt(homeIndex))) {
          return parseInt(homeIndex);
        }
    
        return 0;
    });

    const handleSetTabValue = (event: any, newValue: number) => {
        localStorage.setItem("homeIndex", newValue.toString());
        return setTabValue(newValue);
    };

    return (
        <CommitmentsContextProvider>
            <AlertsContextProvider>
                <MilestonesContextProvider>
                    <DashboardLayout>
                        <Tabs
                            orientation="horizontal"
                            value={tabValue}
                            onChange={handleSetTabValue}
                        >
                            <Tab
                                icon={bigScreen && <CalendarIcon />}
                                iconPosition="start"
                                label="Calendar"
                                {...a11yProps(0)}
                            />
                            <Tab
                                icon={bigScreen && <Notifications />}
                                iconPosition="start"
                                label="Alerts"
                                {...a11yProps(1)}
                            />
                            <Tab
                                icon={bigScreen && <Handshake />}
                                iconPosition="start"
                                label="Commitments"
                                {...a11yProps(2)}
                            />
                            <Tab
                                icon={bigScreen && <Flag />}
                                iconPosition="start"
                                label="Milestones"
                                {...a11yProps(3)}
                            />
                        </Tabs>
                        <CustomTabPanel value={tabValue} index={0}>
                            <CalendarView />
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={1}>
                            <AlertsContextProvider >
                                <AlertsView />
                            </AlertsContextProvider>
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={2}>
                            <CommitmentsView />
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={3}>
                            <MilestonesView />
                        </CustomTabPanel>

                        <Footer />
                    </DashboardLayout>
                </MilestonesContextProvider>
            </AlertsContextProvider>
        </CommitmentsContextProvider>
    );
}

export default Home;