// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import CrossDeviceAccessKeyDisplayDialog from "forge/auth/components/CrossDeviceAccessKeyDisplayDialog";
import { useContext, useEffect, useState } from "react";
import { SecureStorageContext } from "forge/core/services/SecureStorageContext";
import { AuthContext } from "context";

function SettingsCrossDeviceAccessKey(): JSX.Element {
    // Context 
    const { getCurrentUser } = useContext(AuthContext);
    const { user, encryptionService, userProfileData } = getCurrentUser();
    const { read } = useContext(SecureStorageContext);

    // State
    const [openCrossDeviceKeyDisplayDialog, setOpenCrossDeviceKeyDisplayDialog] = useState<boolean>(false);
    const [key, setKey] = useState<string>();

    useEffect(() => {
        (async () => {
            const key = await read(`${user?.uid}_PrivateKey`);
            setKey(key);
        })();
    }, []);

    const onClose = (): void => {
        setOpenCrossDeviceKeyDisplayDialog(false);
    };

    const onShow = (): void => {
        setOpenCrossDeviceKeyDisplayDialog(true);
    };

    return (
        <Card id="cdak">
            <CrossDeviceAccessKeyDisplayDialog
                open={openCrossDeviceKeyDisplayDialog}
                accessKey={key}
                confirmationText="Done"
                onContinue={onClose}
                onClose={onClose}
            />
            <MDBox
                pr={3}
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
            >
                <MDBox p={3} lineHeight={1}>
                    <MDBox mb={1}>
                        <MDTypography variant="h5">Cross Device Access Key</MDTypography>
                    </MDBox>
                    <MDTypography variant="button" color="text">
                        Data encryption key for your Forge Data. You’ll need to enter this key to access your Forge data on any other device.
                    </MDTypography>
                </MDBox>
                <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                    <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                        <MDButton
                            variant="gradient"
                            color="dark"
                            sx={{ height: "100%" }}
                            onClick={onShow}
                        >
                            Show
                        </MDButton>
                    </MDBox>
                </MDBox>
            </MDBox>
        </Card>
    );
}

export default SettingsCrossDeviceAccessKey;