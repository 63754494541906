export class UserSettings {
    audience: string;
    frequenceAfterEachEmail: boolean;
    frequenceBeforeEachEmail: boolean;
    frequenceDailyPostEmail: boolean;
    frequenceDailyPreEmail: boolean;
    frequenceAfterEachPush: boolean;
    frequenceBeforeEachPush: boolean;
    frequenceDailyPostPush: boolean;
    frequenceDailyPrePush: boolean;
    commitmentsPush: boolean;
    milestonesPush: boolean;
    commitmentsReminderDays: number[];
    milestonesReminderDays: number[];
    hasCalendarAccess: boolean;
    hasContactsAccess: boolean;
    ignoredAttendees: string[];

    constructor({
        audience,
        frequenceAfterEachEmail,
        frequenceBeforeEachEmail,
        frequenceDailyPostEmail,
        frequenceDailyPreEmail,
        frequenceAfterEachPush,
        frequenceBeforeEachPush,
        frequenceDailyPostPush,
        frequenceDailyPrePush,
        commitmentsPush,
        milestonesPush,
        commitmentsReminderDays,
        milestonesReminderDays,
        hasCalendarAccess,
        hasContactsAccess,
        ignoredAttendees,
    }: {
        audience: string;
        frequenceAfterEachEmail: boolean;
        frequenceBeforeEachEmail: boolean;
        frequenceDailyPostEmail: boolean;
        frequenceDailyPreEmail: boolean;
        frequenceAfterEachPush: boolean;
        frequenceBeforeEachPush: boolean;
        frequenceDailyPostPush: boolean;
        frequenceDailyPrePush: boolean;
        commitmentsPush: boolean;
        milestonesPush: boolean;
        commitmentsReminderDays: number[];
        milestonesReminderDays: number[];
        hasCalendarAccess: boolean;
        hasContactsAccess: boolean;
        ignoredAttendees: string[];
    }) {
        this.audience = audience;
        this.frequenceAfterEachEmail = frequenceAfterEachEmail;
        this.frequenceBeforeEachEmail = frequenceBeforeEachEmail;
        this.frequenceDailyPostEmail = frequenceDailyPostEmail;
        this.frequenceDailyPreEmail = frequenceDailyPreEmail;
        this.frequenceAfterEachPush = frequenceAfterEachPush;
        this.frequenceBeforeEachPush = frequenceBeforeEachPush;
        this.frequenceDailyPostPush = frequenceDailyPostPush;
        this.frequenceDailyPrePush = frequenceDailyPrePush;
        this.commitmentsPush = commitmentsPush;
        this.milestonesPush = milestonesPush;
        this.commitmentsReminderDays = commitmentsReminderDays;
        this.milestonesReminderDays = milestonesReminderDays;
        this.hasCalendarAccess = hasCalendarAccess;
        this.hasContactsAccess = hasContactsAccess;
        this.ignoredAttendees = ignoredAttendees;
    }

    copyWith({
        audience,
        frequenceAfterEachEmail,
        frequenceBeforeEachEmail,
        frequenceDailyPostEmail,
        frequenceDailyPreEmail,
        frequenceAfterEachPush,
        frequenceBeforeEachPush,
        frequenceDailyPostPush,
        frequenceDailyPrePush,
        commitmentsPush,
        milestonesPush,
        commitmentsReminderDays,
        milestonesReminderDays,
        hasCalendarAccess,
        hasContactsAccess,
        ignoredAttendees,
    }: Partial<UserSettings>) {
        return new UserSettings({
            audience: audience ?? this.audience,
            frequenceAfterEachEmail: frequenceAfterEachEmail ?? this.frequenceAfterEachEmail,
            frequenceBeforeEachEmail: frequenceBeforeEachEmail ?? this.frequenceBeforeEachEmail,
            frequenceDailyPostEmail: frequenceDailyPostEmail ?? this.frequenceDailyPostEmail,
            frequenceDailyPreEmail: frequenceDailyPreEmail ?? this.frequenceDailyPreEmail,
            frequenceAfterEachPush: frequenceAfterEachPush ?? this.frequenceAfterEachPush,
            frequenceBeforeEachPush: frequenceBeforeEachPush ?? this.frequenceBeforeEachPush,
            frequenceDailyPostPush: frequenceDailyPostPush ?? this.frequenceDailyPostPush,
            frequenceDailyPrePush: frequenceDailyPrePush ?? this.frequenceDailyPrePush,
            commitmentsPush: commitmentsPush ?? this.commitmentsPush,
            milestonesPush: milestonesPush ?? this.milestonesPush,
            commitmentsReminderDays: commitmentsReminderDays ?? this.commitmentsReminderDays,
            milestonesReminderDays: milestonesReminderDays ?? this.milestonesReminderDays,
            hasCalendarAccess: hasCalendarAccess ?? this.hasCalendarAccess,
            hasContactsAccess: hasContactsAccess ?? this.hasContactsAccess,
            ignoredAttendees: ignoredAttendees ?? this.ignoredAttendees,
        });
    }

    toMap() {
        return {
            'audience': this.audience,
            'frequenceAfterEachEmail': this.frequenceAfterEachEmail,
            'frequenceBeforeEachEmail': this.frequenceBeforeEachEmail,
            'frequenceDailyPostEmail': this.frequenceDailyPostEmail,
            'frequenceDailyPreEmail': this.frequenceDailyPreEmail,
            'frequenceAfterEachPush': this.frequenceAfterEachPush,
            'frequenceBeforeEachPush': this.frequenceBeforeEachPush,
            'frequenceDailyPostPush': this.frequenceDailyPostPush,
            'frequenceDailyPrePush': this.frequenceDailyPrePush,
            'commitmentsPush': this.commitmentsPush,
            'milestonesPush': this.milestonesPush,
            'commitmentsReminderDays': this.commitmentsReminderDays,
            'milestonesReminderDays': this.milestonesReminderDays,
            'hasCalendarAccess': this.hasCalendarAccess,
            'hasContactsAccess': this.hasContactsAccess,
            'ignoredAttendees': this.ignoredAttendees,
        };
    }

    static fromMap(map: any) {
        return new UserSettings({
            audience: map['audience'] || 'all',
            frequenceAfterEachEmail: map['frequenceAfterEachEmail'] || true,
            frequenceBeforeEachEmail: map['frequenceBeforeEachEmail'] || true,
            frequenceDailyPostEmail: map['frequenceDailyPostEmail'] || true,
            frequenceDailyPreEmail: map['frequenceDailyPreEmail'] || true,
            frequenceAfterEachPush: map['frequenceAfterEachPush'] || true,
            frequenceBeforeEachPush: map['frequenceBeforeEachPush'] || true,
            frequenceDailyPostPush: map['frequenceDailyPostPush'] || true,
            frequenceDailyPrePush: map['frequenceDailyPrePush'] || true,
            commitmentsPush: map['commitmentsPush'] || true,
            milestonesPush: map['milestonesPush'] || true,
            commitmentsReminderDays: map['commitmentsReminderDays'] || [],
            milestonesReminderDays: map['milestonesReminderDays'] || [],
            hasCalendarAccess: map['hasCalendarAccess'] || false,
            hasContactsAccess: map['hasContactsAccess'] || false,
            ignoredAttendees: map['ignoredAttendees'] || [],
        });
    }
}
