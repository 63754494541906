import {
  DocumentReference,
  Unsubscribe,
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { firestoreDb } from "firebase.init";
import { User } from "firebase/auth";
import { UserProfileData } from "types/user/user-profile-data";
import { Call } from "types/pipeline/call";
import { ForgeEncryption } from "forge/core/services/encryption";
import { firestoreDebounce } from "forge/core/utils/firestore";

class CallsFirestore {
  private user: User;
  private userProfileData: UserProfileData;
  private encryptionService: ForgeEncryption;

  constructor(user: User, userProfileData: UserProfileData, encryptionService: ForgeEncryption) {
    this.user = user;
    this.userProfileData = userProfileData;
    this.encryptionService = encryptionService;
  }

  getCall = async (
    callId: string,
    eventRef?: DocumentReference
  ): Promise<Call | undefined> => {
    let docRef = doc(firestoreDb, `organizations/${this.userProfileData?.organization?.id}/calls/${callId}`);
    let callDoc = await getDoc(docRef);
    
    console.log(docRef, callDoc.exists());
    if (!callDoc.exists()) {
      docRef = doc(firestoreDb, `users/${this.user.uid}/calls/${callId}`)
      callDoc = await getDoc(docRef);
      console.log(docRef, callDoc.exists());

      if (!callDoc.exists()) {
        return;
      }
    }

    return await Call.fromMap(callDoc.data(), this.encryptionService);
  }

  getPersonalCallsLive = (
    opportunityId: string,
    onEvent: (calls: Call[]) => void
  ): Unsubscribe => {
    const collectionRef = query(
      collection(firestoreDb, `users/${this.user.uid}/calls`),
      where("opportunityId", "==", opportunityId),
      orderBy("date", "asc")
    );

    return onSnapshot(collectionRef, async (snapshot) => {
      const callsPromises = snapshot.docs.map((doc) =>
        (Call.fromMap(doc.data(), this.encryptionService))
      );

      let decrypted = await Promise.allSettled(callsPromises);
      const calls: Call[] = decrypted
        .filter((e: any) => e.status === "fulfilled")
        .map((e: any) => e.value);

      onEvent(calls);
    });
  }

  getOrganizationCallsLive = (
    opportunityId: string,
    onEvent: (calls: Call[]) => void
  ): Unsubscribe => {
    if (this.userProfileData?.organization?.id) {
      const collectionRef = query(
        collection(firestoreDb, `organizations/${this.userProfileData?.organization?.id}/calls`),
        where("opportunityId", "==", opportunityId),
        orderBy("date", "desc")
      );

      return firestoreDebounce(
        collectionRef,
        async (snapshot) => {
          const callsPromises = snapshot.docs.map((doc) =>
          (Call.fromMap(
            {
              ...doc.data(),
              isOrganizationCall: true,
            },
            this.encryptionService,
          ))
          );

          let decrypted = await Promise.allSettled(callsPromises);
          const calls: Call[] = decrypted
            .filter((e: any) => e.status === "fulfilled")
            .map((e: any) => e.value);

          onEvent(calls);
        }
      );
    }
  }
}

export default CallsFirestore;