// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React context
import SideNavItemTile from "forge/core/components/SideNavItemTile";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function SettingsSidenav(): JSX.Element {
    // Location
    const location = useLocation();
    const hash = location.hash;
    useEffect(() => {
        if (hash) {
            const timeoutId = setTimeout(() => {
                console.log(`Current hash is: ${hash}`);
                handleClick(hash.replace("#", ""));
            }, 500);

            // Clean up the timeout if the component unmounts or hash changes
            return () => clearTimeout(timeoutId);
        }
    }, [hash]);

    const sideNavItems = [
        { icon: "person", label: "profile", href: "profile" },
        { icon: "receipt_long", label: "basic info", href: "basic-info" },
        { icon: "handshake", label: "CRM", href: "crm" },
        { icon: "campaign", label: "notifications", href: "notifications" },
        { icon: "calendar_month", label: "calendars", href: "calendars" },
        { icon: "key", label: "Cross-Device Access Key", href: "cdak" },
        { icon: "delete", label: "delete account", href: "delete-account" },
    ];

    const handleClick = (targetId: string) => {
        const element = document.getElementById(targetId);

        if (element) {
            var headerOffset = 24;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    };

    const renderSideNavItems = sideNavItems.map((item, key) => (
        <SideNavItemTile 
            sideNavItem={item}
            itemKey={key}
            handleClick={handleClick}
        />
    ));

    return (
        <Card
            sx={{
                borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
                position: "sticky",
                top: "24px",
            }}
        >
            <MDBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={2}
                m={0}
                sx={{ listStyle: "none" }}
            >
                {renderSideNavItems}
            </MDBox>
        </Card>
    );
}

export default SettingsSidenav;