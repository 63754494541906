import { createContext, useContext, useState, useEffect } from "react";
import { AuthContext, EventsContext } from "context";
import { ForgeEvent } from "types/forge-event";
import { CalendarEvent } from "types/calendar/calendar-event";
import { Commitment } from "types/commitment";
import { CommitmentsContext } from "forge/commitments/services/CommitmentsContext";
import { MilestonesContext } from "forge/milestones/services/MilestonesContext";

interface AlertsContextType {
    alerts: ForgeEvent[];
    getAlertsForDay(targetDate: Date): ForgeEvent[],
    getGroupedAlerts(): { [key: string]: ForgeEvent[] },
}

export const AlertsContext = createContext<AlertsContextType>({
    alerts: [],
    getAlertsForDay: (targetDate: Date): ForgeEvent[] => [],
    getGroupedAlerts: (): { [key: string]: ForgeEvent[] } => ({}),
});

export const AlertsContextProvider = ({
    children,
}: {
    children: any,
}) => {
    // Context
    const { getCurrentUser, isAuthenticated } = useContext(AuthContext);
    const { events } = useContext(EventsContext);
    const { commitments } = useContext(CommitmentsContext);
    const { milestones } = useContext(MilestonesContext);

    // State
    const [alertEvents, setAlertEvents] = useState<CalendarEvent[]>([]);
    const [alertCommitments, setAlertCommitments] = useState<Commitment[]>([]);
    const [alertMilestones, setAlertMilestones] = useState<any[]>([]);
    const [alerts, setAlerts] = useState<ForgeEvent[]>([]);

    useEffect(() => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero

        const sevenDaysFromNow = new Date();
        sevenDaysFromNow.setDate(sevenDaysFromNow.getDate() + 7);

        setAlertEvents(events.filter(event => {
            const eventDate = event.startDate.toDate();
            return eventDate >= currentDate && eventDate <= sevenDaysFromNow && event.isInvite;
        }));
    }, [events]);

    useEffect(() => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero

        const sevenDaysFromNow = new Date();
        sevenDaysFromNow.setDate(sevenDaysFromNow.getDate() + 7);

        setAlertCommitments(commitments.filter(commitment => {
            const eventDate = commitment.startDate.toDate();
            return eventDate >= currentDate && eventDate <= sevenDaysFromNow;
        }));
    }, [commitments]);

    useEffect(() => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero

        const sevenDaysFromNow = new Date();
        sevenDaysFromNow.setDate(sevenDaysFromNow.getDate() + 7);

        setAlertMilestones(milestones.filter(milestone => {
            const eventDate = milestone.startDate.toDate();
            return eventDate >= currentDate && eventDate <= sevenDaysFromNow;
        }));
    }, [milestones]);

    useEffect(() => {
        setAlerts([...alertEvents, ...alertCommitments, ...alertMilestones]);
    }, [alertEvents, alertCommitments, alertMilestones]);

    const getAlertsForDay = (targetDate: Date): ForgeEvent[] => {
        // Create a new Date object representing the start of the target day
        const startOfDay = new Date(targetDate);
        startOfDay.setHours(0, 0, 0, 0);

        // Create a new Date object representing the end of the target day
        const endOfDay = new Date(targetDate);
        endOfDay.setHours(23, 59, 59, 999);

        // Filter events that fall within the target day
        return alerts.filter(alert => {
            const eventDate = alert.startDate.toDate();
            return eventDate >= startOfDay && eventDate <= endOfDay;
        });
    }

    const getGroupedAlerts = (): { [key: string]: ForgeEvent[] } => {
        // Get today's date
        const today = new Date();
        const data: { [key: string]: ForgeEvent[] } = {};

        for (let i = 0; i < 7; i++) {
            let year = today.getFullYear();
            let month = (today.getMonth() + 1).toString().padStart(2, "0");
            let day = today.getDate().toString().padStart(2, "0");
            const dateKey = `${year}-${month}-${day}`; // Using date as key

            data[dateKey] = getAlertsForDay(today);
            today.setDate(today.getDate() + 1);
        }

        return data;
    }

    return (
        <AlertsContext.Provider
            value={{ alerts, getAlertsForDay, getGroupedAlerts }}
        >
            {children}
        </AlertsContext.Provider>
    );
};