
// @material-ui core components
import { Block, DomainAdd } from "@mui/icons-material";
import { Avatar, Stack } from "@mui/material";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDTypography from "components/MDTypography";

// Settings page components
import { useContext } from "react";
import { AuthContext } from "context";

function RejectedCard(): JSX.Element {
    // Context
    const { getCurrentUser } = useContext(AuthContext);
    const { userProfileData } = getCurrentUser();

    return (
        <Card
            sx={{ overflow: "visible" }}
            style={{
                alignItems: "center",
                justifyContent: "center",
                padding: "64px",
                minHeight: "425px",
                maxHeight: "425px",
                minWidth: "475px",
                maxWidth: "475px",
            }}
        >
            <Stack direction="column" spacing={4} alignItems="center" alignContent="center" justifyContent="center">
                <Avatar
                    variant="rounded"
                    style={{ background: 'black', height: "80px", width: "80px" }}
                >
                    <Block style={{ height: "48px", width: "48px" }} />
                </Avatar>
                <MDTypography variant="h5" fontWeight="normal" textAlign="center">{userProfileData.organization?.idPendingActivation}'s Organization Membership <b>Rejected</b></MDTypography>
                <MDTypography variant="body2" textAlign="center">Please contact your administrator for if you think this is a mistake.</MDTypography>
            </Stack>
        </Card>


    );
}

export default RejectedCard;
