import { Card, Divider, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Info } from "@mui/icons-material";
import { ListenContext } from "../../services/ListenContext";
import { useContext } from "react";
import QuestionField from "../QuestionField";
import { QuestionCategory } from "types/question";

const options = { year: 'numeric', month: 'short', day: '2-digit' };

function ContactListenCard({ contact }: { contact: any }) {
    const { questions, getQuestion } = useContext(ListenContext);

    function renderQuestion(category: QuestionCategory) {
        let question = getQuestion(category);
        if (!question) return null;
        return <QuestionField question={question} contact={contact} />;
    }

    return (
        <Card sx={{ height: "100%" }}>
            <MDBox m={3}>
                <MDTypography variant="h5">Listen</MDTypography>

                <Divider style={{ opacity: 1 }} />

                {contact?.firstName && <MDTypography variant="h6" textAlign="center" mb={1.5}>
                    {`What's most important to ${contact.firstName}?`}
                </MDTypography>}

                {questions.length > 0 ?
                    <Stack width="100%" direction="column" alignItems="center" spacing={0.5}>
                        {renderQuestion(QuestionCategory.meet)}
                        {renderQuestion(QuestionCategory.personal)}
                        {renderQuestion(QuestionCategory.origin)}
                        {renderQuestion(QuestionCategory.relationships)}
                        {renderQuestion(QuestionCategory.family)}
                        {renderQuestion(QuestionCategory.pets)}
                        {renderQuestion(QuestionCategory.sports)}
                        {renderQuestion(QuestionCategory.education)}
                        {renderQuestion(QuestionCategory.entertainment)}
                        {renderQuestion(QuestionCategory.religion)}
                        {renderQuestion(QuestionCategory.hobbies)}
                        {renderQuestion(QuestionCategory.help)}
                        {renderQuestion(QuestionCategory.commitment)}
                    </Stack>
                    : <Stack mt={5} mb={3} direction="column" alignItems="center" spacing={0.5}>
                        <Info style={{ color: "darkgray" }} />
                        <MDTypography variant="body1" style={{ color: "darkgray" }}>Coming Soon</MDTypography>
                    </Stack>
                }
            </MDBox>
        </Card>
    );
};

export default ContactListenCard;