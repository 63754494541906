import {
    collection,
    query,
    orderBy,
    Unsubscribe,
    QuerySnapshot,
    DocumentData,
} from "firebase/firestore";
import { firestoreDb } from "firebase.init";
import { User } from "firebase/auth";
import { UserProfileData } from "types/user/user-profile-data";
import { firestoreDebounce } from "forge/core/utils/firestore";
import { ForgeEncryption } from "forge/core/services/encryption";
import { Knowledge } from "../schemas/knowledge";

class KnowledgeFirestore {
    private user: User;
    private userProfileData: UserProfileData;
    private encryptionService: ForgeEncryption;

    constructor(user: User, userProfileData: UserProfileData, encryptionService: ForgeEncryption) {
        this.user = user;
        this.userProfileData = userProfileData;
        this.encryptionService = encryptionService;
    }

    getPersonalKnowledgeLive = (
        contactId: string,
        onEvent: (knowledge: Knowledge[]) => void
    ): Unsubscribe => {
        const collectionRef = query(
            collection(firestoreDb, `users/${this.user.uid}/contacts/${contactId}/knowledges`),
            orderBy("createdAt", "desc")
        );

        return firestoreDebounce(
            collectionRef,
            async (snapshot: QuerySnapshot<DocumentData, DocumentData>) => {
                const knowledgePromises = snapshot.docs.map(
                    (doc) => (Knowledge.fromMap(
                        {
                            ...doc.data(),
                            isOrganizationKnowledge: false,
                        },
                        this.encryptionService,
                    ))
                );

                let decrypted = await Promise.allSettled(knowledgePromises);
                const knowledge: Knowledge[] = decrypted
                    .filter((e: any) => e.status === "fulfilled")
                    .map((e: any) => e.value);

                onEvent(knowledge);
            }
        );
    }

    getOrganizationKnowledgeLive = (
        contactId: string,
        onEvent: (knowledge: Knowledge[]) => void
    ): Unsubscribe => {
        if (this.userProfileData?.organization?.id) {
            const collectionRef = query(
                collection(firestoreDb, `organizations/${this.userProfileData.organization?.id}/contacts/${contactId}/knowledges`),
                orderBy("createdAt", "desc")
            );

            return firestoreDebounce(
                collectionRef,
                async (snapshot: QuerySnapshot<DocumentData, DocumentData>) => {
                    const knowledgePromises = snapshot.docs.map(
                        (doc) => (Knowledge.fromMap(
                            {
                                ...doc.data(),
                                isOrganizationKnowledge: true,
                            },
                            this.encryptionService,
                        ))
                    );

                    let decrypted = await Promise.allSettled(knowledgePromises);
                    const knowledge: Knowledge[] = decrypted
                        .filter((e: any) => e.status === "fulfilled")
                        .map((e: any) => e.value);

                    onEvent(knowledge);
                }
            );
        }

        return;
    }
}

export default KnowledgeFirestore;