import { ArrowForwardIos } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function ForgeCta({
    cta,
    icon,
    onClick,
    fontWeight = "medium",
    fontSize,
    buttonSize = "3rem",
    margin,
    marginTop = 5,
    marginBottom = 3,
}: {
    cta: string,
    icon: JSX.Element,
    onClick?: () => void,
    fontWeight?: string,
    fontSize?: string,
    buttonSize?: string,
    margin?: string,
    marginTop?: number,
    marginBottom?: number,
}) {
    return (
        <Stack
            mt={marginTop}
            mb={marginBottom}
            direction="row"
            alignSelf={"center"}
            justifyContent={"center"}
            alignItems="center"
            spacing={0.5}
            style={{margin}}
            >
            <MDTypography
                variant="body2"
                fontWeight={fontWeight}
                fontSize={fontSize}
                align="center"
                style={{ color: "gray" }}>
                {cta}
            </MDTypography>
            <ArrowForwardIos style={{ color: "darkgray", height: "0.75em" }} />
            <MDBox
                width={buttonSize}
                height={buttonSize}
                borderRadius="12px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                shadow="md"
                style={{ cursor: "pointer", background: "cornflowerblue" }}
                onClick={onClick}
            >
                <IconButton
                    aria-label="add-knowledge"
                    style={{ padding: 0, color: "white" }}
                    onClick={onClick}
                >
                    {icon}
                </IconButton>
            </MDBox>
        </Stack>
    );
}

export default ForgeCta;