import { DocumentReference, doc } from "firebase/firestore";
import { ForgeAttendee } from "./forge-attendee";
import { firestoreDb } from "firebase.init";
import { VideoConferencingPlatform } from "./video-conferencing-platform";

export class CalendarEventProperties {
    description?: string;
    location?: string;
    hasARealAddress: boolean = false;
    url?: string;
    webUrl?: string;
    urlType?: string;
    userAttendee?: ForgeAttendee;
    attendees?: ForgeAttendee[];
    attendeesRefs?: DocumentReference[];
    attendeesResearch?: ForgeAttendee[];
    attendeesResearchRefs?: DocumentReference[];

    constructor({
        description,
        location,
        hasARealAddress = false,
        url,
        webUrl,
        urlType,
        userAttendee,
        attendees,
        attendeesRefs,
        attendeesResearch,
        attendeesResearchRefs
    }: {
        description?: string;
        location?: string;
        hasARealAddress?: boolean;
        url?: string;
        webUrl?: string;
        urlType?: string;
        userAttendee?: ForgeAttendee;
        attendees?: ForgeAttendee[];
        attendeesRefs?: DocumentReference[];
        attendeesResearch?: ForgeAttendee[];
        attendeesResearchRefs?: DocumentReference[];
    }) {
        this.description = description;
        this.location = location;
        this.hasARealAddress = hasARealAddress;
        this.url = url;
        this.webUrl = webUrl;
        this.urlType = urlType;
        this.userAttendee = userAttendee;
        this.attendees = attendees;
        this.attendeesRefs = attendeesRefs;
        this.attendeesResearch = attendeesResearch;
        this.attendeesResearchRefs = attendeesResearchRefs;
    }

    copyWith({
        description,
        location,
        hasARealAddress,
        url,
        webUrl,
        urlType,
        userAttendee,
        attendees,
        attendeesRefs,
        attendeesResearch,
        attendeesResearchRefs
    }: {
        description?: string;
        location?: string;
        hasARealAddress?: boolean;
        url?: string;
        webUrl?: string;
        urlType?: string;
        userAttendee?: ForgeAttendee;
        attendees?: ForgeAttendee[];
        attendeesRefs?: DocumentReference[];
        attendeesResearch?: ForgeAttendee[];
        attendeesResearchRefs?: DocumentReference[];
    }): CalendarEventProperties {
        return new CalendarEventProperties({
            description: description ?? this.description,
            location: location ?? this.location,
            hasARealAddress: hasARealAddress ?? this.hasARealAddress,
            url: url ?? this.url,
            webUrl: webUrl ?? this.webUrl,
            urlType: urlType ?? this.urlType,
            userAttendee: userAttendee ?? this.userAttendee,
            attendees: attendees ?? this.attendees,
            attendeesRefs: attendeesRefs ?? this.attendeesRefs,
            attendeesResearch: attendeesResearch ?? this.attendeesResearch,
            attendeesResearchRefs: attendeesResearchRefs ?? this.attendeesResearchRefs
        });
    }

    toMap(): Record<string, any> {
        const event: Record<string, any> = {
            description: this.description,
            location: this.location,
            hasARealAddress: this.hasARealAddress,
            url: this.url,
            webUrl: this.webUrl,
            urlType: this.urlType,
            userAttendee: this.userAttendee?.toMap(),
            attendees: this.attendees?.map(att => att.toMap()),
            attendeesRefs: this.attendeesRefs?.map(ref => ref?.path),
            attendeesResearch: this.attendeesResearch?.map(att => att.toMap()),
            attendeesResearchRefs: this.attendeesResearchRefs?.map(ref => ref?.path)
        };

        if (!event['attendeesResearch']) {
            delete event['attendeesResearch'];
        }
        if (!event['attendeesResearchRefs']) {
            delete event['attendeesResearchRefs'];
        }

        return event;
    }

    static fromMap(map: Record<string, any>): CalendarEventProperties {
        return new CalendarEventProperties({
            description: map['description'],
            location: map['location'],
            hasARealAddress: map['hasARealAddress'] ?? false,
            url: map['url'],
            webUrl: map['webUrl'],
            urlType: map['urlType'],
            userAttendee: map['userAttendee']
                ? ForgeAttendee.fromMap(map['userAttendee'])
                : undefined,
            attendees: map['attendees']
                ? map['attendees'].map((x: Record<string, any>) => ForgeAttendee.fromMap(x))
                : [],
            attendeesRefs: map['attendeesRefs']
                ? map['attendeesRefs'].map((x: any) => x instanceof DocumentReference ? x : typeof x === "string" ? doc(firestoreDb, x) : undefined)
                : [],
            attendeesResearch: map['attendeesResearch']
                ? map['attendeesResearch'].map((x: Record<string, any>) => ForgeAttendee.fromMap(x))
                : [],
            attendeesResearchRefs: map['attendeesResearchRefs']
                ? map['attendeesResearchRefs'].map((x: any) => x instanceof DocumentReference ? x : typeof x === "string" ? doc(firestoreDb, x) : undefined)
                : []
        });
    }

    get videoConferencingPlatform(): VideoConferencingPlatform {
        switch (this.urlType) {
            case 'g-meet':
                return VideoConferencingPlatform.meets;
            case 'zoom':
                return VideoConferencingPlatform.zoom;
            case 'msteams':
                return VideoConferencingPlatform.teams;
            default:
                return null;
        }
    }
}
