import { Switch } from "@mui/material";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import spinningSphere from "assets/forge/gifs/spinning-sphere.gif";
import { Crm, CrmExtended } from "types/pipeline/crm";

function CrmTile({
    crm,
    enabled = false,
    loading = false,
    disabledText,
    onSetupTapped,
    onRemoveTapped,
}: {
    crm: Crm,
    enabled?: boolean
    loading?: boolean
    disabledText?: string,
    onSetupTapped?: () => void,
    onRemoveTapped?: () => void,
}): JSX.Element {

    return (
        <MDBox>
            <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection="row"
            >
                <MDBox display="flex" alignItems="center">
                    <img
                        src={CrmExtended.getIcon(crm)}
                        alt={`${CrmExtended.getName(crm)} logo`}
                        style={{ margin: "10px", width: "32px", opacity: enabled || !disabledText ? "1" : "0.3" }}
                    />
                    <MDBox ml={2}>
                        <MDTypography variant="h5" fontWeight="medium" style={{ color: enabled || !disabledText ? "black" : "lightgray" }}>
                            {CrmExtended.getName(crm)}
                        </MDTypography>
                    </MDBox>
                    <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                        {loading ?
                            <img src={spinningSphere} style={{ height: "42px" }} alt="Loading..." />
                            : enabled ?
                                <MDBadge variant="contained" color="success" badgeContent="enabled" container />
                                : <></>}
                    </MDBox>
                </MDBox>
                <MDBox
                    display="flex"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                >
                    {
                        onSetupTapped && !loading ? <MDButton
                            variant="outlined"
                            color="dark"
                            size="small"
                            onClick={onSetupTapped}
                        >
                            {enabled ? "modify" : "set up"}
                        </MDButton>
                            : <MDTypography variant="button" color="text" fontWeight="regular">
                                {disabledText}
                            </MDTypography>
                    }
                    {
                        onRemoveTapped && enabled && <MDButton
                            variant="outlined"
                            color="error"
                            size="small"
                            onClick={onRemoveTapped}
                            style={{marginLeft: 8}}
                        >
                            Remove
                        </MDButton>
                    }
                </MDBox>
            </MDBox>
            {/* {enabled && <MDBox ml={2} pl={{ xs: 0, sm: 6 }} lineHeight={1}>
                <MDBox
                    bgColor={"grey-100"}
                    borderRadius="lg"
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "row", sm: "row" }}
                    my={2}
                    py={1}
                    pl={2}
                    pr={1}
                >
                    <MDBox >
                        <MDTypography variant="h6" fontWeight="normal" color="text">
                            Account Name
                        </MDTypography>
                        <MDTypography variant="body2" fontSize="small" color="text">
                            Details
                        </MDTypography>
                    </MDBox>
                    <MDBox mt={{ xs: 1, sm: 0 }}>
                        <Switch checked={true} onChange={() => { }} />
                    </MDBox>
                </MDBox>
            </MDBox>} */}
        </MDBox>
    );
}

export default CrmTile;