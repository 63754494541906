import Axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";

const API_URL = process.env.REACT_APP_API_URL;
Axios.defaults.baseURL = API_URL;

export class HttpService {
  _axios = Axios.create();

  addRequestInterceptor = (onFulfilled?: (value: InternalAxiosRequestConfig<any>) => InternalAxiosRequestConfig<any> | Promise<InternalAxiosRequestConfig<any>>, onRejected?: (error: any) => any) => {
    this._axios.interceptors.request.use(onFulfilled, onRejected);
  };

  addResponseInterceptor = (onFulfilled?: (value: AxiosResponse<any, any>) => AxiosResponse<any, any> | Promise<AxiosResponse<any, any>>, onRejected?: (error: any) => any) => {
    this._axios.interceptors.response.use(onFulfilled, onRejected);
  };

  get = async (
    url: string,
    params?: any,
    headers?: any,
    data?: any
  ): Promise<any> => await this.request(
    this.getOptionsConfig("get", url, data, params, headers)
  );

  post = async (url: string, data: any): Promise<any> =>
    await this.request(this.getOptionsConfig("post", url, data));

  put = async (url: string, data: any) =>
    await this.request(this.getOptionsConfig("put", url, data));

  patch = async (url: string, data: any): Promise<any> => {
    return await this.request(this.getOptionsConfig("patch", url, data));
  };

  delete = async (url: string): Promise<any> =>
    await this.request(this.getOptionsConfig("delete", url));

  getOptionsConfig = (method: string, url: string, data?: any, params?: any, headers?: any) => {
    headers = headers || {};
    headers["Content-Type"] = "application/vnd.api+json";

    return {
      method,
      url,
      data,
      params,
      headers,
    };
  };

  request(options: any) {
    return new Promise((resolve, reject) => {
      this._axios
        .request(options)
        .then((res) => {
          if (process.env.REACT_APP_DEV === "true") {
            console.log(res);
          }

          resolve(res?.data);
        })
        .catch((ex) => {
          if (process.env.REACT_APP_DEV === "true") {
            console.warn(ex);
          }
          
          reject(ex.response?.data);
        });
    });
  }
}

export default new HttpService();