import { User } from "firebase/auth";
import { UserProfileData } from "types/user/user-profile-data";
import HttpService from "forge/core/services/http.service";
import { ForgeOrganizationMember } from "forge/organization/types/member";
import { ForgeEncryption } from "forge/core/services/encryption";


class OrganizationMembersApi {
    private user: User;
    private userProfileData: UserProfileData;
    private encryptionService: ForgeEncryption;

    constructor(user: User, userProfileData: UserProfileData, encryptionService: ForgeEncryption) {
        this.user = user;
        this.userProfileData = userProfileData;
        this.encryptionService = encryptionService;
    }

    inviteMember = async (member: ForgeOrganizationMember): Promise<any> => {
        if (this.userProfileData.organization?.id) {
            const endpoint = `organizations/${this.userProfileData.organization?.id}/members`;
            return await HttpService.post(endpoint, { member });
        }
    }

    updateMember = async (memberId: string, member: ForgeOrganizationMember): Promise<any> => {
        if (this.userProfileData.organization?.id) {
            const endpoint = `organizations/${this.userProfileData.organization?.id}/members/${memberId}`;
            return await HttpService.patch(endpoint, { member });
        }
    }

    acceptMember = async (memberId: string): Promise<any> => {
        if (this.userProfileData.organization?.id) {
            const endpoint = `organizations/${this.userProfileData.organization?.id}/members/${memberId}/accept`;
            return await HttpService.patch(endpoint, {});
        }
    }

    rejectMember = async (memberId: string): Promise<any> => {
        if (this.userProfileData.organization?.id) {
            const endpoint = `organizations/${this.userProfileData.organization?.id}/members/${memberId}/reject`;
            return await HttpService.patch(endpoint, {});
        }
    }

    deleteMember = async (memberId: string): Promise<any> => {
        if (this.userProfileData.organization?.id) {
            const endpoint = `organizations/${this.userProfileData.organization?.id}/members/${memberId}`;
            return await HttpService.delete(endpoint);
        }
    }
}

export default OrganizationMembersApi;