import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDButton from 'components/MDButton';

export default function ConfirmationDialog({
    openDialog,
    handleCloseDialog,
    title,
    description,
    confirmText,
    denyText,
    confirmColor,
    denyVariant,
}: {
    openDialog: boolean,
    handleCloseDialog: (result: boolean) => void,
    title: string,
    description: string,
    confirmText?: string,
    denyText?: string,
    confirmColor?:
    | "white"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "light"
    | "dark"
    | "default",
    denyVariant?: "text" | "contained" | "gradient" | "outlined",
}) {
    return (
        <Dialog
            open={openDialog}
            onClose={() => handleCloseDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <MDButton variant={denyVariant} autoFocus onClick={() => handleCloseDialog(false)} color={"info"}>
                    {denyText ?? "Cancel"}
                </MDButton>
                <MDButton onClick={() => handleCloseDialog(true)} color={confirmColor ?? "info"}>
                    {confirmText ?? "Confirm"}
                </MDButton>
            </DialogActions>
        </Dialog>
    );
}