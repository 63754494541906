import { Alert } from '@mui/material';
import MDSnackbar from 'components/MDSnackbar';
import * as React from 'react';
import { useLayoutEffect, useRef, useState } from 'react';

const getOnLineStatus = () =>
    typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
        ? navigator.onLine
        : true;

const useNavigatorOnLine = () => {
    const [status, setStatus] = React.useState(getOnLineStatus());

    const setOnline = () => setStatus(true);
    const setOffline = () => setStatus(false);

    React.useEffect(() => {
        window.addEventListener('online', setOnline);
        window.addEventListener('offline', setOffline);

        return () => {
            window.removeEventListener('online', setOnline);
            window.removeEventListener('offline', setOffline);
        };
    }, []);

    return status;
};

function NetworkStatusIndicator() {
    // State
    const [networkError, setNetworkError] = useState<boolean>(false);
    const openNetworkError = () => setNetworkError(true);
    const closeNetworkError = () => setNetworkError(false);

    const isOnline = useNavigatorOnLine();
    const firstUpdate = useRef(true);

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (isOnline) {
            closeNetworkError();
        } else {
            openNetworkError();
        }
    }, [isOnline]);

    return <MDSnackbar
        color="error"
        icon="warning"
        title="Network Offline"
        content="You are currently offline."
        dateTime=''
        open={networkError}
        onClose={closeNetworkError}
        close={closeNetworkError}
        bgWhite
    />;
};

export default NetworkStatusIndicator;