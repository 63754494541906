import { Timestamp } from "firebase/firestore";
import { ForgeEncryption } from "forge/core/services/encryption";

export class ForgeOAuthCredentials {
    account: string;
    clientId: string;
    accessToken: string;
    refreshToken: string;
    expiration?: Date | null;
    scopes: string[];
    loading: boolean;

    constructor({
        account,
        clientId,
        accessToken,
        refreshToken,
        expiration,
        scopes,
        loading,
    }: {
        account: string;
        clientId: string;
        accessToken: string;
        refreshToken: string;
        expiration?: Date | null;
        scopes: string[];
        loading: boolean,
    }) {
        this.account = account;
        this.clientId = clientId;
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
        this.expiration = expiration;
        this.scopes = scopes;
        this.loading = loading;
    }

    copyWith({
        account,
        clientId,
        accessToken,
        refreshToken,
        expiration,
        scopes,
        loading,
    }: {
        account?: string;
        clientId?: string;
        accessToken?: string;
        refreshToken?: string;
        expiration?: Date | null;
        scopes?: string[];
        loading?: boolean;
    } = {}): ForgeOAuthCredentials {
        return new ForgeOAuthCredentials({
            account: account ?? this.account,
            clientId: clientId ?? this.clientId,
            accessToken: accessToken ?? this.accessToken,
            refreshToken: refreshToken ?? this.refreshToken,
            expiration: expiration ?? this.expiration,
            scopes: scopes ?? this.scopes,
            loading: loading ?? this.loading,
        });
    }

    toMap(): { [key: string]: any } {
        return {
            account: this.account,
            clientId: this.clientId,
            accessToken: this.accessToken,
            refreshToken: this.refreshToken,
            expiration: this.expiration
                ? this.expiration.valueOf()
                : null,
            scopes: this.scopes,
            loading: this.loading,
            encrypted: false,
        };
    }

    static async fromMap(
        map: { [key: string]: any },
        encryptionService: ForgeEncryption
    ): Promise<ForgeOAuthCredentials> {
        let accessToken;
        if (map.encrypted) {
            accessToken = await encryptionService.decrypt({
                data: map.accessToken,
                encrypter: map.encrypter
            });
        } else {
            accessToken = map.accessToken;
        }

        let refreshToken;
        if (map.encrypted) {
            refreshToken = await encryptionService.decrypt({
                data: map.refreshToken,
                encrypter: map.encrypter
            });
        } else {
            refreshToken = map.refreshToken;
        }

        return new ForgeOAuthCredentials({
            account: map.account,
            clientId: map.clientId,
            accessToken: accessToken,
            refreshToken: refreshToken,
            expiration: map.expiration ? (map.expiration instanceof Timestamp ? map.expiration.toDate() : new Date(map.expiration)) : null,
            scopes: map.scopes,
            loading: map.loading ?? false,
        });
    }
}
