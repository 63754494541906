import { createContext, useState } from "react";

interface AppStateContextType {
    authLoading: boolean,
    setAuthLoading: React.Dispatch<React.SetStateAction<boolean>>,
    contactsLoading: boolean,
    setContactsLoading: React.Dispatch<React.SetStateAction<boolean>>,
}

export const AppStateContext = createContext<AppStateContextType>({
    authLoading: true,
    setAuthLoading: () => {},
    contactsLoading: true,
    setContactsLoading: () => {},
});

export const AppStateContextProvider = ({ children }: { children: any }) => {
    
    const [authLoading, setAuthLoading] = useState(true);
    const [contactsLoading, setContactsLoading] = useState(true);

    // useEffect(() => {
    //     setInitializing();
    // }, [authLoading, contactsLoading]);

    return (
        <AppStateContext.Provider
            value={{ 
                authLoading,
                setAuthLoading,
                contactsLoading,
                setContactsLoading,
             }}
        >
            {children}
        </AppStateContext.Provider>
    );
};