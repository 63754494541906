import { Add, GroupAddOutlined } from "@mui/icons-material";
import { Avatar, Card } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function AddContactCard({
    onClick,
    width = "100%"
}: {
    onClick?: any,
    isSelected?: boolean,
    isFavoriteEnabled?: boolean,
    isWarmthRingEnabled?: boolean
    enableScoreBadge?: boolean
    maxOneLine?: boolean
    width?: string,
}) {
    return (
        <Card
            variant="outlined"
            style={{
                boxShadow: "none",
                border: "1.5px solid black",
                marginBottom: "8px",
                cursor: onClick ? "pointer" : "auto",
                width: width,
                background: 'white'
            }}
            onClick={onClick}
        >
            <MDBox display="flex" alignItems="center" m={1}>
                <MDBox mx={1} my={1} >
                    <Avatar style={{ background: 'black' }}>
                        <GroupAddOutlined/>
                    </Avatar>
                </MDBox>
                <MDBox ml={0.5} lineHeight={0} sx={{ width: "-webkit-fill-available" }}>
                    <MDBox mt={0.5} />
                    <MDTypography
                        variant="h6"
                        lineHeight={1}
                        color={'black'}
                        sx={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            textOverflow: 'ellipsis',
                        }}
                    >
                        Add a New Relationship
                    </MDTypography>
                </MDBox>
                <Add fontSize="medium" style={{ marginRight: 8 }} />
            </MDBox>
        </Card>
    );
};

export default AddContactCard;