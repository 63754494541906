import { useNavigate } from "react-router-dom";
import { StarBorderOutlined } from "@mui/icons-material";
import { Card, Tooltip } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ForgeContactAvatar } from "../ForgeAvatar";

function ContactCard({
    contact,
    subtitle,
    onClick,
    isSelected = false,
    isFavoriteEnabled = true,
    isWarmthRingEnabled = true,
    enableScoreBadge = true,
    maxOneLine = false,
    width = "100%"
}: {
    contact: any,
    subtitle?: any,
    onClick?: any,
    isSelected?: boolean,
    isFavoriteEnabled?: boolean,
    isWarmthRingEnabled?: boolean
    enableScoreBadge?: boolean
    maxOneLine?: boolean
    width?: string,
}) {
    return (
        <Card
            variant="outlined"
            style={{
                boxShadow: "none",
                border: "1.5px solid black",
                marginBottom: "8px",
                cursor: onClick ? "pointer" : "auto",
                width: width,
                background: isSelected ? 'black' : 'white'
            }}
            onClick={onClick}
        >
            <MDBox display="flex" alignItems="center" m={1}>
                <MDBox mx={1} my={1} >
                    <Tooltip title={contact.name}>
                        <ForgeContactAvatar
                            contact={contact}
                            warmthRingThickness={isWarmthRingEnabled ? 3 : 0}
                            enableScoreBadge={enableScoreBadge}
                            color="white"
                            background="black"
                            onContactTapped={onClick}
                        />
                    </Tooltip>
                </MDBox>
                <MDBox ml={0.5} lineHeight={0} sx={{ width: "-webkit-fill-available" }}>
                    <MDBox mt={0.5} />
                    <MDTypography
                        variant="h6"
                        lineHeight={1}
                        color={isSelected ? 'white' : 'black'}
                        sx={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: maxOneLine ? 1 : null,
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {contact.name}
                    </MDTypography>
                    <MDBox mt={0.25} />
                    <MDTypography
                        variant="body2"
                        lineHeight={1}
                        style={{ "font-size": "0.9rem" }}
                        color={isSelected ? 'white' : 'black'}
                    >
                        {subtitle}
                    </MDTypography>
                </MDBox>
                {isFavoriteEnabled && <StarBorderOutlined fontSize="medium" style={{ marginRight: 8 }} />}
            </MDBox>
        </Card>
    );
};

export default ContactCard;