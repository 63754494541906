export class UserEmail {
    email: string;
    source: string;
    verified: boolean;

    constructor({
        email,
        source,
        verified,
    }: {
        email: string;
        source: string;
        verified: boolean;
    }) {
        this.email = email;
        this.source = source;
        this.verified = verified;
    }

    toMap(): { [key: string]: any } {
        return {
            email: this.email,
            source: this.source,
            verified: this.verified,
        };
    }

    static fromMap(
        map: { [key: string]: any } | string,
    ): UserEmail {
        if (typeof map === "string") {
            return new UserEmail({
                email: map,
                source: "manual",
                verified: false,
            });
        }

        return new UserEmail({
            email: map.email,
            source: map.source,
            verified: map.verified,
        });
    }
}