import { useState, useContext, useEffect } from "react";

// MUI
import { Box, Card, CircularProgress, Divider, Drawer, FormControl, Icon, TextField, useMediaQuery } from "@mui/material";
import { Draw, Edit } from "@mui/icons-material";

// Material Dashboard
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import SelectContact from "forge/core/components/SelectContact";
import { parseKnowledge } from "forge/core/knowledgeParsing";
import KnowledgeEdit from "../KnowledgeEdit";
import { KnowledgeContext, KnowledgeContextProvider } from "forge/knowledge/services/KnowledgeContext";
import theme from "assets/theme";
import { AuthContext } from "context";
import { Knowledge } from "forge/knowledge/schemas/knowledge";
import { KnowledgeType } from "forge/knowledge/schemas/knowledge-type";

function AddKnowledgeDrawer({
    openDrawer,
    handleCloseDrawer,
    contact,
    keepSelectedContact = false,
}: {
    openDrawer: boolean,
    handleCloseDrawer: () => void,
    contact?: any,
    keepSelectedContact?: boolean
}): JSX.Element {
    // UI
    const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const largeScreen = useMediaQuery(theme.breakpoints.up("lg"));

    // State
    const [selectedContact, setSelectedContact] = useState<any>(contact);
    const [selectedKnowledge, setSelectedKnowledge] = useState<any>();
    const [answer, setAnswer] = useState<string>("");
    const [knowledge, setKnowledge] = useState<Knowledge[]>([]);

    useEffect(() => {
        setSelectedContact(contact);
    }, [contact]);

    const handleClose = () => {
        if (!keepSelectedContact) {
            setSelectedContact(undefined);
        }
        return handleCloseDrawer();
    }

    return (
        <Drawer
            anchor="right"
            open={openDrawer}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    height: "fit-content",
                    maxHeight: "96%",
                    width: largeScreen ? "40%" : mediumScreen ? "70%" : "90%",
                },
            }}
        >
            {<div>
                {!selectedKnowledge && <MDBox display="flex" alignItems="baseline" pt={4} pb={0.5} px={3}>
                    <Draw
                        sx={{
                            transform: "translateY(4px)",
                        }}
                    />
                    <MDBox ml={1}>
                        <MDTypography variant="h5">
                            {selectedContact ?
                                `What have you learned from ${selectedContact?.firstName}?`
                                : "Who did you learn something from?"
                            }

                        </MDTypography>
                    </MDBox>
                    <Box style={{ flex: 1 }} />
                    <Icon
                        sx={{
                            cursor: "pointer",
                            transform: "translateY(4px)",
                        }}
                        onClick={handleClose}
                    >
                        close
                    </Icon>
                </MDBox>}
                <Divider />

                {/* Body */}

                {selectedContact ?
                    <KnowledgeContextProvider contact={selectedContact} loadKnowledge={false}>
                        {
                            selectedKnowledge
                                ? <KnowledgeEdit
                                    knowledge={selectedKnowledge}
                                    onEditCanceled={() => setSelectedKnowledge(undefined)}
                                    handleCloseDrawer={() => setSelectedKnowledge(undefined)}
                                />
                                : <AddKnowledgeBody
                                    handleCloseDrawer={handleClose}
                                    setSelectedKnowledge={setSelectedKnowledge}
                                    contact={contact}
                                    answer={answer}
                                    setAnswer={setAnswer}
                                    knowledge={knowledge}
                                    setKnowledge={setKnowledge}
                                />
                        }
                    </KnowledgeContextProvider>
                    : <SelectContact onContactSelected={(contact) => setSelectedContact(contact)} />}
            </div >
            }
        </Drawer>
    );
}

const AddKnowledgeBody = ({
    handleCloseDrawer,
    setSelectedKnowledge,
    contact,
    answer,
    setAnswer,
    knowledge,
    setKnowledge,
}: {
    handleCloseDrawer: () => void,
    setSelectedKnowledge: React.Dispatch<any>,
    contact: any,
    answer: string,
    setAnswer: React.Dispatch<React.SetStateAction<string>>,
    knowledge: Knowledge[],
    setKnowledge: React.Dispatch<React.SetStateAction<Knowledge[]>>,
}) => {
    // Context
    const { analyzeKnowledge } = useContext(KnowledgeContext);
    const { getCurrentUser } = useContext(AuthContext);

    // Services
    let { encryptionService, userRef, memberRef } = getCurrentUser();

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!answer) {
            handleCloseDrawer();
            return;
        }

        let newKnowledge = new Knowledge({
            contactRef: contact.ref,
            answer: answer,
            label: answer,
            type: KnowledgeType.user,
            analyzing: true,
            createdBy: memberRef ?? userRef,
            encryptedBy: memberRef ?? userRef,
        });

        setKnowledge([...knowledge, newKnowledge]);
        setAnswer("");

        try {
            // If successfully analyzed and created, add it to array
            newKnowledge = await analyzeKnowledge(newKnowledge, true);
            setKnowledge([...knowledge, newKnowledge]);
        } catch (error) {
            console.warn(error);
            setKnowledge(prevItems => {
                // Create a copy of the array without the last item
                const newItems = prevItems.slice(0, -1);
                return newItems;
            });
            // If an error has ocurred return the text to the textfield
            setAnswer(newKnowledge.answer);
        }
    };

    return (<>
        {knowledge.length == 0 &&
            <MDTypography variant="body2" color="text" px={3}>
                For example:
                <br />
                + Fan of the Lakers and Rams
                <br />
                + Has a chihuahua puppy
                <br />
                + Dad starting chemo on April 5<br />
            </MDTypography>}

        <FormControl
            component="form"
            onSubmit={handleSubmit}
            style={{ width: "100%" }}
        >
            <MDBox px={3} pt={1}>
                {knowledge.map((e) => (
                    e ?
                        <MDBox mb={2}>
                            <Card style={{ cursor: "pointer" }} onClick={() => setSelectedKnowledge(e)}>
                                <MDBox
                                    display="flex"
                                    alignItems="baseline"
                                    sx={{ justifyContent: "space-between" }}
                                    px={2}
                                    py={1}
                                >
                                    <MDTypography variant="body2" color="text" style={{ "white-space": "break-spaces" }}>
                                        {parseKnowledge(e)}
                                    </MDTypography>
                                    {e.analyzing ? <CircularProgress color="info" size="1.4rem" /> :
                                        <Edit
                                            color="action"
                                            sx={{
                                                cursor: "pointer",
                                                transform: "translateY(4px)",
                                            }}
                                        />
                                    }
                                </MDBox>
                            </Card>
                        </MDBox>
                        : <></>
                ))}

                <MDBox sx={{ display: "flex", alignItems: "flex-end" }} mb={1.5}>
                    <TextField
                        id="insight"
                        label="Save Insight here"
                        variant="outlined"
                        autoFocus
                        style={{ flex: 1 }}
                        value={answer}
                        onChange={(e) => setAnswer(e.target.value)}
                    />
                </MDBox>
            </MDBox>
            <Box
                sx={{
                    display: "flex",
                    gap: 1,
                    p: 1.5,
                    pb: 2,
                    borderTop: "1px solid",
                    borderColor: "divider",
                    justifyContent: "space-between",
                }}
            >
                <MDButton variant="text" color="dark" onClick={handleCloseDrawer}>
                    Done
                </MDButton>
                <MDButton variant="gradient" color="info" type="submit">
                    Save Insight
                </MDButton>
            </Box>
        </FormControl>
    </>);
}

// Setting default values for the props of AddKnowledge
AddKnowledgeDrawer.defaultProps = {
    handleCloseDrawer: null,
    contact: null,
};

export default AddKnowledgeDrawer;