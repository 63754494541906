import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import MDButton from 'components/MDButton';
import { DialogTitle, TextField } from '@mui/material';
import { useState } from 'react';

function AddEmailDialog({
    open,
    confirmationText = "Continue",
    onContinue,
    onClose,
}: {
    open: boolean,
    confirmationText?: string,
    onContinue: (email: string) => void
    onClose?: () => void
}) {
    const [email, setEmail] = useState<string>();

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={onClose}
                PaperProps={{
                    sx: { borderRadius: "16px" },
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    It seem like this contact has no emails attached, do you want to add one?
                </DialogTitle>
                <DialogContent>
                    <TextField
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoFocus
                        margin="dense"
                        id="key"
                        name="key"
                        label="Email"
                        type="email"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                            style: { color: 'gray' },
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <MDButton variant="outlined" onClick={() => onClose && onClose()} color={"info"}>
                        Cancel
                    </MDButton>
                    <MDButton onClick={() => onContinue(email)} color={"info"}>
                        {confirmationText ?? "Confirm"}
                    </MDButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default AddEmailDialog;