import { Save } from "@mui/icons-material";
import { Autocomplete, Card, IconButton, OutlinedInput, Slider, Stack, TextField, styled } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import dayjs, { Dayjs } from "dayjs";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { Pie } from "react-chartjs-2";
import { createUseStyles } from "react-jss";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { ForgeOpportunity, OpportunityStageOption, OpportunityType, opportunityTypes } from "types/pipeline/opportunity";
import { OpportunityContext } from "../../services/OpportunityContext";
import { AuthContext } from "context";
import ConfirmationDialog from "forge/core/components/ConfirmationDialog";
import Confetti from 'react-confetti'

const ForgeSlider = styled(Slider)({
    '& .MuiSlider-thumb': {
        height: 16,
        width: 16,
        backgroundColor: '#fff',
        border: '2px solid cornflowerblue',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&::before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 2,
        backgroundColor: '#fff',
    },
    '& .MuiSlider-track': {
        border: 'none',
        background: 'white',
        height: 5,
    },
    '& .MuiSlider-rail': {
        opacity: 0.5,
        boxShadow: 'inset 0px 0px 4px -2px #fff',
        backgroundColor: '#d0d0d0',
        height: 5,
    },
    "& .MuiSlider-mark": {
        height: "12px",
        borderRadius: "8px",
        color: "white",
    }
});

const styles = createUseStyles({
    root: {
        color: 'white',
        borderColor: 'white',
        border: '0px solid',
        "& .MuiOutlinedInput-input": {
            border: 0,
            color: "white",
        },
        "& .MuiIconButton-root": {
            color: "white",
        }
    },
    clearIndicator: {
        display: "none"
    },
    popupIndicator: {
        color: "white"
    }
});

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                prefix="$"
            />
        );
    },
);

function DealDetails({ opportunity }: { opportunity: ForgeOpportunity }) {
    const classes = styles();

    // Context
    const { getCurrentUser } = useContext(AuthContext);
    const opportunityContext = useContext(OpportunityContext);
    const opportunityContextRef = useRef(opportunityContext); // Ref to hold the context value

    // Data
    let { user, userProfileData, encryptionService } = getCurrentUser();

    // State
    const [stageOptions, setStageOptions] = useState<OpportunityStageOption[]>([]);
    const [initialStage, setInitialStage] = useState<number>(0);
    const [runConfettiAnim, setRunConfettiAnim] = useState<boolean>(false);
    const [stagePercentage, setStagePercentage] = useState<number>(0);
    const [stageEndDate, setStageEndDate] = useState<Dayjs>(dayjs());
    const [openStageDialog, setOpenStageDialog] = useState(false);

    const [editAmount, setEditAmount] = useState(false);
    const [amount, setAmount] = useState<string>(opportunity?.properties?.valueAmount?.toString());

    const [editCloseDate, setEditCloseDate] = useState(false);
    const [closeDate, setCloseDate] = useState<Dayjs>(dayjs(opportunity?.properties?.closeDate));

    const [editType, setEditType] = useState(false);
    const [businessType, setBusinessType] = useState<OpportunityType>(opportunity?.properties?.type ?? opportunityTypes[0]);

    const [editForecast, setEditForecast] = useState(false);
    const [forecastCategory, setForecastCategory] = useState<{ [key: string]: any }>();

    let USDollar = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    useEffect(() => {
        if (opportunity) {
            setStageOptions(opportunity.stageOptionsWithClosedStatesMerged);
            setInitialStage(opportunity.getPercentageFromStageOpportunity(false));
            setStagePercentage(opportunity.getPercentageFromStageOpportunity(false));
            if (opportunity.properties?.stageDetails) {
                setStageEndDate(dayjs(opportunity.properties.stageDetails.expectedEndDate));
                setAmount(opportunity.properties.valueAmount?.toString());
                setCloseDate(dayjs(opportunity.properties.closeDate));
                setBusinessType(opportunity.properties.type ?? opportunityTypes[0]);
                setForecastCategory(() => {
                    let options = userProfileData?.forecastOptions;
                    return options?.find((option) => option.value === opportunity?.properties.forecastCategory);
                });

                if (opportunity.properties?.stageDetails?.stage?.metadata?.isClosed && opportunity.properties?.stageDetails?.stage?.metadata?.probability) {
                    setRunConfettiAnim(true);
                }
            }
        }
    }, [opportunity, userProfileData]);

    useEffect(() => {
        opportunityContextRef.current = opportunityContext; // Update the ref whenever the context value changes
    }, [opportunityContext]);

    const handleSetStage = (stagePercentage: number) => {
        if (stagePercentage === 100) {
            setOpenStageDialog(true);
        } else {
            setStagePercentage(stagePercentage);
            handleOnSave({partialSave: true});
        }
    }

    const handleSetStageEndDate = (value: Dayjs) => {
        setStageEndDate(value);
        handleOnSave({expectedEndDateOverride: value});
    }

    const handleStageConfirmation = (result: boolean) => {
        setOpenStageDialog(false);
        const closedStagesOptions = opportunity?.closedStagesOptions;
        let stage;

        if (result) {
            stage = closedStagesOptions.find((e) => e.metadata.probability === 1);
            setStageEndDate(dayjs());
            setRunConfettiAnim(true);
        } else {
            stage = closedStagesOptions.find((e) => e.metadata.probability === 0);
        }

        if (stage) {
            handleOnSave({
                partialSave: false,
                stageOverride: stage,
            });
        }
    }

    function getFormattedDate(date: Date) {
        var year = date.getFullYear();

        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : "0" + month;

        var day = date.getDate().toString();
        day = day.length > 1 ? day : "0" + day;

        return month + "/" + day + "/" + year;
    }

    function handleOnSave({
        partialSave,
        stageOverride,
        expectedEndDateOverride,
    }: {
        partialSave?: boolean,
        stageOverride?: OpportunityStageOption,
        expectedEndDateOverride?: Dayjs,
    }) {
        opportunity.properties.stageDetails.stage = stageOverride ?? opportunity?.getStageOpportunityFromPercentage(stagePercentage);
        opportunity.properties.stageDetails.expectedEndDate = expectedEndDateOverride?.toDate() ?? stageEndDate.toDate();
        opportunity.properties.stageDetails.updatedAt = new Date();

        opportunityContextRef.current.updateOpportunity(opportunity);
        if (!partialSave) {
            setInitialStage(opportunity.getPercentageFromStageOpportunity(false));
            setStagePercentage(opportunity.getPercentageFromStageOpportunity(false));
            setStageEndDate(dayjs(opportunity.properties.stageDetails.expectedEndDate));
            setEditForecast(false);
        }
    }

    const saveAmount = () => {
        opportunity.properties.valueAmount = parseFloat(amount);
        opportunityContextRef.current.updateOpportunity(opportunity);
        setEditAmount(false);
    }

    const saveCloseDate = (value: dayjs.Dayjs) => {
        setCloseDate(value);
        opportunity.properties.closeDate = value.toDate();
        opportunityContextRef.current.updateOpportunity(opportunity);
        setEditCloseDate(false);
    }

    const saveType = (value: OpportunityType) => {
        setBusinessType(value);
        opportunity.properties.type = value;
        opportunityContextRef.current.updateOpportunity(opportunity);
        setEditType(false);
    }

    const saveForecast = (value: { [key: string]: any }) => {
        setForecastCategory(() => {
            let options = userProfileData.forecastOptions;
            return options.find((option) => option.value === value.value);
        });
        opportunity.properties.forecastCategory = value.value;
        opportunityContextRef.current.updateOpportunity(opportunity);
        setEditForecast(false);
    }

    return (
        <Card id="deal-activities">
            <Confetti
                run={runConfettiAnim}
                style={{ width: "-webkit-fill-available" }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ConfirmationDialog
                    openDialog={openStageDialog}
                    handleCloseDialog={handleStageConfirmation}
                    title="Have you won this opportunity?"
                    description=""
                    confirmText="Won"
                    denyText="Lost"
                    denyVariant="outlined"
                />
                <MDBox m={3}>
                    <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                    >
                        <MDTypography
                            variant="h6"
                            fontWeight="medium"
                            textTransform="capitalize"
                        >
                            Details
                        </MDTypography>
                    </MDBox>
                    <MiniStatisticsCard
                        bgColor="dark"
                        title={{ text: "Stage" }}
                        count={
                            <Stack direction="column" spacing={1}>
                                <ForgeSlider
                                    aria-label="stage"
                                    defaultValue={initialStage}
                                    value={stagePercentage}
                                    valueLabelDisplay="auto"
                                    valueLabelFormat={value => {
                                        const stageOpportunity = opportunity?.getStageOpportunityFromPercentage(value);
                                        return <div>{stageOpportunity?.label || ''}</div>;
                                    }}
                                    onChange={(event, value) => setStagePercentage(value as number)}
                                    onChangeCommitted={(event, value) => handleSetStage(value as number)}
                                    marks
                                    step={stageOptions.length > 1
                                        ? 100 / (stageOptions.length - 1)
                                        : 100}
                                    min={0}
                                    max={100}
                                    style={{
                                        color: '#52af77',
                                        height: 8,
                                    }}
                                />
                                {initialStage != stagePercentage && stagePercentage != 100 && <div>
                                    <Stack
                                        direction="row"
                                        alignSelf={"center"}
                                        justifyContent={"space-between"}
                                        alignItems="space-between"
                                        spacing={1}
                                        style={{ width: "-webkit-fill-available" }}
                                    >
                                        <MDTypography
                                            sx={{ "white-space": "break-spaces" }}
                                            color="white"
                                            variant="body2"
                                            fontWeight="small">
                                            Next Stage:
                                        </MDTypography><MDTypography
                                            sx={{ "white-space": "break-spaces" }}
                                            color="white"
                                            variant="body2"
                                            fontWeight="bold">
                                            {opportunity?.getStageOpportunityFromPercentage(stagePercentage)?.label ?? ""}
                                        </MDTypography>
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        alignSelf={"center"}
                                        justifyContent={"space-between"}
                                        alignItems="space-between"
                                        spacing={1}
                                        style={{ width: "-webkit-fill-available", margin: 0 }}
                                    >
                                        <MDTypography
                                            sx={{ "white-space": "break-spaces" }}
                                            color="white"
                                            variant="body2"
                                            fontWeight="small">
                                            Expected End Date:
                                        </MDTypography><MDTypography
                                            sx={{ "white-space": "break-spaces" }}
                                            color="white"
                                            variant="body2"
                                            fontWeight="bold">
                                            {stageEndDate.toDate().toLocaleDateString("en-US", {
                                                month: "short",
                                                day: "2-digit",
                                                year: "numeric",
                                                // timeZone: 'UTC',
                                            })}
                                        </MDTypography>
                                    </Stack>
                                    <DatePicker
                                        format="MMM DD, YYYY"
                                        value={stageEndDate}
                                        onChange={(value, _) => handleSetStageEndDate(value)}
                                        sx={{ width: "-webkit-fill-available", color: "white", marginTop: 1 }}
                                        className={classes.root}
                                    />
                                </div>}
                            </Stack>
                        }
                        icon={{
                            color: "dark",
                            component: <Pie data={{
                                labels: [],
                                datasets: [
                                    {
                                        label: [],
                                        weight: 9,
                                        cutout: 0,
                                        tension: 0.9,
                                        pointRadius: 2,
                                        borderWidth: 2,
                                        backgroundColor: ["dark", "white"],
                                        fill: false,
                                        data: [stagePercentage, 100 - stagePercentage],
                                    },
                                ],
                            }} />
                        }}
                        direction="left"
                    />
                    <MDBox mt={2} />
                    <MiniStatisticsCard
                        bgColor="dark"
                        title={{ text: "Amount" }}
                        count={editAmount ?
                            <TextField
                                id="amount"
                                variant="outlined"
                                style={{ flex: 1, width: "-webkit-fill-available" }}
                                inputProps={{ style: { color: "white" } }}
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        saveAmount();
                                    }
                                }}
                                InputProps={{
                                    inputComponent: NumericFormatCustom as any,
                                    endAdornment: (<Save
                                        fontSize="medium"
                                        style={{ color: "white" }}
                                        onClick={saveAmount}
                                    />)
                                }}
                            />
                            : <div onClick={() => setEditAmount(!editAmount)}>
                                {USDollar.format(opportunity?.properties?.valueAmount)}
                            </div>
                        }
                        icon={{ color: "dark", component: "attach_money" }}
                        direction="left"
                    />
                    <MDBox mt={2} />
                    <MiniStatisticsCard
                        bgColor="dark"
                        title={{ text: "Close Date" }}
                        icon={{ color: "dark", component: "event_available" }}
                        direction="left"
                        sx={{ mt: 2 }}
                        count={editCloseDate ?
                            <DatePicker
                                format="MMM DD, YYYY"
                                value={closeDate}
                                onChange={saveCloseDate}
                                sx={{ width: "-webkit-fill-available", color: "white" }}
                                className={classes.root}
                            />
                            : <div onClick={() => setEditCloseDate(!editCloseDate)}>
                                {getFormattedDate(
                                    new Date(opportunity?.properties?.closeDate)
                                )}
                            </div>
                        }
                    />
                    <MDBox mt={2} />
                    <MiniStatisticsCard
                        bgColor="dark"
                        title={{ text: "Type" }}
                        count={editType ?
                            <Autocomplete
                                id="business-type"
                                style={{ flex: 1 }}
                                options={opportunityTypes}
                                value={businessType}
                                onChange={(event: any, newValue: any) => saveType(newValue)}
                                getOptionLabel={(option) => option.label}
                                classes={{
                                    clearIndicator: classes.clearIndicator,
                                    popupIndicator: classes.popupIndicator
                                }}
                                renderInput={
                                    (params) => <MDInput {...params}
                                        variant="outlined"
                                        label="Business Type"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                            style: { color: "white" }
                                        }}
                                        InputLabelProps={{ style: { color: 'white' } }}
                                    />
                                }
                            /> : <div onClick={() => setEditType(!editType)}>
                                {businessType?.label ?? 'Unset'}
                            </div>
                        }
                        icon={{ color: "dark", component: "category_outlined" }}
                        direction="left"
                        sx={{ mt: 2 }}
                    />
                    <MDBox mt={2} />
                    {/* userProfileData */}
                    <MiniStatisticsCard
                        bgColor="dark"
                        title={{ text: "Forecast Category" }}
                        count={editForecast ?
                            <Autocomplete
                                id="forecast-category"
                                style={{ flex: 1 }}
                                options={userProfileData.forecastOptions}
                                value={forecastCategory}
                                onChange={(event: any, newValue: any) => saveForecast(newValue)}
                                getOptionLabel={(option) => option.label}
                                classes={{
                                    clearIndicator: classes.clearIndicator,
                                    popupIndicator: classes.popupIndicator
                                }}
                                renderInput={
                                    (params) => <MDInput {...params}
                                        variant="outlined"
                                        label="Forecast Category"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                            style: { color: "white" }
                                        }}
                                        InputLabelProps={{ style: { color: 'white' } }}
                                    />
                                }
                            /> : <div onClick={() => setEditForecast(!editForecast)}>
                                {forecastCategory?.label ?? 'Unset'}
                            </div>
                        }
                        icon={{ color: "dark", component: "thermostat" }}
                        direction="left"
                        sx={{ mt: 2 }}
                    />
                </MDBox>
            </LocalizationProvider>
        </Card>
    );
}

export default DealDetails;