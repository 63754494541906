// Images
import hubspotLogo from "assets/forge/svgs/hubspot-icon.svg";
import salesforceLogo from "assets/forge/svgs/salesforce.svg";

export enum Crm {
    hubspot = "hubspot",
    salesforce = "salesforce",
}

// Reverse mapping
type CrmStringMap = {
    [key in Crm]: string;
};

export const crmStringMap: CrmStringMap = {
    [Crm.hubspot]: "hubspot",
    [Crm.salesforce]: "salesforce",
};

export const CrmExtended = {
    ...Crm,
    crmFromIntegration(integration: string): Crm | undefined {
        switch (integration) {
            case "hubspot":
                return Crm.hubspot;
            case "salesforce":
                return Crm.salesforce;
            default:
                return;
        }
    },
    getName(crm: Crm): string {
        switch (crm) {
            case Crm.hubspot:
                return "Hubspot";
            case Crm.salesforce:
                return "Salesforce";
            default:
                return "";
        }
    },
    getIcon(crm: Crm): any {
        switch (crm) {
            case Crm.hubspot:
                return hubspotLogo;
            case Crm.salesforce:
                return salesforceLogo;
            default:
                return "";
        }
    },
}