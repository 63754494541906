import { Card, Chip, Drawer, Grid, useMediaQuery } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { AuthContext, ContactsContext } from "context";
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ForgeOpportunity } from "types/pipeline/opportunity";
import ReactFlow, { Background, BackgroundVariant, Controls, Edge, Node, NodeDragHandler, PanOnScrollMode, Position, ReactFlowInstance, applyEdgeChanges, applyNodeChanges } from 'reactflow';
import 'reactflow/dist/style.css';
import { OpportunityContactCard, CustomEdge, Title } from "../OpportunityContactCard";
import { OpportunityAddContactCard } from "../OpportunityAddContactCard";
import { OpportunityContext } from "../../services/OpportunityContext";
import theme from "assets/theme";
import AddContactToOpportunity from "../AddContactToOpportunity";
import MDButton from "components/MDButton";
import { RemoteConfigContext } from "forge/core/services/RemoteConfigContext";

// Create a context to hold the isDragging state
export const DraggingContext = createContext({
    isDragging: false,
});

function DealPeople({ opportunity }: { opportunity: ForgeOpportunity }) {
    // Context
    const { warmthAlgorithm } = useContext(RemoteConfigContext);
    const { getContacts, getPossibleUserContacts } = useContext(ContactsContext);
    const { getCurrentUser } = useContext(AuthContext);
    const { user } = getCurrentUser();
    const opportunityContext = useContext(OpportunityContext);
    const opportunityContextRef = useRef(opportunityContext); // Ref to hold the context value
    const opportunityRef = useRef(opportunity); // Ref to hold the context value

    // State
    const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance<any, any> | undefined>();
    const [openAddContactDrawer, setOpenAddContactDrawer] = useState<boolean>(false);
    const [addContactType, setAddContactType] = useState<string>('');
    const [teamClientRelations, setTeamClientRelations] = useState<{ [key: string]: string[] }>(opportunity?.teamClientRelations);
    const [clientContacts, setClientContacts] = useState<any[]>([]);
    const clientContactsRef = useRef(clientContacts); // Ref to hold the context value
    const [teamContacts, setTeamContacts] = useState<any[]>([]);
    const teamContactsRef = useRef(teamContacts); // Ref to hold the context value
    const [nodes, setNodes] = useState<Node[]>([]);
    const nodesRef = useRef(nodes); // Ref to hold the context value
    const [edges, setEdges] = useState<Edge[]>([]);
    const [tempEdges, setTempEdges] = useState<Edge[]>([]);
    const [editing, setEditing] = useState<boolean>(false);

    useEffect(() => {
        if (opportunity) {
            opportunityRef.current = opportunity;
        }
    }, [opportunity]);

    useEffect(() => {
        if (opportunity) {
            let contacts = getContacts(opportunity.clientContactsIds);
            let finalContacts = opportunity.orderedClientContacts(
                contacts, 
                warmthAlgorithm,
                user.uid,
            );
            setClientContacts(finalContacts);
            clientContactsRef.current = finalContacts;
        }
    }, [opportunity?.clientContactsIds]);

    useEffect(() => {
        if (opportunity) {
            let contacts = getContacts(opportunity.teamContactsIds);
            let finalContacts = opportunity.orderedTeamContacts(contacts);
            setTeamContacts(finalContacts);
            teamContactsRef.current = finalContacts;
        }
    }, [opportunity?.teamContactsIds]);

    useEffect(() => {
        if (opportunity) {
            setTeamClientRelations(opportunity.teamClientRelations);
        }
    }, [opportunity?.teamClientRelations]);

    const renderNodes = () => {
        let newsNodes: Node[] = [
            {
                id: 'clientTeam',
                position: { x: 62, y: -40 },
                data: "Client Team",
                type: 'title',
            },
            {
                id: 'myTeam',
                position: { x: 350, y: -40 },
                data: "My Team",
                type: 'title',
            },
        ];
        let newEdges: any[] = [];

        // Client Nodes
        let clientIndex = 0;
        for (const clientContact of clientContactsRef.current) {
            newsNodes.push({
                id: clientContact.id,
                position: { x: 0, y: 100 * clientIndex },
                data: { opportunity: opportunityRef.current, contact: clientContact, type: "client" },
                type: 'opportunityContact',
            });
            clientIndex++;
        }

        // Team Nodes
        let teamIndex = 0;
        let generalOffset = 0;
        for (const teamContact of teamContactsRef.current) {
            let amountOfRelations = opportunityRef.current.getAmountOfRelations(teamContact, clientContactsRef.current);
            let offset = 0;
            if (amountOfRelations > 1) {
                let tmpAmount = amountOfRelations - 1;
                offset = (tmpAmount / 2) * 100;
            }

            newsNodes.push({
                id: teamContact.id,
                position: { x: 275, y: offset + generalOffset },
                data: { opportunity: opportunityRef.current, contact: teamContact, type: "team" },
                type: 'opportunityContact',
            });

            if (amountOfRelations > 1) {
                generalOffset += amountOfRelations * 100;
            } else {
                generalOffset += 100;
            }
            teamIndex++;
        }

        // Add Contact Cards
        let maxOffset = Math.max(100 * clientIndex, generalOffset);
        newsNodes.push({
            id: 'addClientContact',
            position: { x: 0, y: maxOffset },
            data: {
                opportunity: opportunityRef.current,
                type: "client",
                editing,
                onClick: () => {
                    setOpenAddContactDrawer(true);
                    setAddContactType('client');
                }
            },
            type: 'opportunityAddContactCard',
        });
        newsNodes.push({
            id: 'addTeamContact',
            position: { x: 275, y: maxOffset },
            data: {
                opportunity: opportunityRef.current,
                type: "team",
                editing,
                onClick: () => {
                    setOpenAddContactDrawer(true);
                    setAddContactType('team');
                }
            },
            type: 'opportunityAddContactCard',
        });

        setNodes(newsNodes);
        // console.log("nodes", newsNodes);

        // Create edges
        if (opportunityRef.current?.teamClientRelations) {
            let possibleUserContactsIds = getPossibleUserContacts().map(e => e.id);
            for (const relations of Object.entries(opportunityRef.current!.teamClientRelations)) {
                let teamMember = relations[0];
                for (const client of relations[1]) {
                    if (client) {
                        let id = `${teamMember}-${client}`;

                        if (!newEdges.some((edge) => edge.id === id)) {
                            newEdges.push({
                                id: id,
                                source: teamMember,
                                target: client,
                                type: 'custom-edge',
                                data: {
                                    enableColor: possibleUserContactsIds.includes(teamMember),
                                    contact: clientContactsRef.current.find((e) => e.id === client),
                                }
                            });
                        }
                    }
                }
            }
            // console.log("edges", newEdges);
            setEdges(newEdges);
        }

        if (reactFlowInstance) {
            reactFlowInstance?.fitView();
            let viewport = reactFlowInstance.getViewport();
            reactFlowInstance.setViewport({ x: 20, y: 50, zoom: viewport.zoom });
        }
    }

    useEffect(() => {
        renderNodes();
    }, [clientContacts, teamContacts, teamClientRelations]);

    useEffect(() => {
        nodesRef.current = nodes; // Update the ref whenever the context value changes
    }, [nodes]);

    useEffect(() => {
        opportunityContextRef.current = opportunityContext; // Update the ref whenever the context value changes
    }, [opportunityContext]);

    // React Flow
    const nodeTypes = useMemo(() => ({ opportunityContact: OpportunityContactCard, opportunityAddContactCard: OpportunityAddContactCard, title: Title }), []);
    const edgeTypes = { 'custom-edge': CustomEdge }
    let dragHoverNode: Node | undefined;
    const onNodesChange = useCallback(
        (changes: any) => {
            // console.log("changes", changes);
            if (changes.length > 0 && changes[0].dragging !== undefined) {
                if (changes[0].dragging && changes[0].position) {
                    setEditing(true);

                    // User is dragging a Contact Card
                    dragHoverNode = nodesRef.current.find((node) => {
                        let xCenter = changes[0].position.x + 112;
                        let yCenter = changes[0].position.y + 37;
                        let containedInX = node.position.x <= xCenter && xCenter <= (node.position.x + 225);
                        let containedInY = node.position.y <= yCenter && yCenter <= (node.position.y + 75);
                        let isNotTheContactBeingDragged = node.id != changes[0].id;

                        // if (containedInX && containedInY && isNotTheContactBeingDragged) console.log(node);
                        return containedInX && containedInY && isNotTheContactBeingDragged;
                    });
                    // console.log(dragHoverNode);
                } else if (!changes[0].dragging) {
                    setEditing(false);
                    if (dragHoverNode) {

                        // User has release the Contact Card
                        let draggedContact = nodesRef.current.find((e) => e.id == changes[0].id);
                        let hoveringOverContact = dragHoverNode;

                        if (hoveringOverContact.type == 'opportunityAddContactCard') {
                            // Contact is being removed
                            if (draggedContact.data.type === hoveringOverContact.data.type) {
                                if (draggedContact.data.type == "client") {
                                    opportunityContextRef.current.removeClientContact(draggedContact.data.contact);
                                } else {
                                    opportunityContextRef.current.removeTeamContact(draggedContact.data.contact);
                                }
                                dragHoverNode = undefined;
                            }
                        } else {
                            if (hoveringOverContact) {
                                // New/Updated Relationship
                                if (draggedContact.data.type !== hoveringOverContact.data.type) {
                                    if (draggedContact.data.type == "client") {
                                        opportunityContextRef.current.setClientTeamRelationship(draggedContact.data.contact, hoveringOverContact.data.contact);
                                    } else {
                                        opportunityContextRef.current.setClientTeamRelationship(hoveringOverContact.data.contact, draggedContact.data.contact);
                                    }
                                    dragHoverNode = undefined;
                                }
                            } else {
                                renderNodes();
                            }
                        }
                    } else {
                        renderNodes();
                    }
                }
            }

            // console.log("changes", changes);
            return setNodes((nds) => applyNodeChanges(changes, nds));
        },
        [],
    );

    const onNodeDragStart: NodeDragHandler = useCallback((event, node) => {
        setTempEdges(edges);
        setEdges(edges.filter((edge) => edge.target !== node.id));
    }, [edges]);

    const onEdgesChange = useCallback(
        (changes: any) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [],
    );

    const handleCloseDrawer = (contact?: any) => {
        if (contact) {
            if (addContactType === 'client') {
                opportunityContextRef.current.addClientContact(contact);
            } else if (addContactType === 'team') {
                opportunityContextRef.current.addTeamContact(contact);
            }
            setAddContactType('');
        }
        return setOpenAddContactDrawer(false);
    };
    const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const largeScreen = useMediaQuery(theme.breakpoints.up("lg"));

    return (
        <DraggingContext.Provider value={{ isDragging: editing }}>
            <Card sx={{ height: "1000px" }}>
                <Drawer
                    anchor="right"
                    open={openAddContactDrawer}
                    onClose={() => handleCloseDrawer()}
                    PaperProps={{
                        sx: {
                            width: largeScreen ? "40%" : mediumScreen ? "70%" : "90%",
                        },
                    }}
                >
                    <AddContactToOpportunity
                        handleCloseDrawer={handleCloseDrawer}
                        clientContacts={clientContacts}
                        teamContacts={teamContacts}
                        addContactType={addContactType}
                    />
                </Drawer>
                <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    m={3}
                >
                    <MDTypography
                        variant="h6"
                        fontWeight="medium"
                        textTransform="capitalize"
                    >
                        People
                    </MDTypography>

                    {(Object.entries(opportunityContextRef.current.newRelationships).length > 0 || Object.entries(opportunityContextRef.current.droppedRelationships).length > 0) && <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        type="submit"
                        onClick={() => {
                            window.location.href = `mailto:${opportunityContextRef.current.opportunity.teamContacts.filter((e) => e.emailStrings && e.emailStrings.length > 0).map((e) => e.emailStrings[0]).join(',')}?subject=${`${opportunityContextRef.current.opportunity.properties?.name}: Relationship Changes`}&body=${encodeURIComponent(opportunityContextRef.current.generateTeamNotification())}`;
                        }}
                        style={{ textTransform: "none" }}
                    >
                        Notify Team
                    </MDButton>}
                </MDBox>
                <div style={{ height: '100%' }}>
                    <ReactFlow
                        nodeTypes={nodeTypes}
                        onNodesChange={onNodesChange}
                        onNodeDragStart={onNodeDragStart}
                        edgeTypes={edgeTypes}
                        nodes={nodes}
                        edges={edges}
                        defaultViewport={{ x: 20, y: 50, zoom: 0.99 }}
                        panOnDrag={false}
                        panOnScroll={true}
                        panOnScrollMode={PanOnScrollMode.Vertical}
                        zoomOnScroll={false}
                        zoomOnPinch={false}
                        zoomOnDoubleClick={false}
                        preventScrolling={false}
                        fitView={true}
                        snapToGrid={true}
                        onInit={(instance) => setReactFlowInstance(instance)}
                        selectionOnDrag>
                        <Background variant={BackgroundVariant.Lines} lineWidth={0} />
                        <Controls
                            position={'top-right'}
                            showZoom={false}
                            showInteractive={false}
                        />
                    </ReactFlow>
                </div>
            </Card>
        </DraggingContext.Provider>
    );
}

export default DealPeople;