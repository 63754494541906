import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/system';

// Create a styled Avatar component with a hexagon shape
export const HexagonAvatar = styled(Avatar)(({ theme }) => ({
  width: '50px',
  height: '50px',
  fontSize: "1rem",
  cursor: "pointer",
  backgroundColor: theme.palette.primary.main,
  clipPath: 'polygon(25% 6.7%, 75% 6.7%, 100% 50%, 75% 93.3%, 25% 93.3%, 0% 50%)',
  WebkitClipPath: 'polygon(25% 6.7%, 75% 6.7%, 100% 50%, 75% 93.3%, 25% 93.3%, 0% 50%)',
}));