import {
    collection,
    doc,
    documentId,
    DocumentReference,
    getDoc,
    getDocs,
    onSnapshot,
    query,
    Unsubscribe,
    where,
} from "firebase/firestore";
import { firestoreDb } from "firebase.init";
import { User } from "firebase/auth";
import { UserProfileData } from "types/user/user-profile-data";
import { ForgeOrganization } from "../types/organization";
import { ForgeEncryption } from "forge/core/services/encryption";
import { ForgeOrganizationMember } from "../types/member";

class OrganizationFirestore {
    private user: User;
    private userProfileData: UserProfileData;
    private encryptionService: ForgeEncryption;

    constructor(user: User, userProfileData: UserProfileData, encryptionService: ForgeEncryption) {
        this.user = user;
        this.userProfileData = userProfileData;
        this.encryptionService = encryptionService;
    }

    getMembershipDetails = (
        onEvent: (member: ForgeOrganizationMember) => void
    ): Unsubscribe => {
        const docRef = doc(firestoreDb, `organizations/${this.userProfileData.organization?.id}/members/${this.user.uid}`);

        return onSnapshot(docRef, async (snapshot) => {
            if (snapshot.exists() && snapshot.data()) {
                return onEvent(ForgeOrganizationMember.fromFirestore(snapshot));
            }
        });
    }

    getParentOrganization = async (ref: DocumentReference): Promise<ForgeOrganization | undefined> => {
        const organizationDoc = await getDoc(ref);

        if (organizationDoc.exists) {
            return ForgeOrganization.fromFirestore(organizationDoc);
        }

        return undefined;
    }

    getOrganizationLive = (
        onEvent: (organization: ForgeOrganization) => void
    ): Unsubscribe => {
        const docRef = doc(firestoreDb, `organizations/${this.userProfileData.organization?.id}`);

        return onSnapshot(docRef, async (snapshot) => {
            if (snapshot.exists() && snapshot.data()) {
                return onEvent(ForgeOrganization.fromFirestore(snapshot));
            }
        });
    }

    getOrganizationDivisionsLive = (
        refs: DocumentReference[],
        onEvent: (organizations: ForgeOrganization[]) => void
    ): Unsubscribe => {
        const queryResult = query(
            collection(firestoreDb, `organizations`),
            where("ref", "in", refs),
        );

        return onSnapshot(queryResult, async (snapshot) => {
            return onEvent(snapshot.docs.map(
                (doc) => ForgeOrganization.fromFirestore(doc)
            ));

        });
    }

    verifyDomainAvailability = async (domainsToVerify: string[]): Promise<string[]> => {
        const collectionRef = collection(firestoreDb, `organizations`);

        // Query documents where document ID is in the list of IDs to check
        const q = query(collectionRef, where(documentId(), 'in', domainsToVerify));

        // Execute the query
        const querySnapshot = await getDocs(q);

        // Extract IDs of the documents that exist
        const existingDomains = querySnapshot.docs.map(doc => doc.id);

        return domainsToVerify.filter((domain) => !existingDomains.includes(domain));
    }
}

export default OrganizationFirestore;