import HttpService from "forge/core/services/http.service";

class MilestonesApi {
    getMilestoneNote = async (milestone: any): Promise<any> => {
        const endpoint = `/milestones/${milestone.ref?.id}/note`;
        let result = await HttpService.get(endpoint);

        return result ?? "";
    };
}

export default new MilestonesApi();