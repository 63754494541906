// @mui material components

import MDBox from "components/MDBox";
import { Avatar } from "@mui/material";
import { Language } from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import { deepOrange, grey } from "@mui/material/colors";

function SearchTermTile({ searchTerm, isSelected = false, logoUrl, onClick }: { searchTerm: string, isSelected?: boolean, logoUrl?: string, onClick?: () => void }): JSX.Element {
    return (
        <MDBox
            display="flex"
            alignItems="center"
            lineHeight={1}
            mb={1.5}
            style={{ cursor: "pointer" }}
            onClick={onClick}
        >
            <Avatar sx={{ bgcolor: grey[isSelected ? 900 : 500], width: 44, height: 44, marginTop: "4px" }} variant="rounded" src={logoUrl} >
                <Language style={{ color: "white" }} />
            </Avatar>
            <MDBox ml={1.5}>
                <MDTypography variant="body2" fontWeight="bold" style={{ color: 'cornflowerblue' }}>
                    {searchTerm}
                </MDTypography>
            </MDBox>
        </MDBox>
    );
}

// Setting default values for the props of NewsCard
SearchTermTile.defaultProps = {
    location: null,
};

export default SearchTermTile;