
// @material-ui core components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Can } from "forge/organization/rbac/CanContext";

// Settings page components
import { useContext, useEffect, useState } from "react";
import MDButton from "components/MDButton";
import { OrganizationContext } from "forge/organization/services/OrganizationContext";
import DataTable from "examples/Tables/DataTable";
import { DisabledTooltip } from "forge/organization/rbac/DisabledTooltip";
import { Box } from "@mui/material";
import CreateOrganizationDivisionDialog from "../CreateOrganizationDivisionDialog";
import { ForgeOrganization } from "forge/organization/types/organization";

function OrganizationDivisions(): JSX.Element {
    // Context
    const { divisions } = useContext(OrganizationContext);

    // State
    const [tableData, setTableData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    useEffect(() => {
        setTableData(getRows(divisions));
    }, [divisions]);

    const getRows = (info: ForgeOrganization[]) => {
        let updatedInfo = info?.map((row) => {
            return {
                type: "division",
                id: row.ref.id,
                name: row.name,
                members: 0,
                division: row,
            };
        });

        return updatedInfo ?? [];
    };

    const dataTableData = {
        columns: [
            {
                Header: "name",
                width: "20%",
                Cell: (info: any) => {
                    return (<Box sx={{ ml: 2 }}>{info.cell.row.original.name}</Box>);
                },
            },
            { Header: "members", accessor: "members", width: "15%" },
        ],

        rows: tableData
    };

    return (<Card id="divisions" sx={{ overflow: "visible", marginBottom: "24px" }}>
        <CreateOrganizationDivisionDialog
            open={openDialog}
            handleClose={handleCloseDialog}
        />
        <MDBox
            p={3}
            lineHeight={1}
            display="flex"
            justifyContent="space-between"
        >
            <MDTypography variant="h5">Divisions</MDTypography>
            <Can I="invite" an="members" passThrough>
                {allowed =>
                    <DisabledTooltip allowed={allowed}>
                        <MDButton
                            variant="gradient"
                            color="dark"
                            size="small"
                            type="submit"
                            disabled={!allowed}
                            onClick={handleOpenDialog}
                        >
                            + Add Division
                        </MDButton>
                    </DisabledTooltip>
                }
            </Can>
        </MDBox>
        <DataTable
            tableId="teams"
            table={dataTableData}
            canSearch={true}
            onRowClick={(row, index) => {

            }}
        />
    </Card>);
}

export default OrganizationDivisions;
