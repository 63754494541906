import { Timestamp } from "firebase/firestore";
import { Company } from "../company";
import { getWarmthScore } from "types/contact/contact";
import { ForgeEncryption } from "forge/core/services/encryption";

export enum OpportunityState {
    active = "OpportunityState.active",
    onHold = "OpportunityState.onHold",
    underAppeal = "OpportunityState.underAppeal",
    won = "OpportunityState.won",
    lost = "OpportunityState.lost",
    canceled = "OpportunityState.canceled",
    withdrawn = "OpportunityState.withdrawn",
}

// eslint-disable-next-line
interface OpportunityStateDetails {
    state: OpportunityState;
    updatedAt: Date;
}

// eslint-disable-next-line
interface OpportunityStageDetails {
    stage: OpportunityStageOption;
    expectedEndDate: Date;
    updatedAt: Date;
}

// eslint-disable-next-line
export interface OpportunityStageOption {
    label: string;
    displayOrder: number;
    metadata: {
        isClosed?: boolean;
        probability?: number;
    };
    id: string;
    archived: boolean;
}

export interface OpportunityType {
    label: string;
    value: string;
    displayOrder: number;
    hidden: boolean;
}

export const opportunityTypes: OpportunityType[] = [
    {
        label: "New Business",
        value: "newbusiness",
        displayOrder: 0,
        hidden: false,
    },
    {
        label: "Existing Business",
        value: "existingbusiness",
        displayOrder: 0,
        hidden: false,
    },
];

export const hubspotForecastOptions: { [key: string]: any }[] = [
    {
        label: "Not forecasted",
        value: "OMIT",
        description: "",
        displayOrder: 1,
        hidden: false
    },
    {
        label: "Pipeline",
        value: "PIPELINE",
        description: "",
        displayOrder: 2,
        hidden: false
    },
    {
        label: "Best case",
        value: "BEST_CASE",
        description: "",
        displayOrder: 3,
        hidden: false
    },
    {
        label: "Commit",
        value: "COMMIT",
        description: "",
        displayOrder: 4,
        hidden: false
    },
    {
        label: "Closed won",
        value: "CLOSED",
        description: "",
        displayOrder: 5,
        hidden: false
    }
];

export const salesforceForecastOptions: { [key: string]: any }[] = [
    {
        label: "Omitted",
        value: "Omitted",
        description: "",
        displayOrder: 1,
        hidden: false
    },
    {
        label: "Pipeline",
        value: "Pipeline",
        description: "",
        displayOrder: 2,
        hidden: false
    },
    {
        label: "Best case",
        value: "Best case",
        description: "",
        displayOrder: 3,
        hidden: false
    },
    {
        label: "Commit",
        value: "Commit",
        description: "",
        displayOrder: 4,
        hidden: false
    },
    {
        label: "Closed won",
        value: "Closed won",
        description: "",
        displayOrder: 5,
        hidden: false
    }
];


interface Properties {
    name: string;
    company: Company;
    stateDetails: OpportunityStateDetails;
    stageDetails: OpportunityStageDetails;
    valueAmount: number;
    closeDate?: Date | null;
    type: OpportunityType;
    leadSource?: string;
    priority: string;
    forecastCategory?: string;
}

export const opportunityTeamRoles = [
    "Opportunity Owner",
    "Proposal Team",
    "Technical Lead",
    "Technical Support",
    "Proposal Lead",
    "Proposal Support",
    "SME",
    "Legal",
    "Finance",
    "Estimator",
    "Executive Owner",
    "Executive Support",
    "BDR",
    "Account Manager",
    "Account Owner",
    "CSR",
    "Consultant"
];

export const opportunityClientRoles = [
    "Decision Maker",
    "Procurement Lead",
    "Procurement Support",
    "Technical Lead",
    "Technical Support",
    "Client Champion",
    "Economic Buyer",
    "Technical Buyer",
    "Procurement Buyer",
    "River Guide",
    "Legal",
    "Influencer"
];

// eslint-disable-next-line
export class ForgeOpportunity {
    id: string;
    properties: Properties;
    teamUsersIds: string[];
    teamContactsIds: string[];
    teamContacts: any[];
    clientContactsIds: string[];
    clientContacts: any[];
    teamClientRelations: { [key: string]: string[] } | undefined;
    contactsRoles: { [key: string]: string } | undefined;
    createdAt: Date;
    updatedAt: Date;
    stageOptions: OpportunityStageOption[];
    encrypted: boolean;

    // eslint-disable-next-line
    constructor({
        id,
        properties,
        teamUsersIds,
        teamContactsIds,
        teamContacts,
        clientContactsIds,
        clientContacts,
        teamClientRelations,
        contactsRoles,
        createdAt,
        updatedAt,
        stageOptions,
        encrypted,
    }: {
        id: string;
        properties: Properties;
        teamUsersIds: string[];
        teamContactsIds: string[];
        teamContacts: any[];
        clientContactsIds: string[];
        clientContacts: any[];
        teamClientRelations: { [key: string]: string[] } | undefined;
        contactsRoles: { [key: string]: string } | undefined;
        createdAt: Date;
        updatedAt: Date;
        stageOptions: OpportunityStageOption[];
        encrypted: boolean;
    }) {
        this.id = id;
        this.properties = properties;
        this.teamUsersIds = teamUsersIds;
        this.teamContactsIds = teamContactsIds;
        this.teamContacts = teamContacts;
        this.clientContactsIds = clientContactsIds;
        this.clientContacts = clientContacts;
        this.teamClientRelations = teamClientRelations;
        this.contactsRoles = contactsRoles;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.stageOptions = stageOptions;
        this.encrypted = encrypted;
    }

    // eslint-disable-next-line
    copyWith({
        id,
        properties,
        teamUsersIds,
        teamContactsIds,
        teamContacts,
        clientContactsIds,
        clientContacts,
        teamClientRelations,
        contactsRoles,
        createdAt,
        updatedAt,
        stageOptions,
        encrypted,
    }: {
        id?: string;
        properties?: Properties;
        teamUsersIds?: string[];
        teamContactsIds?: string[];
        teamContacts?: any[];
        clientContactsIds?: string[];
        clientContacts?: any[];
        teamClientRelations?: { [key: string]: string[] };
        contactsRoles?: { [key: string]: string };
        createdAt?: Date;
        updatedAt?: Date;
        stageOptions?: OpportunityStageOption[];
        encrypted: boolean;
    }): ForgeOpportunity {
        return new ForgeOpportunity({
            id: id ?? this.id,
            properties: properties ?? this.properties,
            teamUsersIds: teamUsersIds ?? this.teamUsersIds,
            teamContactsIds: teamContactsIds ?? this.teamContactsIds,
            teamContacts: teamContacts ?? this.teamContacts,
            clientContactsIds: clientContactsIds ?? this.clientContactsIds,
            clientContacts: clientContacts ?? this.clientContacts,
            teamClientRelations: teamClientRelations ?? this.teamClientRelations,
            contactsRoles: contactsRoles ?? this.contactsRoles,
            createdAt: createdAt ?? this.createdAt,
            updatedAt: updatedAt ?? this.updatedAt,
            stageOptions: stageOptions ?? this.stageOptions,
            encrypted: encrypted ?? this.encrypted,
        });
    }

    // eslint-disable-next-line
    toMap(toFirestore = true): { [key: string]: any } {
        const map = {
            id: this.id,
            properties: {
                name: this.properties.name,
                company: this.properties.company,
                stateDetails: {
                    state: this.properties.stateDetails?.state,
                    updatedAt: toFirestore ? this.updatedAt ?? Timestamp.now() : this.properties.stateDetails?.updatedAt.valueOf()
                },
                stageDetails: {
                    stage: this.properties.stageDetails?.stage,
                    expectedEndDate: toFirestore ? this.properties.stageDetails?.expectedEndDate ?? Timestamp.now() : this.properties.stageDetails?.expectedEndDate?.valueOf(),
                    updatedAt: toFirestore ? this.properties.stageDetails?.updatedAt ?? Timestamp.now() : this.properties.stageDetails?.updatedAt?.valueOf(),
                },
                valueAmount: this.properties.valueAmount,
                closeDate: toFirestore ? this.properties.closeDate ?? Timestamp.now() : this.properties.closeDate?.valueOf(),
                type: this.properties.type,
                leadSource: this.properties.leadSource,
                priority: this.properties.priority,
                forecastCategory: this.properties.forecastCategory,
            },
            teamUsersIds: this.teamUsersIds,
            teamContactsIds: this.teamContactsIds,
            clientContactsIds: this.clientContactsIds,
            teamClientRelations: this.teamClientRelations,
            contactsRoles: this.contactsRoles,
            createdAt: toFirestore ? this.createdAt ?? Timestamp.now() : this.createdAt?.valueOf(),
            updatedAt: toFirestore ? this.createdAt ?? Timestamp.now() : this.updatedAt?.valueOf(),
            stageOptions: this.stageOptions,
            encrypted: false,
        };

        if (!map.contactsRoles) {
            delete map.contactsRoles;
        }

        if (!map.teamClientRelations) {
            delete map.teamClientRelations;
        }
        return map;
    }

    // eslint-disable-next-line
    static async fromMap(
        map: Record<string, any>,
        encryptionService: ForgeEncryption,
    ): Promise<ForgeOpportunity> {
        const isEncrypted = map.encrypted ?? false;
        let properties = map["properties"];
        if (isEncrypted) {
            let decrypted = await encryptionService.decrypt({
                data: map.properties,
                encrypter: map.encrypter,
            }) ?? {};
            if (decrypted && typeof decrypted === "string") {
                properties = JSON.parse(decrypted);
            }
        } else {
            properties = JSON.parse(properties);
        }

        return new ForgeOpportunity({
            id: map["id"],
            properties: {
                name: properties["name"],
                company: properties["company"],
                stateDetails: properties["stateDetails"],
                stageDetails: properties["stageDetails"],
                valueAmount: properties["valueAmount"],
                closeDate:
                    typeof properties["closeDate"] === "number" ?
                        new Date(properties["closeDate"]) :
                        properties["closeDate"],
                type: properties["type"],
                leadSource: properties["leadSource"],
                priority: properties["priority"],
                forecastCategory: properties["forecastCategory"],
            },
            teamUsersIds: map["teamUsersIds"],
            teamContactsIds: map["teamContactsIds"],
            teamContacts: [],
            clientContactsIds: map["clientContactsIds"],
            clientContacts: [],
            teamClientRelations: map["teamClientRelations"],
            contactsRoles: map["contactsRoles"],
            createdAt: map.createdAt != null ? (map.createdAt instanceof Timestamp ? map.createdAt.toDate() : new Date(map.createdAt)) : undefined,
            updatedAt: map.updatedAt != null ? (map.updatedAt instanceof Timestamp ? map.updatedAt.toDate() : new Date(map.updatedAt)) : undefined,
            stageOptions: map["stageOptions"],
            encrypted: map["encrypted"],
        });
    }

    orderedClientContacts(
        clientContacts: any[],
        warmthAlgorithm: any,
        userId: string,
    ): any[] {
        const orderedContacts: any[] = [];
        const orderedContactsIds: string[] = Object.keys(this.teamClientRelations);

        orderedContactsIds.sort((a, b) =>
            this.teamClientRelations[b].length - this.teamClientRelations[a].length
        );

        for (const contactId of orderedContactsIds) {
            let contacts = clientContacts.filter(
                e => this.teamClientRelations[contactId]?.includes(e.ref?.id || '')
            );

            contacts.sort((a, b) => {
                // let aScore = getScore(a);
                // let bScore = getScore(b);
                // let result = bScore - aScore;

                // console.log(`(${b.id} - ${bScore}) - (${a.id} - ${aScore}) = ${result}`);
                return getWarmthScore(b, warmthAlgorithm, userId).get("finalScore")
                    - getWarmthScore(a, warmthAlgorithm, userId).get("finalScore");
            });
            orderedContacts.push(...contacts);
        }

        orderedContacts.push(
            ...clientContacts.filter(
                e => !orderedContacts.includes(e)
            )
        );

        return [...new Set(orderedContacts)];
    }

    orderedTeamContacts(teamContacts: any[]): any[] {
        const orderedContacts: any[] = [];
        const orderedContactsIds: string[] = Object.keys(this.teamClientRelations);

        orderedContactsIds.sort((a, b) =>
            this.teamClientRelations[b].length - this.teamClientRelations[a].length
        );

        for (const contactId of orderedContactsIds) {
            const contact = teamContacts.find(e => e.ref?.id === contactId);
            if (contact) {
                orderedContacts.push(contact);
            }
        }

        orderedContacts.push(
            ...teamContacts.filter(
                e => !orderedContacts.includes(e)
            )
        );

        return [...new Set(orderedContacts)];
    }

    getAmountOfRelations(contact: any, clientContacts: any[]): number {
        if (contact.ref && this.teamClientRelations.hasOwnProperty(contact.ref?.id)) {
            return [...new Set(this.teamClientRelations[contact.ref?.id])]
                .filter(contactId =>
                    clientContacts.some(e => e.ref?.id === contactId)
                )
                .length;
        }
        return 0;
    }

    get stageOptionsWithClosedStatesMerged(): OpportunityStageOption[] {
        const options = this.stageOptions.filter(e => !e.metadata["isClosed"]);

        options.push({
            label: 'Closed',
            displayOrder: 99,
            metadata: {
                'isClosed': true,
                'probability': 100,
            },
            id: '',
            archived: false,
        });

        return options;
    }

    get closedStagesOptions(): OpportunityStageOption[] {
        return this.stageOptions.filter((e) => e.metadata["isClosed"]);
    }

    getStageOpportunityFromPercentage(value: number): OpportunityStageOption | null {
        if (value < 0 || value > 100) {
            // Ensure percentage is within valid range
            console.log(`Percentage must be between 0 and 100. ${value}`);
            return null;
        }

        const stageOptions = this.stageOptionsWithClosedStatesMerged;

        const index = Math.floor((value / 100) * stageOptions.length);

        // Ensure the calculated index is within the bounds of the list
        if (index >= 0 && index <= stageOptions.length) {
            if (index === stageOptions.length) {
                return stageOptions[stageOptions.length - 1];
            }
            return stageOptions[index];
        } else {
            console.log(`Invalid index calculated. ${index}`);
            return null;
        }
    }

    getPercentageFromStageOpportunity(startAtZero: boolean = true): number {
        if (!this.properties.stageDetails) {
            return 0;
        }

        if (this.properties.stageDetails?.stage?.metadata?.isClosed ?? false) {
            return 100;
        }

        const stageOptions = this.stageOptionsWithClosedStatesMerged;

        const index = stageOptions.findIndex(e => e.id === this.properties.stageDetails.stage.id);

        if (index === -1) {
            return 0;
        }

        if (startAtZero) {
            return ((index + 1) / stageOptions.length) * 100;
        }
        return (index / (stageOptions.length - 1)) * 100;
    }

    getWarmestContact(
        possibleUserContacts: any[],
        warmthAlgorithm: any,
        userId: string,
    ): any {
        let opportunityOwner = this.opportunityOwner;

        if (opportunityOwner) {
            const contactIds = possibleUserContacts.map(e => e.id);

            if (contactIds.includes(opportunityOwner.id)) {
                let possibleContactsIds = this.teamClientRelations[opportunityOwner.id];
                let possibleContacts = this.clientContacts.filter((contact: any) => possibleContactsIds.includes(contact.id));

                possibleContacts.sort((a, b) =>
                    getWarmthScore(b, warmthAlgorithm, userId).get("finalScore")
                    - getWarmthScore(a, warmthAlgorithm, userId).get("finalScore")
                );
                return possibleContacts.length > 0
                    ? possibleContacts[0]
                    : undefined;
            }
        }

        return undefined;
    }

    get decisionMaker(): any {
        let decisionMakerIds = Object.entries(this.contactsRoles)?.filter(
            ([key, value]) => value === "Decision Maker"
        ).map(([key, _]) => key);

        return decisionMakerIds && decisionMakerIds.length > 0
            ? this.clientContacts.find((contact: any) => decisionMakerIds.includes(contact.id))
            : undefined;
    }

    get opportunityOwner(): any {
        let opportunityOwnerIds = Object.entries(this.contactsRoles)?.filter(
            ([key, value]) => value === "Opportunity Owner"
        ).map(([key, _]) => key);

        return opportunityOwnerIds && opportunityOwnerIds.length > 0
            ? this.teamContacts.find((contact: any) => opportunityOwnerIds.includes(contact.id))
            : undefined;
    }

    get allRelatedContactsIds(): string[] {
        return [
            ...this.teamContactsIds,
            ...this.clientContactsIds,
        ];
    }

    get allRelatedContacts(): any[] {
        return [
            ...this.teamContacts,
            ...this.clientContacts,
        ];
    }
}