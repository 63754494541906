// import firebase from "firebase/compat/app";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getRemoteConfig } from "firebase/remote-config";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { auth } from "firebaseui";

const firebaseConfig = {
  apiKey: "AIzaSyAO8Hl-I85RYytU-X2F6rrQj0zocRAqdWk",
  authDomain: "forge-development.firebaseapp.com",
  databaseURL: "https://forge-development-default-rtdb.firebaseio.com",
  projectId: "forge-development",
  storageBucket: "forge-development.appspot.com",
  messagingSenderId: "514739754269",
  appId: "1:514739754269:web:71d6303a562fadb2d93df4",
  measurementId: "G-NMES2KM87G",
};

// Firebase App
const app = initializeApp(firebaseConfig);

// Firebase App Check
if (process.env.REACT_APP_DEV === "true") {
  // eslint-disable-next-line
  (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider("6Lc0esQoAAAAAGPUXJRIWS5f4WrHogPaZ2Do_opI"),
  isTokenAutoRefreshEnabled: true,
});

export const firebaseAuthUi = new auth.AuthUI(getAuth());
export const firestoreDb = getFirestore(app);
export const remoteConfig = getRemoteConfig(app);
export const firebaseAppCheck = appCheck;