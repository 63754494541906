import { Card, Divider, IconButton, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Add, ArrowForwardIos, EmojiObjectsOutlined } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { MilestonesContext } from "../../services/MilestonesContext";
import MilestoneCard from "forge/milestones/components/MilestoneCard";

const options = { year: 'numeric', month: 'short', day: '2-digit' };

function ContactMilestonesCard({ contact, handleOpenDrawer }: { contact: any, handleOpenDrawer: () => void }) {
    const { milestones } = useContext(MilestonesContext);
    const [finalMilestones, setFinalMilestones] = useState<{ [key: string]: { [key: string]: any[] } }>({});

    useEffect(() => {
        setFinalMilestones(groupMilestonesByYearAndDay(milestones));
    }, [milestones]);

    function groupMilestonesByYearAndDay(milestones: any[]) {
        const groupedMilestones: { [key: string]: { [key: string]: any[] } } = {}; // Nested object for year and day grouping
        milestones.forEach((milestone) => {
            const startDate = milestone.startDate?.toDate();
            const year = startDate.getFullYear().toString(); // Extract year from start date
            const dateKey = startDate.toISOString().split("T")[0]; // Using date as key
            if (!groupedMilestones[year]) {
                groupedMilestones[year] = {}; // Initialize year grouping if not already present
            }
            if (!groupedMilestones[year][dateKey]) {
                groupedMilestones[year][dateKey] = []; // Initialize day grouping if not already present
            }
            groupedMilestones[year][dateKey].push(milestone);
        });
        return groupedMilestones;
    }


    return (
        <Card sx={{ height: "100%" }}>
            <MDBox m={3}>
                <MDBox
                    display="flex"
                    alignItems="center"
                    sx={{ justifyContent: "space-between" }}
                >
                    <MDTypography variant="h5">Milestones</MDTypography>
                    {Object.entries(finalMilestones).length > 0 && <MDBox
                        width="3rem"
                        height="3rem"
                        borderRadius="md"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        shadow="md"
                        style={{ cursor: "pointer", background: "cornflowerblue" }}
                        onClick={handleOpenDrawer}
                    >
                        <IconButton
                            aria-label="add-knowledge"
                            style={{ padding: 0 }}
                            onClick={handleOpenDrawer}
                        >
                            <EmojiObjectsOutlined style={{ color: "white" }} />
                            <Add style={{ color: "white", width: "0.5em", position: "absolute", top: "-10px", right: "-4px" }} />
                        </IconButton>
                    </MDBox>}
                </MDBox>
                <Divider style={{ opacity: 1 }} />
                {Object.entries(finalMilestones).length > 0 ?
                    Object.entries(finalMilestones).map(([year, milestonesByDay]) => (
                        <MDBox px={1}>
                            <MDBox
                                mb={1}
                                mx="auto"
                                width="fit-content"
                                borderRadius="24px"
                                shadow="md"
                                style={{ background: "black" }}
                            >
                                <MDTypography
                                    variant="caption"
                                    fontWeight="bold"
                                    fontSize="small"
                                    textTransform="capitalize"
                                    display="block"
                                    width="fit-content"
                                    py={1}
                                    px={2}
                                    textAlign="center"
                                    style={{ color: "white" }}
                                >
                                    {new Date(year).toLocaleDateString("en-US", {
                                        year: 'numeric',
                                        timeZone: 'UTC',
                                    })}
                                </MDTypography>
                            </MDBox>
                            {Object.entries(milestonesByDay).map(([day, milestones]) => (
                                <MDBox>
                                    <MDBox mb={1}>
                                        <MDTypography
                                            variant="caption"
                                            fontWeight="bold"
                                            fontSize="medium"
                                            textTransform="capitalize"
                                            style={{ color: "#0000008f" }}
                                        >
                                            {new Date(day).toLocaleDateString("en-US", {
                                                weekday: 'long',
                                                month: "short",
                                                day: "2-digit",
                                                timeZone: 'UTC',
                                            })}
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox
                                        component="ul"
                                        display="flex"
                                        flexDirection="column"
                                        p={0}
                                        m={0}
                                        sx={{ listStyle: "none" }}
                                    >
                                        {milestones.map((milestone: any) => (
                                            <MilestoneCard milestone={milestone} />
                                        ))}
                                    </MDBox>
                                </MDBox>
                            ))}
                        </MDBox>
                    ))
                    : (
                        <Stack mt={5} mb={3} direction="row" alignSelf={"center"} justifyContent={"center"} alignItems="center" spacing={0.5}>
                            <MDTypography variant="body2" align="center" style={{ color: "darkgray" }}>{`Add a birthday or other event`}</MDTypography>
                            <ArrowForwardIos style={{ color: "darkgray", height: "0.75em" }} />
                            <MDBox
                                width="3rem"
                                height="3rem"
                                borderRadius="md"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                shadow="md"
                                style={{ cursor: "pointer", background: "cornflowerblue" }}
                                onClick={handleOpenDrawer}
                            >
                                <IconButton
                                    aria-label="add-knowledge"
                                    style={{ padding: 0 }}
                                    onClick={handleOpenDrawer}
                                >
                                    <EmojiObjectsOutlined style={{ color: "white" }} />
                                    <Add style={{ color: "white", width: "0.5em", position: "absolute", top: "-10px", right: "-4px" }} />
                                </IconButton>
                            </MDBox>
                        </Stack>
                    )
                }
            </MDBox>
        </Card>
    );
};

export default ContactMilestonesCard;