import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormField from "layouts/pages/account/components/FormField";
import { useContext, useState } from 'react';
import { Backdrop, Stack } from '@mui/material';
import MDButton from 'components/MDButton';
import { OrganizationContext } from 'forge/organization/services/OrganizationContext';
import { ForgeOrganization } from 'forge/organization/types/organization';

// Images
import spinningSphere from "assets/forge/gifs/spinning-sphere.gif";
import { AuthContext } from 'context';

export default function CreateOrganizationDivisionDialog({
    open,
    handleClose
}: {
    open: boolean,
    handleClose: () => void
}) {
    // Context
    const { getCurrentUser } = useContext(AuthContext);
    const { organization, parentOrganization, createOrganization } = useContext(OrganizationContext);

    // State
    const [name, setName] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit = async (event: any) => {
        event.preventDefault();
        setLoading(true);

        const organizationDivision = new ForgeOrganization({
            domain: organization.domain,
            parentOrganizationRef: parentOrganization?.ref ?? organization.ref,
            name,
            legalName: organization.legalName,
            address: organization.address,
            linkedInUrl: organization.linkedInUrl
        });
        await createOrganization(organizationDivision);

        setLoading(false);
        internalHandleClose();
    }

    const internalHandleClose = () => {
        setName(undefined);
        setLoading(false);
        handleClose();
    }

    return (
        <Dialog
            open={open}
            onClose={internalHandleClose}
            fullWidth
        >
            <Backdrop
                sx={{ background: 'rgba(255, 255, 255, 0.5)', zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute' }}
                open={loading}
            >
                <img src={spinningSphere} style={{ width: "200px" }} alt="Loading..." />
            </Backdrop>
            <DialogTitle>Add Organization Division</DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={2}>
                    <FormField
                        label="Name"
                        placeholder="Division Name"
                        value={name}
                        onChange={(e: any) => {
                            setName(e.target.value);
                        }}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <MDButton variant="outlined" autoFocus onClick={internalHandleClose} color={"dark"}>
                    Cancel
                </MDButton>
                <MDButton onClick={onSubmit} color={"dark"}>
                    Add
                </MDButton>
            </DialogActions>
        </Dialog>
    );
}