
// @material-ui core components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import { useContext, useState } from "react";
import { Stack } from "@mui/material";
import ConfirmationDialog from "forge/core/components/ConfirmationDialog";
import MDButton from "components/MDButton";
import { OrganizationContext } from "forge/organization/services/OrganizationContext";
import { Can } from "forge/organization/rbac/CanContext";
import { DisabledTooltip } from "forge/organization/rbac/DisabledTooltip";

function OrganizationActions(): JSX.Element {
    // Context
    const { deleteOrganization } = useContext(OrganizationContext);

    // State
    const [openLeaveDialog, setOpenLeaveDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const handleCloseDialogLeave = async (result: boolean) => {
        setOpenLeaveDialog(false);
        if (result) {
            // await deleteOrganization();
        }
    };

    const handleCloseDialogDelete = async (result: boolean) => {
        setOpenDeleteDialog(false);
        if (result) {
            await deleteOrganization();
        }
    };

    const customErrorFeature = "Only the account administrator may use this feature.";

    return (
        <Stack direction="column" spacing={2} style={{ marginBottom: "24px" }}>
            <Can I="leave" an="organization" passThrough>
                {(allowed, because) => {
                    console.log(because);
                    return <Card id="leave-organization">
                        <ConfirmationDialog
                            openDialog={openLeaveDialog}
                            handleCloseDialog={handleCloseDialogLeave}
                            title="Are you sure you want to leave your organization?"
                            description="You will only be able to rejoin the organization with an invite."
                            confirmText="Leave"
                            confirmColor="dark" />
                        <MDBox
                            pr={3}
                            display="flex"
                            justifyContent="space-between"
                            alignItems={{ xs: "flex-start", sm: "center" }}
                            flexDirection={{ xs: "column", sm: "row" }}
                        >
                            <MDBox p={3} lineHeight={1}>
                                <MDBox mb={1}>
                                    <MDTypography variant="h5">Leave Organization</MDTypography>
                                </MDBox>
                                <MDTypography variant="button" color="text">
                                    You may only be a member of one organization at a time. Once you leave your organization, you will only be able to rejoin the organization with an invite. Please be certain.
                                </MDTypography>
                            </MDBox>

                            <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                                <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                    <DisabledTooltip allowed={allowed}>
                                        <MDButton
                                            variant="gradient"
                                            color="dark"
                                            sx={{ height: "100%" }}
                                            disabled={!allowed}
                                            onClick={() => setOpenLeaveDialog(true)}
                                        >
                                            Leave Organization
                                        </MDButton>
                                    </DisabledTooltip>
                                </MDBox>
                            </MDBox>
                        </MDBox>
                    </Card>;
                }}
            </Can>
            <Can I="delete" an="organization" passThrough>
                {allowed => <Card id="delete-organization">
                    <ConfirmationDialog
                        openDialog={openDeleteDialog}
                        handleCloseDialog={handleCloseDialogDelete}
                        title="Are you sure you want to delete your organization?"
                        description="Please note that this action is irreversible."
                        confirmText="Delete"
                        confirmColor="error"
                    />
                    <MDBox
                        pr={3}
                        display="flex"
                        justifyContent="space-between"
                        alignItems={{ xs: "flex-start", sm: "center" }}
                        flexDirection={{ xs: "column", sm: "row" }}
                    >
                        <MDBox p={3} lineHeight={1}>
                            <MDBox mb={1}>
                                <MDTypography variant="h5">Delete Organization</MDTypography>
                            </MDBox>
                            <MDTypography variant="button" color="text">
                                Once you delete your organization, there is no going back. Please be certain.
                            </MDTypography>
                        </MDBox>

                        <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                <DisabledTooltip allowed={allowed}  message={customErrorFeature}>
                                    <MDButton
                                        variant="gradient"
                                        color="error"
                                        sx={{ height: "100%" }}
                                        disabled={!allowed}
                                        onClick={() => setOpenDeleteDialog(true)}
                                    >
                                        delete Organization
                                    </MDButton>
                                </DisabledTooltip>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </Card>}
            </Can>
        </Stack >
    );
}

export default OrganizationActions;
