import PropTypes from "prop-types";
import { useState, useEffect, useContext } from "react";
import theme from "assets/theme";

// MUI
import {
    Card,
    Grid,
    useMediaQuery,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";

// Material Dashboard
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Components
import CommitmentCard from "../../../commitments/components/CommitmentCard";
import Calendar from "examples/Calendar";
import { CommitmentsContext } from "forge/commitments/services/CommitmentsContext";
import { createUseStyles } from "react-jss";
import { ExpandMore } from "@mui/icons-material";
import { startOfDay } from "forge/core/utilities";
import { EventClickArg } from "@fullcalendar/core";
import CreateCommitmentDrawer from "forge/commitments/components/CreateCommitmentDrawer";
import { Commitment } from "types/commitment";

const styles = createUseStyles({
    root: {
        '&:before': {
            display: 'none',
        },
    }
});

function CommitmentsView({ }: {}) {
    const classes = styles();

    // Context
    const { commitments, pastDueCommitments } = useContext(CommitmentsContext);

    // State
    const [selectedCommitment, setSelectedCommitment] = useState<Commitment>();
    const [selectedDay, setSelectedDay] = useState(startOfDay(new Date()));
    const [schedulerData, setSchedulerData] = useState([]);
    const [agendaData, setAgendaData] = useState({});
    const [openCreateCommitmentDrawer, setOpenCreateCommitmentDrawer] = useState(false);

    const handleOpenDrawer = () => setOpenCreateCommitmentDrawer(true);
    const handleCloseDrawer = () => setOpenCreateCommitmentDrawer(false);

    useEffect(() => {
        let parsedCommitments = parseCommitments(commitments);
        setSchedulerData(parsedCommitments);
        setAgendaData(getAgendaCommitments(parsedCommitments));
    }, [commitments]);

    const parseCommitments = (info: any[]) => {
        let updatedInfo = info.map((commitment) => {
            return {
                id: `${commitment.ref?.id}-${commitment.childIndex}`,
                title: commitment.name,
                start: commitment.startDate?.toDate(),
                end: commitment.endDate?.toDate() ?? commitment.startDate?.toDate(),
                className: "info",
                commitment: commitment,
                ...commitment,
            };
        });

        return updatedInfo;
    };

    const getAgendaCommitments = (info: any[], day?: Date) => {
        const endDate = new Date(day ?? selectedDay);
        endDate.setDate(endDate.getDate() + 7);

        const filteredCommitments = info.filter((commitment) => {
            const eventDate = new Date(commitment.start);
            return eventDate >= (day ?? selectedDay) && eventDate <= endDate;
        });

        return groupCommitmentsByDay(filteredCommitments);
    };

    // Function to group events by day
    function groupCommitmentsByDay(commitments: any[]) {
        const groupedCommitments: { [key: string]: any } = {};
        commitments.forEach((commitment) => {
            const startDate = new Date(commitment.start);
            const dateKey = startDate.toISOString().split("T")[0]; // Using date as key
            if (!groupedCommitments[dateKey]) {
                groupedCommitments[dateKey] = [];
            }
            groupedCommitments[dateKey].push(commitment);
        });
        return groupedCommitments;
    }

    const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const largeScreen = useMediaQuery(theme.breakpoints.up("lg"));

    const handleDateChange = (date: any) => {
        setSelectedDay(date.start);
        setAgendaData(getAgendaCommitments(schedulerData, date.start));
    };

    return (
        <Card>
            <CreateCommitmentDrawer
                openDrawer={openCreateCommitmentDrawer}
                handleCloseDrawer={handleCloseDrawer}
                commitment={selectedCommitment}
            />
            <MDBox p={3} lineHeight={1} display="flex" justifyContent="space-between">
                <MDTypography variant="h5" fontWeight="medium">
                    Commitments
                </MDTypography>
                {/* {ability.can("create", "categories") && ( */}
                <MDButton
                    variant="gradient"
                    color="dark"
                    size="small"
                    type="submit"
                    style={{ textTransform: "none" }}
                    onClick={handleOpenDrawer}
                >
                    + Add Commitment
                </MDButton>
                {/* )} */}
            </MDBox>
            <MDBox py={1}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                        <MDBox ml={3}>
                            <MDBox
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                pt={1}
                                px={2}
                            >
                                <MDTypography
                                    variant="h6"
                                    fontWeight="medium"
                                    textTransform="capitalize"
                                >
                                    Next 7 days
                                </MDTypography>
                            </MDBox>
                            {pastDueCommitments.length > 0 && <Accordion style={{ boxShadow: "none" }} className={classes.root}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <MDTypography
                                        variant="caption"
                                        fontWeight="bold"
                                        fontSize="medium"
                                        textTransform="capitalize"
                                        style={{ color: "#0000008f" }}
                                    >
                                        Past due commitments
                                    </MDTypography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {
                                        pastDueCommitments.map((commitment) => <CommitmentCard commitment={commitment} />)
                                    }
                                </AccordionDetails>
                            </Accordion>}

                            {Object.entries(agendaData).map((day: any) => (
                                <MDBox px={2}>
                                    <MDBox mb={2}>
                                        <MDTypography
                                            variant="caption"
                                            fontWeight="bold"
                                            fontSize="medium"
                                            textTransform="capitalize"
                                            style={{ color: "#0000008f" }}
                                        >
                                            {new Date(day[0]).toLocaleDateString("en-US", {
                                                weekday: 'long',
                                                month: "short",
                                                day: "2-digit",
                                                timeZone: 'UTC',
                                            })}
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox
                                        component="ul"
                                        display="flex"
                                        flexDirection="column"
                                        p={0}
                                        m={0}
                                        sx={{ listStyle: "none" }}
                                    >
                                        {day[1].map((commitment: any) => (
                                            <CommitmentCard commitment={commitment.commitment} />
                                        ))}
                                    </MDBox>
                                </MDBox>
                            ))}
                        </MDBox>
                    </Grid>
                    <Divider orientation="vertical" flexItem style={{ height: "auto" }} />
                    <Grid item xs={11} md={7} lg={7}>
                        <MDBox mr={3}>
                            <Calendar
                                header={{
                                    date: selectedDay.toLocaleDateString("en-US", {
                                        month: "long",
                                        year: "numeric",
                                    }),
                                }}
                                headerToolbar={false}
                                initialView="dayGridMonth"
                                initialDate={selectedDay}
                                events={schedulerData.map((event) => {
                                    return {
                                        id: event.id,
                                        title: event.title,
                                        start: event.start.toISOString().split("T")[0],
                                        end: event.end.toISOString().split("T")[0],
                                        className: "info",
                                    };
                                })}
                                selectable
                                editable
                                select={handleDateChange}
                                eventClick={(event: EventClickArg) => {
                                    console.log(event.event.id);
                                    let commitment = schedulerData.find((commitment) => commitment.id === event.event.id);

                                    if (commitment) {
                                        setSelectedCommitment(commitment.commitment);
                                        handleOpenDrawer();
                                    }
                                }}
                            />
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
        </Card>
    );
}

// Setting default values for the props of CommitmentCard
CommitmentsView.defaultProps = {
    commitments: [],
};

// Typechecking props for the CommitmentCard
CommitmentsView.propTypes = {
    commitments: PropTypes.array.isRequired,
};

export default CommitmentsView;