import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import forgeLogo from "assets/forge/images/forge.png";

function CrossDeviceAccessKeyDisplayDialog({
    open,
    accessKey,
    confirmationText = "Continue",
    onContinue,
    onClose,
}: {
    open: boolean,
    accessKey: string,
    confirmationText?: string,
    onContinue: () => void
    onClose?: () => void
}) {

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={onClose}
                PaperProps={{
                    style: {
                        color: 'white',
                        backgroundColor: 'black',
                        boxShadow: 'none',
                    },
                    sx: { borderRadius: "16px" },
                }}
            >
                <img
                    src={forgeLogo}
                    alt="Forge"
                    style={{ margin: "auto", marginTop: "32px", width: "25%" }}
                />
                <DialogContent>
                    <DialogContentText color={'white !important'} align='center'>
                        {"We’re serious about security."}
                    </DialogContentText>
                    <DialogContentText color={'white !important'} align='center'>
                        {"This is your Cross-Device Access Key, store it somewhere safe. You’ll need to enter this key to access your Forge data on any other device."}
                    </DialogContentText>
                    <DialogContentText color={'white !important'} align='center' fontWeight="bold  !important" fontSize="xx-large  !important">
                        {accessKey}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        style={{ color: 'white' }}
                        onClick={() => onContinue()}
                    >
                        {confirmationText}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default CrossDeviceAccessKeyDisplayDialog;