import { useContext, useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Billing page components
import { Accordion, AccordionDetails, AccordionSummary, Divider, Stack, Tab, Tabs } from "@mui/material";

import { ForgeOpportunity } from "types/pipeline/opportunity";
import { EventsContext } from "context";
import { CalendarEvent } from "types/calendar/calendar-event";
import EventCard from "forge/core/components/EventCard";
import { CustomTabPanel, a11yProps } from "forge/core/components/TabPanel";
import { Call } from "types/pipeline/call";
import { ForgeEvent } from "types/forge-event";
import CallCard from "../CallCard";
import { InfoOutlined, KeyboardArrowDownRounded } from "@mui/icons-material";
import { CallsContext } from "../../../calls/services/CallsContext";
import { CommitmentsContext } from "forge/commitments/services/CommitmentsContext";
import { Commitment } from "types/commitment";
import CommitmentCard from "forge/commitments/components/CommitmentCard";
import { createUseStyles } from "react-jss";

const styles = createUseStyles({
    root: {
        '& .MuiAccordionSummary-root': {
            padding: 0,
        },
        '&:before': {
            display: 'none',
        },
    },
    accordionDetailsRoot: {
        '& .MuiAccordionDetails-root': {
            padding: 0,
        },
    }
});

function DealActivities({ opportunity }: { opportunity: ForgeOpportunity }) {
    const classes = styles();

    // Context
    const { events } = useContext(EventsContext);
    const { calls } = useContext(CallsContext);
    const { commitments } = useContext(CommitmentsContext);

    // State
    const [expanded, setExpanded] = useState<boolean>(false);
    const [opportunityEvents, setOpportunityEvents] = useState<CalendarEvent[]>([]);

    const [groupedPastEvents, setGroupedPastEvents] = useState<{ [key: string]: ForgeEvent[] }>({});
    const [pastEventsCounter, setPastEventsCounter] = useState<number>(undefined);
    const [groupedCurrentEvents, setGroupedCurrentEvents] = useState<{ [key: string]: ForgeEvent[] }>({});

    useEffect(() => {
        if (opportunity) {
            const filteredEvents = events.filter((event) => event.opportunityId === opportunity.id);
            setOpportunityEvents(filteredEvents);
        }
    }, [events, opportunity])

    useEffect(() => {
        const { pastEvents, futureEvents } = groupEventsByDay([...opportunityEvents, ...calls, ...commitments]);
        setGroupedPastEvents(pastEvents);
        setGroupedCurrentEvents(futureEvents);

        if (Object.entries(futureEvents).length == 0) {
            setExpanded(true);
        }
    }, [opportunityEvents, calls, commitments])

    // Function to group events by day
    function groupEventsByDay(events: ForgeEvent[]) {
        const groupedEvents: { [key: string]: any } = {};
        events.forEach((event) => {
            const startDate = event.startDate.toDate();
            const dateKey = startDate.toISOString().split("T")[0]; // Using date as key

            if (!groupedEvents[dateKey]) {
                groupedEvents[dateKey] = [];
            }
            groupedEvents[dateKey].push(event);
        });

        const pastEvents: { [key: string]: ForgeEvent[] } = {};
        let pastEventsCounter: number = 0;
        const futureEvents: { [key: string]: ForgeEvent[] } = {};
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        Object.keys(groupedEvents).forEach((key) => {
            const dateString = key;
            const [year, month, day] = dateString.split("-").map(Number);
            const eventDate = new Date(year, month - 1, day);

            if (eventDate < today) {
                // Add to pastEvents object
                if (!pastEvents[key]) {
                    pastEvents[key] = [];
                }
                pastEvents[key].push(...groupedEvents[key]);
                pastEventsCounter += groupedEvents[key].length;
            } else {
                // Add to futureEvents object
                if (!futureEvents[key]) {
                    futureEvents[key] = [];
                }
                futureEvents[key].push(...groupedEvents[key]);
            }
        });

        setPastEventsCounter(pastEventsCounter);
        return { pastEvents, futureEvents };
    }

    const buildCards = (day: string, events: ForgeEvent[]) => {
        let date = new Date(day);

        return (
            <MDBox>
                <MDBox mb={2}>
                    <MDTypography
                        variant="caption"
                        color="text"
                        fontWeight="bold"
                        fontSize="medium"
                        textTransform="capitalize"
                        style={{ color: "#0000008f" }}
                    >
                        {date.toLocaleDateString("en-US", {
                            weekday: "long",
                            month: "short",
                            day: "2-digit",
                            timeZone: 'UTC',
                        })}
                    </MDTypography>
                </MDBox>
                <MDBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                    sx={{ listStyle: "none" }}
                >
                    {events.map((event: any) => {
                        if (event instanceof CalendarEvent) {
                            return (
                                <EventCard event={event} />
                            );
                        } else if (event instanceof Call) {
                            return (
                                <CallCard call={event} opportunity={opportunity} />
                            );
                        } else if (event instanceof Commitment) {
                            return (
                                <CommitmentCard commitment={event} />
                            );
                        }

                        return (<div></div>);
                    })}
                </MDBox>
            </MDBox>
        );
    }

    return (
        <Card id="deal-activities" sx={{ height: "100%" }}>
            <MDBox m={3}>
                <MDBox>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item>
                            <MDTypography variant="h6" fontWeight="medium">
                                Activities
                            </MDTypography>
                        </Grid>
                    </Grid>
                </MDBox>
                <MDBox pt={1} pb={2}>
                    <MDBox
                        component="ul"
                        display="flex"
                        flexDirection="column"
                        p={0}
                        m={0}
                    >
                        <Accordion
                            expanded={expanded}
                            style={{ boxShadow: "none" }}
                            className={classes.root}
                            onChange={() => setExpanded(!expanded)}
                        >
                            <AccordionSummary
                                expandIcon={<KeyboardArrowDownRounded />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <MDTypography
                                    variant="caption"
                                    fontWeight="bold"
                                    fontSize="medium"
                                    textTransform="capitalize"
                                    style={{ color: "#0000008f" }}
                                >
                                    Past events {pastEventsCounter && pastEventsCounter != 0 ? `(${pastEventsCounter})` : ""}
                                </MDTypography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.accordionDetailsRoot}>
                                {
                                    Object.entries(groupedPastEvents).length > 0 ?
                                        Object.entries(groupedPastEvents).map(([day, events], i) => buildCards(day, events))
                                        : <MDBox></MDBox>
                                }
                            </AccordionDetails>
                        </Accordion>
                        {Object.entries(groupedPastEvents).length > 0 && <Divider style={{ opacity: 1, margin: "8px 0" }} />}
                        {
                            Object.entries(groupedCurrentEvents).length > 0 ?
                                Object.entries(groupedCurrentEvents).map(([day, events], i) => buildCards(day, events))
                                : (<Stack m={"auto"} mt={"30vh"} mb={"30vh"} direction="column" alignItems="center" spacing={0.5}>
                                    <InfoOutlined style={{ color: "darkgray" }} />
                                    <MDTypography variant="h5" style={{ color: "darkgray" }}>No current or future events</MDTypography>
                                </Stack>)
                        }
                    </MDBox>
                </MDBox>
            </MDBox>
        </Card>
    );
}

export default DealActivities;