
// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { AuthContext } from "context";
import { SettingsContext } from "forge/settings/services/SettingsContext";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import { useContext, useEffect, useState } from "react";
import { debounce } from 'lodash';
import { Avatar, Stack } from "@mui/material";
import { Add, Info, Remove } from "@mui/icons-material";

function OrganizationBilling(): JSX.Element {
    return (
        <Card id="billing" sx={{ overflow: "visible", marginBottom: 3 }}>
            <Stack mt="12vh" mb="12vh" direction="column" alignItems="center" spacing={0.5}>
                <Info style={{ color: "darkgray" }} />
                <MDTypography variant="body1" style={{ color: "darkgray" }}>Coming Soon</MDTypography>
            </Stack>
        </Card>
    );
}

export default OrganizationBilling;
