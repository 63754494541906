import { Box, DialogContent, DialogContentText } from "@mui/material";
import questionBro from "assets/forge/images/question_bro.png";

function Relationship1Slide({ }: {}) {

    return (
        <DialogContent>
            <DialogContentText color="white" fontWeight="bold" align='center'>
                {"OK! To start with, strong relationships are based\non genuine trust."}
            </DialogContentText>
            <DialogContentText color="white" align='center'>
                {"But how do we build trust?"}
            </DialogContentText>
            <Box height={16}/>
            <img
                src={questionBro}
                style={{
                    width: "50%",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
            />
        </DialogContent>
    );
};

export default Relationship1Slide;