// @mui material components

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { BusinessCenterOutlined, LinkedIn } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { getKnowledgeTypeIcon } from "forge/knowledge/schemas/knowledge-type";
import { parseKnowledge } from "forge/core/knowledgeParsing";
import { timeFrame } from "types/contact/linkedin-data";

function ExperienceTile({ experience, knowledge, onClick }: { experience: any, knowledge: any, onClick?: () => void }): JSX.Element {
    return (
        <MDBox
            display="flex"
            alignItems="start"
            lineHeight={1}
            mb={1.5}
            style={{ cursor: "pointer" }}
            onClick={onClick}
        >
            <Avatar sx={{ bgcolor: "lightgray", width: 44, height: 44, marginTop: "4px" }} variant="rounded" src={experience?.logo_url}>
                <BusinessCenterOutlined style={{ color: "white" }} />
            </Avatar>
            {experience && <MDBox ml={1}>
                {parseKnowledge(knowledge, experience.company, "bold")}
                <MDTypography
                    variant="body2"
                    color="text"
                    sx={{ fontSize: "0.9rem", lineHeight: 1.4 }}
                >
                    {experience.title}
                </MDTypography>
                <MDTypography
                    variant="body2"
                    sx={{ fontSize: "0.8rem", lineHeight: 1.4, color: "grey" }}
                >
                    {timeFrame(experience)}
                </MDTypography>
                <MDTypography
                    variant="body2"
                    color="text"
                    sx={{ fontSize: "0.8rem", lineHeight: 1.4, color: "grey" }}
                >
                    {experience.location}
                </MDTypography>
            </MDBox>}
            {!experience && knowledge && <MDBox ml={1}>
                {parseKnowledge(knowledge)}
            </MDBox>}
            <MDBox flex={1} />
            {getKnowledgeTypeIcon(knowledge?.type)}
        </MDBox>
    );
}

// Setting default values for the props of NewsCard
ExperienceTile.defaultProps = {
    experience: null,
};

export default ExperienceTile;