import { ForgeEvent } from 'types/forge-event';
import { CalendarEventProperties } from './calendar-event-properties';
import { CalendarType } from './calendar';
import { DocumentReference, Timestamp, doc } from 'firebase/firestore';
import { ForgeRecurrence } from './forge-recurrence';
import { ForgeAttendee, InvitationStatus } from './forge-attendee';
import { User } from 'firebase/auth';
import { firestoreDb } from 'firebase.init';

export class CalendarEvent extends ForgeEvent {
    properties: CalendarEventProperties;
    eventId: string;
    iCalUID?: string;
    deleted?: boolean;
    recurrence: ForgeRecurrence;
    reminder?: number;
    calendarType: CalendarType;
    calendarId: string;
    colorValue?: number;
    ignoreDelete: boolean;
    nonEditable: boolean;

    /// If the event is related to an opportunity, a call can be created from
    /// the event card when this one has passed. The reference is used to open
    /// the call from the event card.
    callRef?: DocumentReference;

    constructor({
        name,
        startDate,
        isProcessing = false,
        ref,
        endDate,
        contacts,
        opportunityId,
        properties,
        eventId,
        iCalUID,
        deleted,
        recurrence,
        reminder,
        calendarType,
        calendarId,
        colorValue,
        ignoreDelete = false,
        nonEditable = false,
        callRef,
    }: {
        name: string;
        startDate: Date;
        isProcessing?: boolean;
        ref?: DocumentReference;
        endDate?: Date;
        contacts?: any[];
        opportunityId?: string;
        properties: CalendarEventProperties;
        eventId: string;
        iCalUID?: string;
        deleted?: boolean;
        recurrence: ForgeRecurrence;
        reminder?: number;
        calendarType: CalendarType;
        calendarId: string;
        colorValue?: number;
        ignoreDelete?: boolean;
        nonEditable?: boolean;
        callRef?: DocumentReference;
    }) {
        super(name, startDate ? Timestamp.fromDate(startDate) : Timestamp.now(), isProcessing, ref, endDate ? Timestamp.fromDate(endDate) : Timestamp.now(), contacts, opportunityId);
        this.properties = properties;
        this.eventId = eventId;
        this.iCalUID = iCalUID;
        this.deleted = deleted;
        this.recurrence = recurrence;
        this.reminder = reminder;
        this.calendarType = calendarType;
        this.calendarId = calendarId;
        this.colorValue = colorValue;
        this.ignoreDelete = ignoreDelete;
        this.nonEditable = nonEditable;
        this.callRef = callRef;
    }

    copyWith({
        ref,
        name,
        startDate,
        endDate,
        properties,
        eventId,
        iCalUID,
        contacts,
        deleted,
        recurrence,
        reminder,
        calendarType,
        calendarId,
        colorValue,
        ignoreDelete,
        opportunityId,
        isProcessing,
        nonEditable,
        callRef,
    }: {
        ref?: DocumentReference;
        name?: string;
        startDate?: Date;
        endDate?: Date;
        properties?: CalendarEventProperties;
        eventId?: string;
        iCalUID?: string;
        contacts?: any[];
        deleted?: boolean;
        recurrence?: ForgeRecurrence;
        reminder?: number;
        calendarType?: CalendarType;
        calendarId?: string;
        colorValue?: number;
        ignoreDelete?: boolean;
        opportunityId?: string;
        isProcessing?: boolean;
        nonEditable?: boolean;
        callRef?: DocumentReference;
    }): CalendarEvent {
        return new CalendarEvent({
            ref: ref ?? this.ref,
            name: name ?? this.name,
            startDate: startDate ?? this.startDate.toDate(),
            endDate: endDate ?? this.endDate.toDate(),
            properties: properties ?? this.properties,
            eventId: eventId ?? this.eventId,
            iCalUID: iCalUID ?? this.iCalUID,
            contacts: contacts ?? this.contacts,
            deleted: deleted ?? this.deleted,
            recurrence: recurrence ?? this.recurrence,
            reminder: reminder ?? this.reminder,
            calendarType: calendarType ?? this.calendarType,
            calendarId: calendarId ?? this.calendarId,
            colorValue: colorValue ?? this.colorValue,
            ignoreDelete: ignoreDelete ?? this.ignoreDelete,
            opportunityId: opportunityId ?? this.opportunityId,
            isProcessing: isProcessing ?? this.isProcessing,
            nonEditable: nonEditable ?? this.nonEditable,
            callRef: callRef ?? this.callRef,
        });
    }

    toMap(): Record<string, any> {
        const event: Record<string, any> = {
            ref: this.ref?.path,
            name: this.name,
            startDate: this.startDate.toDate().valueOf(),
            endDate: this.endDate?.toDate().valueOf(),
            eventId: this.eventId,
            properties: this.properties.toMap(),
            iCalUID: this.iCalUID,
            // contacts: this.contacts?.map(contact => contact.toMap({ toFirestore })),
            deleted: this.deleted,
            recurrence: this.recurrence.toMap(),
            reminder: this.reminder,
            calendarType: this.calendarType.toString(),
            calendarId: this.calendarId,
            colorValue: this.colorValue,
            ignoreDelete: this.ignoreDelete,
            opportunityId: this.opportunityId,
            isProcessing: this.isProcessing,
            nonEditable: this.nonEditable,
            callRef: this.callRef?.path,
        };

        if (event.opportunityId == null) {
            delete event.opportunityId;
        }
        if (event.colorValue == null) {
            delete event.colorValue;
        }

        return event;
    }

    static fromMap(map: Record<string, any>, user: User): CalendarEvent {
        return new CalendarEvent({
            ref: map.ref ? (map.ref instanceof DocumentReference ? map.ref : doc(map.ref)) : doc(firestoreDb, `users/${user.uid}/events/${map.eventId}`),
            name: map.name,
            startDate: map.startDate ? (map.startDate instanceof Timestamp ? map.startDate.toDate() : new Date(map.startDate)) : undefined,
            endDate: map.endDate ? (map.endDate instanceof Timestamp ? map.endDate.toDate() : new Date(map.endDate)) : undefined,
            eventId: map.eventId,
            properties: map.properties ? CalendarEventProperties.fromMap(map.properties) : new CalendarEventProperties({}),
            iCalUID: map.iCalUID,
            // contacts: map.contacts && Array.isArray(map.contacts) && map.contacts.length > 0
            //     ? map.contacts.map((x: Record<string, any>) => ForgeContact.fromMap(x))
            //     : undefined,
            deleted: map.deleted,
            recurrence: map.recurrence ? ForgeRecurrence.fromMap(map.recurrence) : new ForgeRecurrence({ recurrent: false }),
            reminder: map.reminder,
            calendarType: map.calendarType,
            calendarId: map.calendarId ?? '',
            colorValue: map.colorValue,
            ignoreDelete: map.ignoreDelete ?? false,
            opportunityId: map.opportunityId,
            isProcessing: map.isProcessing ?? false,
            nonEditable: map.nonEditable ?? false,
            callRef: map.callRef ? (map.callRef instanceof DocumentReference ? map.callRef : doc(map.callRef)) : undefined,
        });
    }

    color() {
        if (this.colorValue) {
            this.colorValue >>>= 0;
            var b = this.colorValue & 0xFF,
                g = (this.colorValue & 0xFF00) >>> 8,
                r = (this.colorValue & 0xFF0000) >>> 16,
                a = ((this.colorValue & 0xFF000000) >>> 24) / 255;
            return "rgba(" + [r, g, b, a].join(",") + ")";
        }
        return '#0000FF';
    }

    isEventEditable(isCalendarWritable: (calendarId: string) => boolean) {
        // this.calendarId
        return !this.nonEditable && isCalendarWritable(this.calendarId);
    }

    get attendees(): ForgeAttendee[] {
        return [
            ...(this.properties.attendees ?? []),
            ...(this.properties.attendeesResearch ?? []),
        ];
    }

    get allAttendeesRefs(): DocumentReference[] {
        return [
            ...(this.properties.attendeesRefs ?? []),
            ...(this.properties.attendeesResearchRefs ?? []),
        ];
    }

    get hasAttendees(): boolean {
        return (this.properties.attendees !== null && this.properties.attendees.length > 0) ||
            (this.properties.attendeesResearch !== null && this.properties.attendeesResearch.length > 0);
    }

    get hasContacts(): boolean {
        return this.contacts !== null && this.contacts.length > 0;
    }

    get isHappeningNow(): boolean {
        return this.startDate.toDate().getTime() < Date.now() &&
            this.endDate !== null && this.endDate.toDate().getTime() > Date.now();
    }

    get hasPassed(): boolean {
        return this.endDate !== null && this.endDate.toDate().getTime() < Date.now();
    }

    get isDeclined(): boolean {
        return this.properties.userAttendee?.status === InvitationStatus.rejected;
    }

    get isInvite(): boolean {
        return this.properties.userAttendee?.status == InvitationStatus.none &&
            this.properties.attendees != null &&
            this.properties.attendees!.length > 0;
    }

    get isAllDay(): boolean {
        const endDate = this.endDate?.toDate() || this.startDate.toDate();
        const startDate = this.startDate.toDate();
        const differenceInMinutes = (endDate.getTime() - startDate.getTime()) / (1000 * 60);
        return differenceInMinutes >= 1435;
    }

    get cardCta(): string {
        if (this.isInvite) {
            return 'RSVP';
        } else if (this.isDeclined) {
            return 'Change RSVP';
        } else if (this.opportunityId && this.hasPassed) {
            if (!this.callRef) {
                return 'Log the conversation';
            }

            return 'Review notes';
        }

        return 'Prepare';
    }

}
