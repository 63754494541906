import { ArrowBackIos } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

function BackButton({ backSteps = -1, color = "black" }: { backSteps?: number, color?: string }) {
    const navigate = useNavigate();

    return (
        <IconButton onClick={() => { navigate(backSteps); }}>
            <ArrowBackIos style={{ color }} />
        </IconButton>
    );
}

export default BackButton;