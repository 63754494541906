import { createContext, useContext, useState, useEffect } from "react";
import { AuthContext } from "context";
import { LinkedInProfileOption } from "types/contact/linkedin-profile-option";
import ContactsFirestore from "./firestore";
import ContactsApi from "./api";

interface LinkedInProfileContextType {
    profileOptions: LinkedInProfileOption[];
    selectProfile: (option: LinkedInProfileOption) => Promise<void>
    setLinkedInUrl: (url: string) => Promise<void>
}

export const LinkedInProfileContext = createContext<LinkedInProfileContextType>({
    profileOptions: [],
    selectProfile: async () => { },
    setLinkedInUrl: async () => { },
});

export const LinkedInProfileContextProvider = ({ contact, children }: { contact: any, children: any }) => {
    // Context
    const { getCurrentUser, isEncryptionInitialized } = useContext(AuthContext);

    // Services
    const { user, encryptionService, userProfileData } = getCurrentUser();
    const contactsFirestoreService = new ContactsFirestore(user, userProfileData, encryptionService);
    const contactsApi = new ContactsApi(user, userProfileData, encryptionService)

    // State
    const [profileOptions, setProfileOptions] = useState<LinkedInProfileOption[]>([]);

    useEffect(() => {
        (async () => {
            if (user && contact?.ref) {
                const unsubscribe = contactsFirestoreService.getLinkedInProfileOptionsLive(
                    contact.ref,
                    (options) => setProfileOptions(options)
                );

                return () => unsubscribe();
            }
        })()
    }, [isEncryptionInitialized, contact?.ref]);

    const selectProfile = async (option: LinkedInProfileOption): Promise<void> => {
        await contactsApi.updateContact({
            ref: contact.ref,
            linkedInUrl: option.link,
        });
        await contactsApi.searchLinkedInData(contact, option);
    }

    const setLinkedInUrl = async (url: string): Promise<void> => {
        await contactsApi.updateContact({
            ref: contact.ref,
            linkedInUrl: url,
        });
        await contactsApi.searchLinkedInData(contact, undefined, url);
    }

    return (
        <LinkedInProfileContext.Provider
            value={{ profileOptions, selectProfile, setLinkedInUrl }}
        >
            {children}
        </LinkedInProfileContext.Provider>
    );
};