import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { AuthContext, ContactsContext } from "context";
import theme from "assets/theme";

// MUI
import {
    Avatar,
    Backdrop,
    Box,
    Chip,
    Divider,
    Drawer,
    FormControl,
    Icon,
    Stack,
    TextField,
    useMediaQuery,
} from "@mui/material";
import { Abc, CalendarMonth, EditNoteOutlined, OpenInNewRounded, PersonAdd } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// Material Dashboard
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Services
import { ForgeOpportunity } from "types/pipeline/opportunity";
import dayjs, { Dayjs } from "dayjs";
import { Call } from "types/pipeline/call";
import CallsApi from "../../services/api";

// Images
import spinningSphere from "assets/forge/gifs/spinning-sphere.gif";
import CreateCommitmentDrawer from "forge/commitments/components/CreateCommitmentDrawer";
import SelectContact from "forge/core/components/SelectContact";
import { CalendarEvent } from "types/calendar/calendar-event";

const styles = createUseStyles({
    formIcon: { alignSelf: "center", height: "1.5em", width: "1.5em", marginRight: "16px" },
    formTile: { display: "flex", alignItems: "flex-end", marginBottom: "12px" },
});

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                prefix="$"
            />
        );
    },
);

function CreateCall({
    openDrawer,
    handleCloseDrawer,
    opportunity,
    call,
    event,
}: {
    openDrawer: boolean,
    handleCloseDrawer: () => void,
    opportunity?: ForgeOpportunity
    call?: Call,
    event?: CalendarEvent,
}) {
    // UI
    const classes = styles();
    const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const largeScreen = useMediaQuery(theme.breakpoints.up("lg"));

    // Context
    const { getCurrentUser } = useContext(AuthContext);
    const { getContacts, getPossibleUserContacts } = useContext(ContactsContext);

    // API
    let user = getCurrentUser();
    const callsApi = new CallsApi(user.userProfileData)

    // Options
    const [contacts, setContacts] = useState<any[]>([]);

    // State
    const drawerRef = useRef(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [subjectGeneratorActive, setSubjectGeneratorActive] = useState<boolean>(() => !(call instanceof Call));
    const [contactSelectorActive, setContactSelectorActive] = useState(false);
    const [selectedContacts, setSelectedContacts] = useState(new Set());
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [closeDate, setCloseDate] = useState<Dayjs>(dayjs(new Date()));
    const [openCreateCommitmentDrawer, setOpenCreateCommitmentDrawer] = useState(false);
    const handleOpenCommitmentDrawer = () => setOpenCreateCommitmentDrawer(true);
    const handleCloseCommitmentDrawer = () => setOpenCreateCommitmentDrawer(false);
    const handleOpenContactSelector = () => setContactSelectorActive(true);
    const handleCloseContactSelector = () => setContactSelectorActive(false);

    useEffect(() => {
        let tmpContacts = [];

        if (opportunity) {
            let contactIds = [...opportunity.clientContactsIds, ...opportunity.teamContactsIds];
            tmpContacts = getContacts(contactIds);
            setContacts(tmpContacts);
            setName(`${opportunity.properties?.name} Call`);
        }

        if (call) {
            setSelectedContacts(new Set(call.contacts));
            setName(call.subject);
            setDescription(call.details);
            setCloseDate(dayjs(call.date));
        }

        if (opportunity && !call) {
            const possibleUserContacts = getPossibleUserContacts();
            let selectedOptions = new Set();

            for (const possibleContact of possibleUserContacts) {
                let userContact = tmpContacts.find((contact) => contact.id === possibleContact.id);
                if (userContact) {
                    selectedOptions.add(userContact);
                }
            }

            setSelectedContacts(selectedOptions);
        }
    }, [opportunity, call]);

    // generateSubject
    useEffect(() => {
        if (subjectGeneratorActive) {
            let subject = `${opportunity?.properties?.name} Call`;
            const selectedContactsList = [...selectedContacts];
            if (selectedContactsList.length > 0) {
                subject += " with ";
                const contactNames = selectedContactsList.map(e => composedShortName(e));
                subject += contactNames.join(", ");
            }

            setName(subject);
        }
    }, [selectedContacts]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);

        if (call?.ref) {
            call.subject = name;
            call.details = description;
            call.date = closeDate.toDate();
            call.contacts = [...selectedContacts];
            call.opportunityId = opportunity.id;

            await callsApi.updateCall(call, opportunity);
            return;
        }

        let newCall = new Call({
            subject: name,
            details: description,
            date: closeDate.toDate(),
            contacts: [...selectedContacts],
            opportunityId: opportunity.id,
            eventRef: event?.ref,
        });

        await callsApi.createCall(newCall, opportunity);
        setLoading(false);
        handleCloseDrawer();
    };

    const handleSecondaryAction = async () => {
        if (opportunity) {
            await callsApi.deleteCall(call);
        } else {
            handleCloseDrawer();
        }
    }

    function handleSelectionChanged(contact: any) {
        // Treat state as immutable
        // React only does a shallow comparison so we need a new Set
        const newSet = new Set(selectedContacts);
        if (newSet.has(contact)) {
            newSet.delete(contact);
        } else {
            newSet.add(contact);
        }
        setSelectedContacts(newSet);
    }

    function composedShortName(contact: any): string {
        let fullName = '';

        fullName += contact.firstName;
        if (contact.lastName != null && contact.lastName !== '') {
            fullName += ' ';
            fullName += contact.lastName.substring(0, 1);
            fullName += '.';
        }

        return fullName.trim();
    }

    const onContactSelected = (contact: any) => {
        handleCloseContactSelector();
        setContacts([...contacts, contact]);
        handleSelectionChanged(contact);
    }

    return (
        <Drawer
            anchor="right"
            ref={drawerRef}
            open={openDrawer}
            onClose={handleCloseDrawer}
            PaperProps={{
                sx: {
                    height: "fit-content",
                    maxHeight: "96%",
                    width: largeScreen ? "40%" : mediumScreen ? "70%" : "90%",
                },
            }}
        >
            <CreateCommitmentDrawer
                openDrawer={openCreateCommitmentDrawer}
                handleCloseDrawer={handleCloseCommitmentDrawer}
                opportunity={opportunity}
            />
            <FormControl component="form" onSubmit={handleSubmit} style={{ display: "contents" }}>
                <Backdrop
                    sx={{ background: 'rgba(255, 255, 255, 0.5)', zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute' }}
                    open={loading}
                >
                    <img src={spinningSphere} style={{ width: "200px" }} alt="Loading..." />
                </Backdrop>

                {/* Header */}
                < MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="baseline"
                    pt={4}
                    pb={0.5}
                    px={3}
                >
                    <MDBox>
                        <MDTypography variant="h5">Log a Conversation</MDTypography>
                    </MDBox>
                    <Icon
                        sx={({ typography: { size }, palette: { dark, white } }) => ({
                            fontSize: `${size.lg} !important`,
                            color: dark.main,
                            stroke: "currentColor",
                            strokeWidth: "2px",
                            cursor: "pointer",
                            transform: "translateY(5px)",
                        })}
                        onClick={handleCloseDrawer}
                    >
                        close
                    </Icon>
                </MDBox >
                <Divider />

                {/* Body */}
                {contactSelectorActive ? <SelectContact onContactSelected={onContactSelected} />
                    : <MDBox>
                        <MDBox style={{ width: "100%", flex: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MDBox px={3}>
                                    <MDBox className={classes.formTile}>
                                        <CalendarMonth className={classes.formIcon} />
                                        <MDBox>
                                            <MDTypography variant="body2" color="text" style={{ alignSelf: "center" }}>
                                                Who attended?
                                            </MDTypography>
                                            <Stack direction="row" useFlexGap flexWrap="wrap" alignItems="center">
                                                {contacts.map((contact: any) => (
                                                    <Chip
                                                        label={composedShortName(contact)}
                                                        style={{
                                                            margin: "4px",
                                                            background: selectedContacts.has(contact) ? "black" : undefined,
                                                            color: selectedContacts.has(contact) ? "white" : undefined
                                                        }}
                                                        onClick={() => handleSelectionChanged(contact)}
                                                    />
                                                ))}
                                                <Avatar
                                                    style={{ background: "cornflowerblue", width: "34px", height: "34px", cursor: "pointer" }}
                                                    onClick={handleOpenContactSelector}
                                                >
                                                    <PersonAdd fontSize="small" style={{ color: "white" }} />
                                                </Avatar>
                                            </Stack>
                                        </MDBox>
                                    </MDBox>
                                    <MDBox className={classes.formTile}>
                                        <Abc className={classes.formIcon} />
                                        <TextField
                                            id="name"
                                            label="Subject"
                                            variant="outlined"
                                            style={{ flex: 1 }}
                                            value={name}
                                            onChange={(e) => {
                                                setSubjectGeneratorActive(false);
                                                return setName(e.target.value);
                                            }}
                                        />
                                    </MDBox>
                                    <MDBox className={classes.formTile}>
                                        <EditNoteOutlined className={classes.formIcon} />
                                        <TextField
                                            id="description"
                                            label="Description"
                                            variant="outlined"
                                            style={{ flex: 1 }}
                                            multiline
                                            minRows={5}
                                            maxRows={10}
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                        />
                                    </MDBox>
                                    <MDBox
                                        bgColor={"grey-100"}
                                        borderRadius="lg"
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems={{ xs: "flex-start", sm: "center" }}
                                        flexDirection={{ xs: "row", sm: "row" }}
                                        style={{ cursor: "pointer" }}
                                        mb={2}
                                        ml={5.5}
                                        py={1}
                                        pl={2}
                                        pr={2}
                                        onClick={handleOpenCommitmentDrawer}
                                    >
                                        <MDTypography variant="body2" style={{ alignSelf: "center" }}>
                                            Have you committed to any next steps?
                                        </MDTypography>
                                        <OpenInNewRounded />
                                    </MDBox>
                                    <MDBox className={classes.formTile}>
                                        <CalendarMonth className={classes.formIcon} />
                                        <MDTypography variant="body2" style={{ alignSelf: "center" }}>
                                            Conversation Date
                                        </MDTypography>
                                        <Box style={{ flex: 1 }} />
                                        <DatePicker
                                            format="MMM DD, YYYY"
                                            value={closeDate}
                                            onChange={setCloseDate} />
                                    </MDBox>
                                </MDBox>
                            </LocalizationProvider>
                        </MDBox>

                        {/* Footer */}
                        <Box
                            sx={{
                                display: "flex",
                                gap: 1,
                                p: 1.5,
                                pb: 2,
                                borderTop: "1px solid",
                                borderColor: "divider",
                                justifyContent: "space-between",
                            }}
                        >
                            <MDButton variant="text" color={opportunity ? 'error' : 'dark'} onClick={handleSecondaryAction}>
                                {call?.ref ? 'Delete' : 'Clear'}
                            </MDButton>
                            <MDButton variant="gradient" color="info" type="submit">
                                {call?.ref ? 'Update' : 'Save'}
                            </MDButton>
                        </Box>
                    </MDBox>
                }
            </FormControl>
        </Drawer>
    );
}

// Setting default values for the props of CreateCall
CreateCall.defaultProps = {
    handleCloseDrawer: null,
};

export default CreateCall;