import {
    doc,
    updateDoc,
} from "firebase/firestore";
import { firestoreDb } from "firebase.init";
import { User } from "firebase/auth";
import { UserProfileData } from "types/user/user-profile-data";
import { UserSettings } from "types/user/user-settings";
import { ForgeEncryption } from "forge/core/services/encryption";

class SettingsFirestoreService {
    private user: User;
    private userProfileData: UserProfileData;
    private encryptionService: ForgeEncryption;

    constructor(user: User, userProfileData: UserProfileData, encryptionService: ForgeEncryption) {
        this.user = user;
        this.userProfileData = userProfileData;
        this.encryptionService = encryptionService;
    }

    updateUserSettings = async (
        userSettings: UserSettings,
    ): Promise<void> => {
        const documentRef = doc(
            firestoreDb,
            `users/${this.user.uid}`
        );

        await updateDoc(documentRef, {
            settings: userSettings.toMap()
        });
        return;
    }
}

export default SettingsFirestoreService;