import { Block, Delete, Edit, Merge, NotificationsActive, NotificationsOffOutlined, Star, StarBorderOutlined } from "@mui/icons-material";
import { CircularProgress, IconButton, Stack } from "@mui/material";
import { AuthContext } from "context";
import ConfirmationDialog from "forge/core/components/ConfirmationDialog";
import { useContext, useState } from "react";
import ContactsApi from "../../services/api";
import { useNavigate } from "react-router-dom";
import EditContactDrawer from "../EditContactDrawer";
import MergeContactsDrawer from "../MergeContactsDrawer";
import { DisabledTooltip } from "forge/organization/rbac/DisabledTooltip";
import { Can } from "forge/organization/rbac/CanContext";

function ContactHeaderMenu({ contact, isOrganizationContact }: { contact: any, isOrganizationContact: boolean }) {
    // Context
    const { getCurrentUser } = useContext(AuthContext);

    // Services
    let { user, encryptionService, userProfileData } = getCurrentUser();
    const contactsApi = new ContactsApi(user, userProfileData, encryptionService)

    // Other
    const navigate = useNavigate();

    // State
    const [favoriteLoading, setFavoriteLoading] = useState(false);
    const [notificationsLoading, setNotificationsLoading] = useState(false);
    const [openEditDrawer, setOpenEditDrawer] = useState(false);
    const [openMergeDrawer, setOpenMergeDrawer] = useState(false);
    const [openDialogIgnore, setOpenDialogIgnore] = useState(false);
    const [openDialogDelete, setOpenDialogDelete] = useState(false);

    const handleOpenDrawer = () => setOpenEditDrawer(true);
    const handleCloseDrawer = () => setOpenEditDrawer(false);
    const handleOpenMergeDrawer = () => setOpenMergeDrawer(true);
    const handleCloseMergeDrawer = () => setOpenMergeDrawer(false);
    const handleOpenDialogIgnore = () => setOpenDialogIgnore(true);
    const handleOpenDialogDelete = () => setOpenDialogDelete(true);
    const handleCloseDialogIgnore = async (result: boolean) => {
        setOpenDialogIgnore(false);
        if (result) {
            // The delete API call handles the Ignore flow 
            // and Mike want the contact to also be deleted.
            let deleteResult = await contactsApi.deleteContact(contact);
            if (deleteResult) {
                navigate("/people/relationships");
            }
        }
    };
    const handleCloseDialogDelete = async (result: boolean) => {
        setOpenDialogDelete(false);
        if (result && contact) {
            let deleteResult = await contactsApi.deleteContact(contact);
            if (deleteResult) {
                navigate("/people/relationships");
            }
        }
    };

    const toggleFavorite = async (event: any) => {
        event.preventDefault();
        setFavoriteLoading(true);

        try {
            if (contact) {
                let updatedContact: any = {
                    ref: contact.ref,
                    favorite: !contact.favorite,
                };

                await contactsApi.updateContact(updatedContact);
            }
        } catch (error) {
            console.error(error);
        }

        setFavoriteLoading(false);
    }

    const toggleNotifications = async (event: any) => {
        event.preventDefault();
        setNotificationsLoading(true);

        try {
            if (contact) {
                let updatedContact: any = {
                    ref: contact.ref,
                    notificationEnabled: !(contact.notificationEnabled ?? true),
                };

                await contactsApi.updateContact(updatedContact);
            }
        } catch (error) {
            console.error(error);
        }

        setNotificationsLoading(false);
    }

    return (
        <div>
            <EditContactDrawer
                contact={contact}
                openDrawer={openEditDrawer}
                handleCloseDrawer={handleCloseDrawer} />
            <MergeContactsDrawer
                contact={contact}
                openDrawer={openMergeDrawer}
                handleCloseDrawer={handleCloseMergeDrawer} />
            <ConfirmationDialog
                openDialog={openDialogIgnore}
                handleCloseDialog={handleCloseDialogIgnore}
                title="Are you sure you want to ignore this contact?"
                description="You can always remove this Attendee from the ignore list in Settings."
                confirmText="Ignore"
                confirmColor="warning"
            />
            <ConfirmationDialog
                openDialog={openDialogDelete}
                handleCloseDialog={handleCloseDialogDelete}
                title="Are you sure you want to delete this contact?"
                description="This is a permanent action."
                confirmText="Delete"
                confirmColor="error"
            />
            <Stack
                direction="row"
                useFlexGap
                flexWrap="wrap"
                alignItems="center"
                spacing={0}
            >
                {favoriteLoading ?
                    <CircularProgress size={24} style={{ color: 'white', marginLeft: 8, marginRight: 8 }} />
                    : <DisabledTooltip
                        allowed={!isOrganizationContact}
                        message="Coming soon to shared contacts!"
                        backgroundColor="grey">
                        <IconButton
                            id="favorite-button"
                            disabled={isOrganizationContact}
                            onClick={toggleFavorite}
                        >
                            {contact?.favorite
                                ? <Star style={{ color: isOrganizationContact ? 'grey' : 'white' }} />
                                : <StarBorderOutlined style={{ color: isOrganizationContact ? 'grey' : 'white' }} />}
                        </IconButton>
                    </DisabledTooltip>
                }
                {notificationsLoading ?
                    <CircularProgress size={24} style={{ color: 'white', marginLeft: 8, marginRight: 8 }} />
                    : <DisabledTooltip
                        allowed={!isOrganizationContact}
                        message="Coming soon to shared contacts!"
                        backgroundColor="grey">
                        <IconButton
                            id="notifications-button"
                            disabled={isOrganizationContact}
                            onClick={toggleNotifications}
                        >
                            {contact?.notificationEnabled ?? true
                                ? <NotificationsActive style={{ color: isOrganizationContact ? 'grey' : 'white' }} />
                                : <NotificationsOffOutlined style={{ color: isOrganizationContact ? 'grey' : 'white' }} />}
                        </IconButton>
                    </DisabledTooltip>
                }
                <Can I="edit" a="contacts" passThrough>
                    {(allowed: boolean) =>
                        <DisabledTooltip
                            allowed={allowed}
                            backgroundColor="grey">
                            <IconButton
                                id="edit-button"
                                disabled={!allowed}
                                onClick={handleOpenDrawer}
                            >
                                <Edit style={{ color: allowed ? 'white' : 'grey' }} />
                            </IconButton>
                        </DisabledTooltip>}
                </Can>
                <Can I="merge" a="contacts" passThrough>
                    {(allowed: boolean) =>
                        <DisabledTooltip
                            allowed={!isOrganizationContact}
                            backgroundColor="grey">
                            <IconButton
                                id="merge-button"
                                disabled={!allowed}
                                onClick={handleOpenMergeDrawer}
                            >
                                <Merge style={{ color: allowed ? 'white' : 'grey' }} />
                            </IconButton>
                        </DisabledTooltip>}
                </Can>
                {contact?.status === "draft" && <Can I="ignore" a="contacts" passThrough>
                    {(allowed: boolean) =>
                        <DisabledTooltip
                            allowed={!isOrganizationContact}
                            backgroundColor="grey">
                            <IconButton
                                id="ignore-button"
                                disabled={!allowed}
                                onClick={handleOpenDialogIgnore}
                            >
                                <Block style={{ color: allowed ? 'white' : 'grey' }} />
                            </IconButton>
                        </DisabledTooltip>
                    }
                </Can>}
                <Can I="delete" a="contacts" passThrough>
                    {(allowed: boolean) =>
                        <DisabledTooltip
                            allowed={!isOrganizationContact}
                            backgroundColor="grey">
                            <IconButton
                                id="delete-button"
                                disabled={!allowed}
                                onClick={handleOpenDialogDelete}
                            >
                                <Delete style={{ color: allowed ? 'white' : 'grey' }} />
                            </IconButton>
                        </DisabledTooltip>}
                </Can>
            </Stack>
        </div>
    );
}

export default ContactHeaderMenu;