import {
    collection,
    query,
    orderBy,
    Unsubscribe,
    where,
    DocumentReference,
} from "firebase/firestore";
import { firestoreDb } from "firebase.init";
import { Commitment } from "types/commitment";
import { User } from "firebase/auth";
import { UserProfileData } from "types/user/user-profile-data";
import { firestoreDebounce } from "forge/core/utils/firestore";
import { ForgeEncryption } from "forge/core/services/encryption";

class CommitmentsFirestore {
    private user: User;
    private userProfileData: UserProfileData;
    private encryptionService: ForgeEncryption;

    constructor(user: User, userProfileData: UserProfileData, encryptionService: ForgeEncryption) {
        this.user = user;
        this.userProfileData = userProfileData;
        this.encryptionService = encryptionService;
    }

    getPersonalCommitmentsLive = (
        onEvent: (opportunities: Commitment[]) => void,
        contactRef?: DocumentReference,
        opportunityId?: string,
    ): Unsubscribe => {
        let collectionRef;
        if (contactRef) {
            collectionRef = query(
                collection(firestoreDb, `users/${this.user.uid}/commitments`),
                where("contactRefs", "array-contains", contactRef),
                where("isArchived", "==", false),
                where("completed", "==", false),
                orderBy("startDate")
            );
        } else if (opportunityId) {
            collectionRef = query(
                collection(firestoreDb, `users/${this.user.uid}/commitments`),
                where("opportunityId", "==", opportunityId),
                where("isArchived", "==", false),
                where("completed", "==", false),
                orderBy("startDate")
            );
        } else {
            collectionRef = query(
                collection(firestoreDb, `users/${this.user.uid}/commitments`),
                where("isArchived", "==", false),
                where("completed", "==", false),
                orderBy("startDate", "asc")
            );
        }

        return firestoreDebounce(
            collectionRef,
            async (snapshot) => {
                const commitmentsPromises = snapshot.docs.map(
                    (doc) => (Commitment.fromMap(
                        {
                            ...doc.data(),
                            isOrganizationCommitment: false,
                        },
                        this.encryptionService,
                    ))
                );

                let decrypted = await Promise.allSettled(commitmentsPromises);
                const commitments: Commitment[] = decrypted
                    .filter((e: any) => e.status === "fulfilled")
                    .map((e: any) => e.value);

                onEvent(commitments);
            }
        );
    }

    getOrganizationCommitmentsLive = (
        onEvent: (opportunities: Commitment[]) => void,
        contactRef?: DocumentReference,
        opportunityId?: string,
    ): Unsubscribe => {
        if (this.userProfileData?.organization?.id) {
            let collectionRef;
            if (contactRef) {
                collectionRef = query(
                    collection(firestoreDb, `organizations/${this.userProfileData.organization?.id}/commitments`),
                    where("contactRefs", "array-contains", contactRef),
                    where("isArchived", "==", false),
                    where("completed", "==", false),
                    orderBy("startDate")
                );
            } else if (opportunityId) {
                collectionRef = query(
                    collection(firestoreDb, `organizations/${this.userProfileData.organization?.id}/commitments`),
                    where("opportunityId", "==", opportunityId),
                    where("isArchived", "==", false),
                    where("completed", "==", false),
                    orderBy("startDate")
                );
            } else {
                collectionRef = query(
                    collection(firestoreDb, `organizations/${this.userProfileData.organization?.id}/commitments`),
                    where("isArchived", "==", false),
                    where("completed", "==", false),
                    orderBy("startDate", "asc")
                );
            }

            return firestoreDebounce(
                collectionRef,
                async (snapshot) => {
                    const commitmentsPromises = snapshot.docs.map(
                        (doc) => (Commitment.fromMap(
                            {
                                ...doc.data(),
                                isOrganizationCommitment: true,
                            },
                            this.encryptionService,
                        ))
                    );

                    let decrypted = await Promise.allSettled(commitmentsPromises);
                    const commitments: Commitment[] = decrypted
                        .filter((e: any) => e.status === "fulfilled")
                        .map((e: any) => e.value);

                    onEvent(commitments);
                }
            );
        }
        return;
    }
}

export default CommitmentsFirestore;