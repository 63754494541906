import { Avatar, Card, Tooltip } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { HexagonAvatar } from "forge/core/components/HexagonAvatar";
import { ForgeOrganizationTeam } from "forge/organization/types/team";

function TeamCard({
    team,
    subtitle,
    onClick,
    actions
}: {
    team: ForgeOrganizationTeam,
    subtitle?: any,
    onClick?: any,
    actions?: any
}) {
    return (
        <Card
            variant="outlined"
            style={{
                boxShadow: "none",
                border: "1.5px solid black",
                marginBottom: "8px",
                cursor: onClick ? "pointer" : "auto",
                width: "100%",
                background: 'white'
            }}
            onClick={onClick}
        >
            <MDBox display="flex" alignItems="center" m={1}>
                <MDBox mx={1} my={1} >
                    <Tooltip title={team.name}>
                        <HexagonAvatar>
                            {team.name[0]}
                        </HexagonAvatar>
                    </Tooltip>
                </MDBox>
                <MDBox ml={0.5} lineHeight={0} sx={{ width: "-webkit-fill-available" }}>
                    <MDBox mt={0.5} />
                    <MDTypography
                        variant="h6"
                        lineHeight={1}
                        color={'black'}
                    >
                        {team.name}
                    </MDTypography>
                    <MDBox mt={0.25} />
                    <MDTypography
                        variant="body2"
                        lineHeight={1}
                        style={{ "font-size": "0.9rem" }}
                        color={'black'}
                    >
                        {subtitle}
                    </MDTypography>
                </MDBox>
                {actions}
            </MDBox>
        </Card>
    );
};

export default TeamCard;