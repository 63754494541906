export enum QuestionType {
    open = "QuestionType.open",
    openFIDB = "QuestionType.openFIDB",
    button = "QuestionType.button",
    radio = "QuestionType.radio",
    picker = "QuestionType.picker",
    pickerYears = "QuestionType.pickerYears",
    checkbox = "QuestionType.checkbox",
    datePicker = "QuestionType.datePicker",
    commitment = "QuestionType.commitment",
    people = "QuestionType.people",
}

// Reverse mapping
type QuestionTypeStringMap = {
    [key in QuestionType]: string;
};

const questionTypeStringMap: QuestionTypeStringMap = {
    [QuestionType.open]: "QuestionType.open",
    [QuestionType.openFIDB]: "QuestionType.openFIDB",
    [QuestionType.button]: "QuestionType.button",
    [QuestionType.radio]: "QuestionType.radio",
    [QuestionType.picker]: "QuestionType.picker",
    [QuestionType.pickerYears]: "QuestionType.pickerYears",
    [QuestionType.checkbox]: "QuestionType.checkbox",
    [QuestionType.datePicker]: "QuestionType.datePicker",
    [QuestionType.commitment]: "QuestionType.commitment",
    [QuestionType.people]: "QuestionType.people",
};

export enum QuestionCategory {
    origin = "QuestionCategory.origin",
    relationships = "QuestionCategory.relationships",
    family = "QuestionCategory.family",
    hobbies = "QuestionCategory.hobbies",
    sports = "QuestionCategory.sports",
    education = "QuestionCategory.education",
    meet = "QuestionCategory.meet",
    help = "QuestionCategory.help",
    commitment = "QuestionCategory.commitment",
    entertainment = "QuestionCategory.entertainment",
    religion = "QuestionCategory.religion",
    pets = "QuestionCategory.pets",
    personal = "QuestionCategory.personal",
}

// Reverse mapping
type QuestionCategoryStringMap = {
    [key in QuestionCategory]: string;
};

const questionCategoryStringMap: QuestionCategoryStringMap = {
    [QuestionCategory.origin]: "QuestionCategory.origin",
    [QuestionCategory.relationships]: "QuestionCategory.relationships",
    [QuestionCategory.family]: "QuestionCategory.family",
    [QuestionCategory.hobbies]: "QuestionCategory.hobbies",
    [QuestionCategory.sports]: "QuestionCategory.sports",
    [QuestionCategory.education]: "QuestionCategory.education",
    [QuestionCategory.meet]: "QuestionCategory.meet",
    [QuestionCategory.help]: "QuestionCategory.help",
    [QuestionCategory.commitment]: "QuestionCategory.commitment",
    [QuestionCategory.entertainment]: "QuestionCategory.entertainment",
    [QuestionCategory.religion]: "QuestionCategory.religion",
    [QuestionCategory.pets]: "QuestionCategory.pets",
    [QuestionCategory.personal]: "QuestionCategory.personal",
};


export class Question {
    id: string;
    question: string;
    answer: string[];
    answerFormat: string;
    conditionalAnswerFormats?: { [key: string]: string };
    nullPredecessors: string[];
    notNullPredecessors: string[];
    type: QuestionType;
    possibleAnswers?: string[];
    category: QuestionCategory;
    isMultipleAnswer: boolean;
    prefill?: string;
    idsToDelete?: string[];

    constructor({
        id,
        question,
        answer,
        answerFormat,
        conditionalAnswerFormats,
        nullPredecessors,
        notNullPredecessors,
        type,
        possibleAnswers,
        category,
        isMultipleAnswer = false,
        prefill,
        idsToDelete,
    }: {
        id: string;
        question: string;
        answer: string[];
        answerFormat: string;
        conditionalAnswerFormats?: { [key: string]: string };
        nullPredecessors: string[];
        notNullPredecessors: string[];
        type: QuestionType;
        possibleAnswers?: string[];
        category: QuestionCategory;
        isMultipleAnswer?: boolean;
        prefill?: string;
        idsToDelete?: string[];
    }) {
        this.id = id;
        this.question = question;
        this.answer = answer;
        this.answerFormat = answerFormat;
        this.conditionalAnswerFormats = conditionalAnswerFormats;
        this.nullPredecessors = nullPredecessors;
        this.notNullPredecessors = notNullPredecessors;
        this.type = type;
        this.possibleAnswers = possibleAnswers;
        this.category = category;
        this.isMultipleAnswer = isMultipleAnswer ?? false;
        this.prefill = prefill;
        this.idsToDelete = idsToDelete;
    }

    copyWith({
        id,
        question,
        answer,
        answerFormat,
        conditionalAnswerFormats,
        nullPredecessors,
        notNullPredecessors,
        type,
        possibleAnswers,
        category,
        isMultipleAnswer,
        prefill,
        idsToDelete,
    }: Partial<Question>): Question {
        return new Question({
            id: id ?? this.id,
            question: question ?? this.question,
            answer: answer ?? this.answer,
            answerFormat: answerFormat ?? this.answerFormat,
            conditionalAnswerFormats: conditionalAnswerFormats ?? this.conditionalAnswerFormats,
            nullPredecessors: nullPredecessors ?? this.nullPredecessors,
            notNullPredecessors: notNullPredecessors ?? this.notNullPredecessors,
            type: type ?? this.type,
            possibleAnswers: possibleAnswers ?? this.possibleAnswers,
            category: category ?? this.category,
            isMultipleAnswer: isMultipleAnswer ?? this.isMultipleAnswer,
            prefill: prefill ?? this.prefill,
            idsToDelete: idsToDelete ?? this.idsToDelete,
        });
    }

    toMap(): any {
        return {
            id: this.id,
            question: this.question,
            answer: this.answer,
            answerFormat: this.answerFormat,
            conditionalAnswerFormats: this.conditionalAnswerFormats,
            nullPredecessors: this.nullPredecessors,
            notNullPredecessors: this.notNullPredecessors,
            type: this.type.toString(),
            possibleAnswers: this.possibleAnswers,
            category: this.category.toString(),
            isMultipleAnswer: this.isMultipleAnswer,
            prefill: this.prefill,
            idsToDelete: this.idsToDelete,
        };
    }

    static fromMap(map: any): Question {
        return new Question({
            id: map.id || '',
            question: map.question || '',
            answer: map.answer || [],
            answerFormat: map.answerFormat || '',
            conditionalAnswerFormats: map.conditionalAnswerFormats || {},
            nullPredecessors: map.nullPredecessors || [],
            notNullPredecessors: map.notNullPredecessors || [],
            type: Object.keys(questionTypeStringMap).find(
                (key) => questionTypeStringMap[key as QuestionType] === map.type
            ) as QuestionType | undefined,
            possibleAnswers: map.possibleAnswers || [],
            category: Object.keys(questionCategoryStringMap).find(
                (key) => questionCategoryStringMap[key as QuestionCategory] === map.category
            ) as QuestionCategory | undefined,
            isMultipleAnswer: map.isMultipleAnswer || false,
            prefill: map.prefill,
            idsToDelete: map.idsToDelete || [],
        });
    }

    renderQuestion(answers: { [key: string]: any }, contact: any): string {
        try {
            let questionText: string = this.question;
            const regExp: RegExp = new RegExp(/\{(.*?)\}/g);
            const matches: RegExpMatchArray | null = questionText.match(regExp);

            if (matches) {
                for (const match of matches) {
                    let replacement: string = "";
                    const key: string = match.substring(1, match.length - 1);

                    if (key === "firstName") {
                        replacement = contact?.firstName || "";
                    } else if (key === "lastName") {
                        replacement = contact?.lastName || answers[key] || "";
                    } else {
                        replacement = answers[key] || "";
                    }

                    questionText = questionText.replace(match, replacement);
                }
            }

            return questionText;
        } catch (e) {
            console.error(e);
            return this.question;
        }
    }

    renderQuestionVars(answers: { [key: string]: any }): string[] {
        const children: string[] = [];
        if (this.possibleAnswers) {
          for (const possibleAnswer of this.possibleAnswers) {
            try {
              let answerText = possibleAnswer;
              const regExp = /\{(.*?)\}/;
              const matches = possibleAnswer.match(regExp);
              if (!matches) {
                children.push(answerText);
              }
              if (matches) {
                const match = matches[0];
                const group1 = match.substring(1, match.length - 1); // Extract group without curly braces
                if (answers[group1] !== undefined) {
                  answerText = answerText.replace(
                    RegExp(match.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')), // Escape special characters in match
                    answers[group1] ?? ""
                  );
                  children.push(answerText);
                }
              }
            } catch (e) {
              console.log(e);
              children.push(possibleAnswer);
            }
          }
        }
        return children;
      }
      

    getAnswerText(answers: { [key: string]: any }): string {
        let answerText: string = this.answerFormat;

        if (
            this.conditionalAnswerFormats !== null &&
            this.answer.length === 1 &&
            answers.hasOwnProperty(this.answer[0])
        ) {
            const answerKey: string = this.answer[0];

            if (this.conditionalAnswerFormats.hasOwnProperty(answers[answerKey])) {
                answerText = this.conditionalAnswerFormats[answers[answerKey]];
            } else if (this.conditionalAnswerFormats.hasOwnProperty("default")) {
                answerText = this.conditionalAnswerFormats["default"];
            }
        }

        return answerText;
    }

    renderAnswer(answers: { [key: string]: any }, answerText: string, contact: any): string {
        try {
            const regExp = new RegExp(/\{(.*?)\}/g);
            const matches: RegExpMatchArray | null = answerText.match(regExp);

            if (matches) {
                for (const match of matches) {
                    let replacement: string = "";
                    const key: string = match.substring(1, match.length - 1);

                    if (key.toLowerCase() === "firstName".toLowerCase()) {
                        replacement = contact.firstName || "";
                    } else if (key.toLowerCase() === "lastName".toLowerCase()) {
                        replacement = contact.lastName || answers[key] || "";
                    } else {
                        if (this.isMultipleAnswer && this.answer.length > 0 && this.answer.includes(key)) {
                            for (const answer of this.answer) {
                                const tmpAnswer = answers[answer];
                                if (tmpAnswer != null) {
                                    replacement += `${tmpAnswer}, `;
                                }
                            }
                            replacement = replacement.substring(0, replacement.length - 2);
                        } else {
                            replacement = answers[key] || "";
                        }
                    }

                    answerText = answerText.replace(match, replacement);
                }
            }

            return answerText;
        } catch (e) {
            console.error(e);
            return this.answerFormat;
        }
    }

}
