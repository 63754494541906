import { VirgilService } from './virgil';
import { SealdService } from './seald';
import { generateRandomPassword } from './utils';
import { firestoreDb } from 'firebase.init';
import { doc, updateDoc } from 'firebase/firestore';

export interface EncryptionResult {
    result: any,
    encrypter: string,
    encrypted: boolean,
}
export class ForgeEncryption {
    private sealdService: SealdService;
    private virgilService: VirgilService;

    constructor(sealdService: SealdService, virgilService: VirgilService) {
        this.sealdService = sealdService;
        this.virgilService = virgilService;
    }

    createIdentity = async ({
        userId,
        existingPassword
    }: {
        userId: string,
        existingPassword?: string
    }): Promise<string> => {
        let password = existingPassword ?? generateRandomPassword();

        // Seald
        try {
            let sealdIdentity = await this.sealdService.createIdentity({
                userId,
                password
            });

            const userRef = doc(
                firestoreDb,
                `users/${userId}`
            );

            await updateDoc(userRef, { "encryption.sealdIdentity": sealdIdentity });
        } catch (error) {
            console.warn(error);
            return;
        }

        return password;
    }

    retrieveIdentity = async ({ userId, password }: { userId: string, password: string }): Promise<void> => {
        // Virgil
        try {
            await this.virgilService.retrieveIdentity({ password });
        } catch (error) {
            console.warn(error);
        }

        // Seald
        try {
            await this.sealdService.retrieveIdentity({
                userId,
                password
            });
        } catch (error) {
            console.warn(error);
        }
    }

    // --------------------------------ENCRYPTION-------------------------------

    encrypt = async (data: string, organizationId?: string): Promise<EncryptionResult> => {
        return await this.sealdService.encrypt(data, organizationId);
    }

    // --------------------------------DECRYPTION-------------------------------

    decrypt = async ({
        data,
        encrypter,
    }: {
        data: string,
        encrypter?: string,
    }): Promise<any> => {
        // All Organization encryption & new Personal Encryption handled by Seald
        if (encrypter === "seald") {
            return this.sealdService.decrypt(data);
        }

        return this.virgilService.decrypt(data);
    }

    // ----------------------------------GROUPS---------------------------------

    createOrganizationGroup = async (
        name: string,
        sealdId: string,
        organizationId: string
    ): Promise<any> => {
        return await this.sealdService.createOrganizationGroup(
            name,
            sealdId,
            organizationId
        );
    }

    deleteOrganizationGroup = async (organizationId: string) => {
        return await this.sealdService.deleteOrganizationGroup(organizationId);
    }

    addMemberToGroup = async (
        groupSealdId: string,
        memberSealdId: string,
        isAdminRole: boolean,
    ) => {
        return await this.sealdService.addMemberToGroup(
            groupSealdId,
            memberSealdId,
            isAdminRole,
        );
    }

    removeMemberFromGroup = async (
        groupSealdId: string,
        memberSealdId: string,
    ) => {
        return await this.sealdService.removeMemberFromGroup(
            groupSealdId,
            memberSealdId,
        );
    }

    manageGroupAdmins = async (
        groupSealdId: string,
        memberSealdId: string,
        remove: boolean,
    ) => {
        return await this.sealdService.manageGroupAdmins(
            groupSealdId,
            memberSealdId,
            remove,
        );
    }

    // TODO: Work on cleanup
    cleanup() {

    }
}