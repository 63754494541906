import { DocumentData, Query, QuerySnapshot, onSnapshot } from "firebase/firestore";
import { debounce } from "lodash";

export const firestoreDebounce = (
    collectionRef: Query<DocumentData, DocumentData>,
    callback: (snapshot: QuerySnapshot<DocumentData, DocumentData>) => void,
) => {
    const debouncedHandlerSnapshot = debounce(callback, 1000);

    const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
        debouncedHandlerSnapshot(snapshot);
    });

    return () => {
        unsubscribe();
        debouncedHandlerSnapshot.cancel(); // Cancel any pending debounced calls
    };
}