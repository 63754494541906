import { CallOutlined, ForumOutlined, LinkedIn, LocationOn, MailOutline, SmartphoneOutlined } from "@mui/icons-material"
import { Card, Dialog, Stack } from "@mui/material"
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import ContactHeaderHotButton from "../ContactHeaderHotButton";
import ContactHeaderMenu from "../ContactHeaderMenu";
import { ForgeContactAvatar } from "forge/core/components/ForgeAvatar";
import { useContext, useEffect, useState } from "react";
import BackButton from "forge/core/components/BackButton";
import { ForgeOrganizationMember } from "forge/organization/types/member";
import { MembersContext } from "forge/organization/members/services/MembersContext";
import { DocumentReference } from "firebase/firestore";
import MemberTile from "forge/organization/members/components/MemberTile";
import telephoneWhite from "assets/forge/svgs/telephone_white.svg";
import telephoneBlack from "assets/forge/svgs/telephone_black.svg";

function ContactHeaderCard({ contact, isContactProfilePage = true }: { contact: any, isContactProfilePage?: boolean }) {
    // Context
    const { getMember } = useContext(MembersContext);

    // State
    const [open, setOpen] = useState(false);
    const [isOrgContact, setIsOrganizationContact] = useState(false);
    const [creatorMember, setMemberCreator] = useState<ForgeOrganizationMember>();

    const onAvatarClicked = () => setOpen(true);
    const handleCloseDialog = () => setOpen(false);

    useEffect(() => {
        if (contact?.isOrganizationContact && contact?.createdBy && contact.createdBy instanceof DocumentReference) {
            setIsOrganizationContact(true);
            setMemberCreator(getMember(contact.createdBy.id));
        }
    }, [contact]);

    return <Card sx={({
        background: "black"
    })}>
        <Stack direction="row" useFlexGap flexWrap="wrap" m={3} spacing={2} justifyContent="space-between">
            <Dialog
                open={open && contact?.linkedInProfileData?.profile_pic_url}
                onClose={handleCloseDialog}
                PaperProps={{
                    style: {
                        color: 'white',
                        backgroundColor: 'black',
                        boxShadow: 'none',
                    },
                    sx: { borderRadius: "16px" },
                }}
            >
                <img
                    src={contact?.linkedInProfileData?.profile_pic_url}
                    alt={contact?.name}
                />
            </Dialog>
            <Stack direction="row" useFlexGap flexWrap="wrap" spacing={2}>
                {isContactProfilePage && <BackButton color="white" />}
                <ForgeContactAvatar
                    contact={contact}
                    diameter={100}
                    warmthRingThickness={5}
                    onContactTapped={() => onAvatarClicked()}
                />
                <MDBox>
                    <MDTypography variant="h4" fontWeight="bold" color="white">
                        {contact?.name}
                    </MDTypography>
                    <MDTypography color="white">
                        {contact?.jobTitle}{(contact?.jobTitle && contact?.company) && ' | '}{contact?.company}
                    </MDTypography>
                    <Stack
                        direction="row"
                        useFlexGap
                        flexWrap="wrap"
                        alignItems="center"
                        spacing={2}
                        mt={1}
                    >
                        <ContactHeaderHotButton
                            enabled={contact?.emailStrings?.[0]}
                            onClick={() => {
                                window.location.href = `mailto:${contact?.emailStrings?.[0]}`;
                            }}
                        >
                            <MailOutline />
                        </ContactHeaderHotButton>
                        <ContactHeaderHotButton
                            enabled={contact?.phoneStrings?.[0]}
                            onClick={() => {
                                window.location.href = `tel:${contact?.phoneStrings?.[0]}`;
                            }}
                        >
                            <img
                                loading="lazy"
                                width="20"
                                src={contact?.phoneStrings?.[0] ? telephoneBlack : telephoneWhite}
                                alt=""
                            />
                        </ContactHeaderHotButton>
                        <ContactHeaderHotButton
                            enabled={contact?.phoneStrings?.[0]}
                            onClick={() => {
                                window.location.href = `tel:${contact?.phoneStrings?.[0]}`;
                            }}
                        >
                            <SmartphoneOutlined />
                        </ContactHeaderHotButton>
                        <ContactHeaderHotButton
                            enabled={contact?.phoneStrings?.[0]}
                            onClick={() => {
                                window.location.href = `sms:${contact?.phoneStrings?.[0]}`;
                            }}
                        >
                            <ForumOutlined />
                        </ContactHeaderHotButton>
                        <ContactHeaderHotButton
                            enabled={contact?.addresses?.[0]?.value}
                            onClick={() => {
                                window.open(
                                    `https://maps.google.com/?q=${contact?.addresses?.[0]?.value}`,
                                    "_blank"
                                );
                            }}
                        >
                            <LocationOn />
                        </ContactHeaderHotButton>
                        <ContactHeaderHotButton
                            enabled={contact?.linkedInUrl}
                            onClick={() => {
                                window.open(
                                    contact?.linkedInUrl,
                                    "_blank"
                                );
                            }}
                        >
                            <LinkedIn />
                        </ContactHeaderHotButton>
                    </Stack>
                </MDBox>
            </Stack>
            <Stack direction="column">
                <ContactHeaderMenu
                    contact={contact}
                    isOrganizationContact={isOrgContact}
                />
                {creatorMember && <MemberTile
                    member={creatorMember}
                    color="white"
                />}
            </Stack>
        </Stack>
    </Card>
};

export default ContactHeaderCard;