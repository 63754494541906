import { CalendarMonth, RadioButtonChecked } from '@mui/icons-material';
import { Avatar, AvatarGroup, Badge, List, ListItem, ListItemIcon, ListItemText, Stack, Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { AuthContext, ContactsContext } from 'context';
import { RemoteConfigContext } from 'forge/core/services/RemoteConfigContext';
import { ForgeOrganizationMember } from 'forge/organization/types/member';
import ContactWarmthDialog from 'forge/people/contacts/components/ContactWarmthDialog';
import { capitalize } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { CalendarEvent } from 'types/calendar/calendar-event';
import { ForgeAttendee } from 'types/calendar/forge-attendee';
import { getColorWithGradient, getWarmthScore, sortContacts } from 'types/contact/contact';

const styles = createUseStyles({
    avatar: {
        color: "black",
        backgroundColor: "#F7F8FB",
        fontSize: "1rem",
    },
});

export const ForgeAvatarGroup = ({ contacts, event }: { contacts: any[], event?: CalendarEvent }) => {
    // UI
    const classes = styles();

    // Context
    const { warmthAlgorithm } = useContext(RemoteConfigContext);
    const { getCurrentUser } = useContext(AuthContext);

    // Services
    const { user } = getCurrentUser();

    // State
    const [finalContacts, setFinalContacts] = useState<any[]>([]);

    useEffect(() => {
        setFinalContacts(
            sortContacts(
                contacts,
                warmthAlgorithm,
                user.uid,
            )
        );
    }, [contacts]);

    return (<AvatarGroup max={4} classes={{ avatar: classes.avatar }} style={{ paddingLeft: event ? "8px" : null }}>
        {finalContacts.map((contact) => {
            let attendee;

            if (event?.properties) {
                let attendeeIndex = event.properties.attendeesRefs?.map((ref) => ref.id).indexOf(contact.ref?.id);

                if (attendeeIndex !== -1 && event.properties.attendees.length > attendeeIndex) {
                    attendee = event.properties.attendees[attendeeIndex];
                } else {
                    let attendeeIndex = event?.properties?.attendeesResearchRefs?.map((ref) => ref.id).indexOf(contact.ref?.id);
                    if (attendeeIndex !== -1 && event.properties.attendeesResearch.length > attendeeIndex) {
                        attendee = event.properties.attendeesResearch[attendeeIndex];
                    }
                }
            }

            return <ForgeContactAvatar contact={contact} attendee={attendee} enableScoreBadge={false} />;
        })}
    </AvatarGroup>
    );
};

export const ForgeContactAvatar = ({
    contact,
    attendee,
    diameter,
    warmthRingThickness = 3,
    enableScoreBadge = true,
    color = "black",
    background = "#F7F8FB",
    override,
    onContactTapped,
}: {
    contact: any,
    attendee?: ForgeAttendee | null,
    diameter?: number,
    warmthRingThickness?: number,
    enableScoreBadge?: boolean,
    color?: string,
    background?: string,
    override?: {
        warmthScore?: number,
        isUserContact?: boolean
    },
    onContactTapped?: (e: any) => void
}) => {
    // Navigation
    const navigate = useNavigate();

    // Context
    const { contacts, getPossibleUserContacts } = useContext(ContactsContext);
    const { warmthAlgorithm } = useContext(RemoteConfigContext);
    const { getCurrentUser } = useContext(AuthContext);

    // Services
    const { user } = getCurrentUser();

    // State
    const [userContact, setUserContact] = useState<any>();
    const [possibleUserContactsIds, setPossibleUserContactsIds] = useState<string[]>([]);
    const [openWarmthDialog, setOpenWarmthDialog] = useState<boolean>(false);
    const handleClose = () => setOpenWarmthDialog(false);
    useEffect(() => {
        const possibleUserContacts = getPossibleUserContacts();
        if (possibleUserContacts.length > 0) {
            setUserContact(possibleUserContacts[0]);
            setPossibleUserContactsIds(getPossibleUserContacts().map(e => e.id));
        }
    }, [contacts]);

    // Score
    let scores = getWarmthScore(contact, warmthAlgorithm, user.uid);
    let score = override?.warmthScore ?? scores.get("finalScore");
    score = isNaN(score) ? 0 : score;
    let relationshipColor = getColorWithGradient(score);

    // User Contact
    const isUserContact = override?.isUserContact ?? possibleUserContactsIds.includes(contact?.id);

    const onImageTapped = (e: any) => {
        if (contact.ref?.id) {
            e.stopPropagation();
            navigate(`/people/relationships/${contact.ref?.id}`);
        }
    };

    const baseAvatar = (enableScoreBadge: boolean) => {
        let avatar = (<Avatar
            className={"MuiAvatar-root"}
            key={contact?.id}
            alt={contact?.name}
            src={contact?.linkedInProfileData?.profile_pic_url}
            style={{
                color: color,
                backgroundColor: background,
                fontSize: "1rem",
                cursor: "pointer",
                border: `${warmthRingThickness}px solid ${isUserContact ? 'transparent' : relationshipColor}`
            }}
            sx={{ height: diameter, width: diameter }}
            onClick={onContactTapped ?? onImageTapped}
        >
            {contact?.firstName && contact?.firstName[0]}
            {contact?.lastName && contact?.lastName[0]}
        </Avatar>);

        if (!enableScoreBadge || isUserContact) {
            return avatar;
        }

        return (
            <MDBox style={{ height: diameter, width: diameter }}>
                <ContactWarmthDialog
                    open={openWarmthDialog}
                    onClose={handleClose}
                    userContact={userContact}
                    contact={contact}
                    isOwnUser={true}
                    relationshipScore={score}
                    relationshipColor={relationshipColor} />
                <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    sx={{
                        "& .MuiBadge-badge": {
                            color: `${score >= 20 && score < 80 ? "#000" : "#fff"} !important`,
                            backgroundColor: `${relationshipColor} !important`,
                            fontSize: "x-large",
                            height: "40px",
                            width: "40px",
                            borderRadius: "24px",
                        }
                    }}
                    style={{cursor: "pointer"}}
                    variant="standard"
                    badgeContent={`${score}`}
                    onClick={() => { setOpenWarmthDialog(true) }}
                >
                    {avatar}
                </Badge>
            </MDBox>
        );
    }

    if (!attendee) {
        return baseAvatar(enableScoreBadge);
    }

    return (
        <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            sx={{
                "& .MuiBadge-badge": {
                    backgroundColor: attendee?.color,
                    color: '#fff',
                    padding: "3px",
                    minWidth: "14px",
                    minHeight: "14px",
                    height: "auto",
                }
            }}
            variant="standard"
            badgeContent={attendee?.icon}
        >
            {baseAvatar(enableScoreBadge)}
        </Badge>
    );
};

export const ForgeMemberAvatar = ({
    member,
    diameter = 40
}: {
    member: ForgeOrganizationMember,
    diameter?: number,
}) => {
    if (!member) {
        return null;
    }

    return (
        <Avatar
            style={{
                color: "white",
                backgroundColor: "black",
                fontSize: diameter < 30 ? "0.7rem" : "1rem",
                border: '0.1px solid lightgray'
            }}
            sx={{ height: diameter, width: diameter }}
            alt={member.name}
            src={member.profilePictureUrl}
        >
            {member.initials}
        </Avatar>
    );
}
