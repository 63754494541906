import { DocumentReference, DocumentSnapshot, Timestamp } from "firebase/firestore";
import { ForgeOrganizationMember } from "./member";

export class ForgeOrganizationTeam {
    ref: DocumentReference;
    name: string;
    description?: string;
    members: ForgeOrganizationMember[];
    membersRefs: DocumentReference[];
    createdBy: ForgeOrganizationMember;
    createdByRef: DocumentReference;
    createdAt: Date;
    updatedAt: Date;

    // eslint-disable-next-line
    constructor({
        ref,
        name,
        description,
        members,
        membersRefs,
        createdBy,
        createdByRef,
        createdAt,
        updatedAt,
    }: {
        ref?: DocumentReference;
        name: string;
        description?: string;
        members?: ForgeOrganizationMember[];
        membersRefs?: DocumentReference[];
        createdBy?: ForgeOrganizationMember;
        createdByRef?: DocumentReference;
        createdAt?: Date;
        updatedAt?: Date;
    }) {
        this.ref = ref;
        this.name = name;
        this.description = description;
        this.members = members ?? [];
        this.membersRefs = membersRefs ?? [];
        this.createdBy = createdBy;
        this.createdByRef = createdByRef;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }

    toMap(toFirestore = true): any {
        const map = {
            ref: toFirestore ? this.ref : this.ref?.path,
            name: this.name,
            description: this.description,
            membersRefs: this.membersRefs?.map((e) => toFirestore ? e : e?.path),
            createdByRef: toFirestore ? this.createdByRef : this.createdByRef?.path,
            createdAt: toFirestore ? this.createdAt ?? Timestamp.now() : this.createdAt?.valueOf(),
            updatedAt: toFirestore ? this.updatedAt ?? Timestamp.now() : this.updatedAt?.valueOf(),
        };

        return map;
    }

    static fromFirestore(doc: DocumentSnapshot): ForgeOrganizationTeam {
        const data = doc.data();
        return new ForgeOrganizationTeam({
            ref: doc.ref,
            name: data.name,
            description: data.description,
            membersRefs: data.membersRefs,
            createdByRef: data.createdByRef,
            createdAt: data.createdAt != null ? (data.createdAt instanceof Timestamp ? data.createdAt.toDate() : new Date(data.createdAt)) : undefined,
            updatedAt: data.updatedAt != null ? (data.updatedAt instanceof Timestamp ? data.updatedAt.toDate() : new Date(data.updatedAt)) : undefined,
        });
    }
}