import { Card, capitalize } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useContext, useEffect, useState } from "react";
import { DraggingContext } from "../DealPeople";

export function OpportunityAddContactCard({ data }: { data: any }) {
    // Context
    const { isDragging } = useContext(DraggingContext);

    // State
    const [type, setType] = useState<string>('');

    useEffect(() => {
        if (data) {
            setType(data.type);
        }
    }, []);

    return (
        <div className="nodrag" style={{ width: "100%" }}>
            <Card
                style={{
                    border: `3px dotted ${isDragging ? "red" : "lightgray"}`,
                    boxShadow: "none",
                    marginBottom: "8px",
                    cursor: true ? "pointer" : "auto",
                    width: "220px",
                    background: "transparent"
                }}
                onClick={() => data.onClick()}
            >
                <MDTypography
                    variant="body2"
                    textAlign="center"
                    py={2.4}
                    style={{ color: isDragging ? "red" : "gray" }}
                >
                    {isDragging ? 'Remove' : 'Add'} {capitalize(type)} Contact
                </MDTypography>
            </Card>
        </div>
    );
}