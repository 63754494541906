import { useNavigate } from "react-router-dom";
import { StarBorderOutlined } from "@mui/icons-material";
import { Card, Tooltip } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ForgeMemberAvatar } from "forge/core/components/ForgeAvatar";
import { ForgeOrganizationMember } from "forge/organization/types/member";

function MemberCard({
    member,
    subtitle,
    onClick,
}: {
    member: ForgeOrganizationMember,
    subtitle?: any,
    onClick?: any,
}) {
    return (
        <Card
            variant="outlined"
            style={{
                boxShadow: "none",
                border: "1.5px solid black",
                marginBottom: "8px",
                cursor: onClick ? "pointer" : "auto",
                background: 'white'
            }}
            onClick={onClick}
        >
            <MDBox display="flex" alignItems="center" m={1}>
                <MDBox mx={1} my={1} >
                    <Tooltip title={member.name}>
                        <ForgeMemberAvatar member={member} />
                    </Tooltip>
                </MDBox>
                <MDBox ml={0.5} lineHeight={0} sx={{ width: "-webkit-fill-available" }}>
                    <MDBox mt={0.5} />
                    <MDTypography
                        variant="h6"
                        lineHeight={1}
                        color={'black'}
                    >
                        {member.name}
                    </MDTypography>
                    <MDBox mt={0.25} />
                    <MDTypography
                        variant="body2"
                        lineHeight={1}
                        style={{ "font-size": "0.9rem" }}
                        color={'black'}
                    >
                        {subtitle}
                    </MDTypography>
                </MDBox>
            </MDBox>
        </Card>
    );
};

export default MemberCard;