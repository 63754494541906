import meetsLogo from "assets/forge/svgs/google-meet.svg";
import zoomLogo from "assets/forge/svgs/zoom.svg";
import teamsLogo from "assets/forge/svgs/microsoft-teams.svg";

export enum VideoConferencingPlatform {
    meets,
    zoom,
    teams,
}

export const VideoConferencingPlatformExtended = {
    ...VideoConferencingPlatform,
    toString(type: VideoConferencingPlatform): any {
        switch (type) {
            case VideoConferencingPlatform.meets:
                return "Google Meets";
            case VideoConferencingPlatform.zoom:
                return "Zoom";
            case VideoConferencingPlatform.teams:
                return "Microsoft Teams";
            default:
                return "";
        }
    },
    getIcon(type: VideoConferencingPlatform): any {
        switch (type) {
            case VideoConferencingPlatform.meets:
                return meetsLogo;
            case VideoConferencingPlatform.zoom:
                return zoomLogo;
            case VideoConferencingPlatform.teams:
                return teamsLogo;
            default:
                return "";
        }
    },
    getUrlType(type: VideoConferencingPlatform): any {
        switch (type) {
            case VideoConferencingPlatform.meets:
                return 'g-meet';
            case VideoConferencingPlatform.zoom:
                return 'zoom';
            case VideoConferencingPlatform.teams:
                return 'msteams';
            default:
                return "";
        }
    },
    fromUrlType(type: string): VideoConferencingPlatform {
        switch (type) {
            case 'g-meet':
                return VideoConferencingPlatform.meets;
            case 'zoom':
                return VideoConferencingPlatform.zoom;
            case 'msteams':
                return VideoConferencingPlatform.teams;
            default:
                return null;
        }
    }
};