import { ForgeOpportunity, OpportunityStageOption } from "types/pipeline/opportunity";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { User } from "firebase/auth";
import { UserProfileData } from "types/user/user-profile-data";
import { Crm } from "types/pipeline/crm";
import HttpService from "forge/core/services/http.service";
import { ForgeEncryption } from "forge/core/services/encryption";
import { Timestamp } from "firebase/firestore";
import { dateToMap } from "forge/core/utils/schema-parsing";

class PipelineApi {
    private user: User;
    private userProfileData: UserProfileData;
    private encryptionService: ForgeEncryption;

    constructor(user: User, userProfileData: UserProfileData, encryptionService: ForgeEncryption) {
        this.user = user;
        this.userProfileData = userProfileData;
        this.encryptionService = encryptionService;
    }

    createOpportunity = async (opportunity: ForgeOpportunity): Promise<any> => {
        const functions = getFunctions();
        if (process.env.REACT_APP_DEV === "true") {
            connectFunctionsEmulator(functions, "0.0.0.0", 5001);
        }
        const callable = httpsCallable(functions, "pipeline-createopportunity");

        let result;
        try {
            result = await callable({
                "opportunity": opportunity.toMap(false),
                "integration": this.userProfileData?.pipelineUserConfig?.integration ?? '',
            });
        } catch (error) {
            console.error(error);
        }

        return result?.data;
    };

    getOpportunities = async (): Promise<any> => {
        const functions = getFunctions();
        if (process.env.REACT_APP_DEV === "true") {
            connectFunctionsEmulator(functions, "0.0.0.0", 5001);
        }
        const callable = httpsCallable(functions, "pipeline-getopportunities");

        let result;
        try {
            result = await callable({
                "integration": this.userProfileData?.pipelineUserConfig?.integration ?? '',
            });
        } catch (error) {
            console.error(error);
        }

        return result?.data;
    };

    getOpportunityStageOptions = async (): Promise<{ [key: string]: OpportunityStageOption[] }> => {
        const functions = getFunctions();
        if (process.env.REACT_APP_DEV === "true") {
            connectFunctionsEmulator(functions, "0.0.0.0", 5001);
        }
        const callable = httpsCallable(functions, "pipeline-getopportunitystageoptions");

        let result;
        try {
            result = await callable({
                "integration": this.userProfileData?.pipelineUserConfig?.integration ?? '',
            });
        } catch (error) {
            console.error(error);
        }
        const mappedEntries: { [key: string]: OpportunityStageOption[] } = {};

        if (result?.data) {
            for (const [key, value] of Object.entries(result?.data)) {
                const options = (value as any[]).map(e => ({
                    'label': e.label,
                    'displayOrder': e.displayOrder,
                    'metadata': e.metadata,
                    'id': e.id,
                    'archived': e.archived,
                }));
                mappedEntries[key] = options;
            }
        }

        return mappedEntries;
    };

    updateOpportunity = async (opportunity: ForgeOpportunity): Promise<any> => {
        const functions = getFunctions();
        if (process.env.REACT_APP_DEV === "true") {
            connectFunctionsEmulator(functions, "0.0.0.0", 5001);
        }
        const callable = httpsCallable(functions, "pipeline-updateopportunity");

        let result;
        try {
            result = await callable({
                "opportunity": opportunity.toMap(false),
                "integration": this.userProfileData?.pipelineUserConfig?.integration ?? '',
            });
        } catch (error) {
            console.error(error);
        }

        return result?.data;
    };

    addContactToOpportunity = async (opportunity: ForgeOpportunity, contact: any): Promise<any> => {
        const functions = getFunctions();
        if (process.env.REACT_APP_DEV === "true") {
            connectFunctionsEmulator(functions, "0.0.0.0", 5001);
        }
        const callable = httpsCallable(functions, "pipeline-addcontacttoopportunity");

        let result;
        try {
            contact.createdAt = dateToMap(contact.createdAt);
            contact.lastInteraction = dateToMap(contact.lastInteraction);
            contact.updatedAt = dateToMap(contact.updatedAt);
            contact.ref = contact.ref?.path;
            contact.createdBy = contact.createdBy?.path;
            console.log(contact);
            result = await callable({
                "contact": contact,
                "opportunity": opportunity.toMap(false),
                "integration": this.userProfileData?.pipelineUserConfig?.integration ?? '',
            });
        } catch (error) {
            console.error(error);
        }

        // return result?.data;
    };

    removeContactFromOpportunity = async (opportunity: ForgeOpportunity, contact: any): Promise<any> => {
        const functions = getFunctions();
        if (process.env.REACT_APP_DEV === "true") {
            connectFunctionsEmulator(functions, "0.0.0.0", 5001);
        }
        const callable = httpsCallable(functions, "pipeline-removecontactfromopportunity");

        let result;
        try {
            contact.createdAt = dateToMap(contact.createdAt);
            contact.lastInteraction = dateToMap(contact.lastInteraction);
            contact.updatedAt = dateToMap(contact.updatedAt);
            contact.ref = contact.ref?.path;
            contact.createdBy = contact.createdBy?.path;
            console.log(contact);
            result = await callable({
                "contact": contact,
                "opportunity": opportunity.toMap(false),
                "integration": this.userProfileData?.pipelineUserConfig?.integration ?? '',
            });
        } catch (error) {
            console.error(error);
        }

        // return result?.data;
    };

    deleteOpportunity = async (opportunity: ForgeOpportunity): Promise<boolean> => {
        const functions = getFunctions();
        if (process.env.REACT_APP_DEV === "true") {
            connectFunctionsEmulator(functions, "0.0.0.0", 5001);
        }
        const callable = httpsCallable(functions, "pipeline-deleteopportunity");

        try {
            let result = await callable({
                "opportunity": opportunity.toMap(false),
                "integration": this.userProfileData?.pipelineUserConfig?.integration ?? '',
            });
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }

    };

    deleteConnection = async (integration: Crm): Promise<boolean> => {
        const endpoint = `user/connection?integrationId=${integration}`;

        return await HttpService.delete(endpoint);
    };
}

export default PipelineApi;