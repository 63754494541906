import { createContext, useContext, useState, useEffect } from "react";
import { AuthContext } from "context";
import { Question, QuestionCategory, QuestionType } from "types/question";
import { Timestamp } from "firebase/firestore";
import ContactsFirestore from "./firestore";
import KnowledgeApi from "forge/knowledge/services/api";
import { getBachelors } from "types/contact/linkedin-data";
import { Knowledge } from "forge/knowledge/schemas/knowledge";
import { KnowledgeType } from "forge/knowledge/schemas/knowledge-type";
import { OrganizationContext } from "forge/organization/services/OrganizationContext";

interface ListenContextType {
    questions: Question[];
    originQuestions: Question[];
    relationshipsQuestions: Question[];
    familyQuestions: Question[];
    sportsQuestions: Question[];
    educationQuestions: Question[];
    hobbyQuestions: Question[];
    meetQuestions: Question[];
    helpQuestions: Question[];
    commitmentQuestions: Question[];
    entertainmentQuestions: Question[];
    religionQuestions: Question[];
    petQuestions: Question[];
    personalQuestions: Question[];
    getQuestion: (category: QuestionCategory) => Question | null;
    saveAnswer: (response: string, question: Question, selectedContact?: any) => Promise<void>;
}

export const ListenContext = createContext<ListenContextType>({
    questions: [],
    originQuestions: [],
    relationshipsQuestions: [],
    familyQuestions: [],
    sportsQuestions: [],
    educationQuestions: [],
    hobbyQuestions: [],
    meetQuestions: [],
    helpQuestions: [],
    commitmentQuestions: [],
    entertainmentQuestions: [],
    religionQuestions: [],
    petQuestions: [],
    personalQuestions: [],
    getQuestion: (category: QuestionCategory) => null,
    saveAnswer: (response: string, question: Question, selectedContact?: any) => null,
});

export const ListenContextProvider = ({ contact, children }: { contact: any, children: any }) => {
    // Context
    const { getCurrentUser, isEncryptionInitialized } = useContext(AuthContext);
    const { organization } = useContext(OrganizationContext);

    // Services
    let { user, encryptionService, userProfileData, userRef, memberRef } = getCurrentUser();
    const knowledgeApi = new KnowledgeApi(user, userProfileData, organization, encryptionService)
    const contactsFirestore = new ContactsFirestore(user, userProfileData, encryptionService)

    // State
    const [questions, setQuestions] = useState<Question[]>([]);
    const [originQuestions, setOriginQuestions] = useState<Question[]>([]);
    const [relationshipsQuestions, setRelationshipsQuestions] = useState<Question[]>([]);
    const [familyQuestions, setFamilyQuestions] = useState<Question[]>([]);
    const [sportsQuestions, setSportsQuestions] = useState<Question[]>([]);
    const [educationQuestions, setEducationQuestions] = useState<Question[]>([]);
    const [hobbyQuestions, setHobbyQuestions] = useState<Question[]>([]);
    const [meetQuestions, setMeetQuestions] = useState<Question[]>([]);
    const [helpQuestions, setHelpQuestions] = useState<Question[]>([]);
    const [commitmentQuestions, setCommitmentQuestions] = useState<Question[]>([]);
    const [entertainmentQuestions, setEntertainmentQuestions] = useState<Question[]>([]);
    const [religionQuestions, setReligionQuestions] = useState<Question[]>([]);
    const [petQuestions, setPetQuestions] = useState<Question[]>([]);
    const [personalQuestions, setPersonalQuestions] = useState<Question[]>([]);

    useEffect(() => {
        (async () => {
            let questions = await knowledgeApi.getListenQuestions();
            console.log(questions);

            setQuestions((questions["all"] ?? []).map((e: any) => Question.fromMap(e)));
            setOriginQuestions((questions["QuestionCategory.origin"] ?? []).map((e: any) => Question.fromMap(e)));
            setRelationshipsQuestions((questions["QuestionCategory.relationships"] ?? []).map((e: any) => Question.fromMap(e)));
            setFamilyQuestions((questions["QuestionCategory.family"] ?? []).map((e: any) => Question.fromMap(e)));
            setSportsQuestions((questions["QuestionCategory.sports"] ?? []).map((e: any) => Question.fromMap(e)));
            setEducationQuestions((questions["QuestionCategory.education"] ?? []).map((e: any) => Question.fromMap(e)));
            setHobbyQuestions((questions["QuestionCategory.hobbies"] ?? []).map((e: any) => Question.fromMap(e)));
            setMeetQuestions((questions["QuestionCategory.meet"] ?? []).map((e: any) => Question.fromMap(e)));
            setHelpQuestions((questions["QuestionCategory.help"] ?? []).map((e: any) => Question.fromMap(e)));
            setCommitmentQuestions((questions["QuestionCategory.commitment"] ?? []).map((e: any) => Question.fromMap(e)));
            setEntertainmentQuestions((questions["QuestionCategory.entertainment"] ?? []).map((e: any) => Question.fromMap(e)));
            setReligionQuestions((questions["QuestionCategory.religion"] ?? []).map((e: any) => Question.fromMap(e)));
            setPetQuestions((questions["QuestionCategory.pets"] ?? []).map((e: any) => Question.fromMap(e)));
            setPersonalQuestions((questions["QuestionCategory.personal"] ?? []).map((e: any) => Question.fromMap(e)));
        })();
    }, [isEncryptionInitialized]);

    const getQuestion = (category: QuestionCategory): Question | null => {
        switch (category) {
            case QuestionCategory.meet:
                if (meetQuestions.length == 0) return null;
                return meetQuestionsValidator(
                    meetQuestions,
                    contact?.questionsVars ?? {},
                );
            case QuestionCategory.origin:
                if (originQuestions.length == 0) return null;
                return originQuestionsValidator(
                    originQuestions,
                    contact?.questionsVars ?? {},
                    [],
                );
            case QuestionCategory.relationships:
                if (relationshipsQuestions.length == 0) return null;
                return relationshipsQuestionsValidator(
                    relationshipsQuestions,
                    contact?.questionsVars ?? {},
                );
            case QuestionCategory.family:
                if (familyQuestions.length == 0) return null;
                return familyQuestionsValidator(
                    familyQuestions,
                    contact?.questionsVars ?? {},
                );
            case QuestionCategory.sports:
                if (sportsQuestions.length == 0) return null;
                return sportsQuestionsValidator(
                    sportsQuestions,
                    contact?.questionsVars ?? {},
                );
            case QuestionCategory.education:
                if (educationQuestions.length == 0) return null;
                return educationQuestionsValidator(
                    educationQuestions,
                    contact?.questionsVars ?? {},
                );
            case QuestionCategory.hobbies:
                if (hobbyQuestions.length == 0) return null;
                return hobbyQuestionsValidator(
                    hobbyQuestions,
                    contact?.questionsVars ?? {},
                );
            case QuestionCategory.help:
                if (helpQuestions.length == 0) return null;
                return genericQuestionsValidator(
                    helpQuestions,
                    contact?.questionsVars ?? {},
                );
            case QuestionCategory.commitment:
                if (commitmentQuestions.length == 0) return null;
                return genericQuestionsValidator(
                    commitmentQuestions,
                    contact?.questionsVars ?? {},
                );
            case QuestionCategory.entertainment:
                if (entertainmentQuestions.length == 0) return null;
                return genericQuestionsValidator(
                    entertainmentQuestions,
                    contact?.questionsVars ?? {},
                );
            case QuestionCategory.religion:
                if (religionQuestions.length == 0) return null;
                return genericQuestionsValidator(
                    religionQuestions,
                    contact?.questionsVars ?? {},
                );
            case QuestionCategory.personal:
                if (personalQuestions.length == 0) return null;
                return personalQuestionsValidator(
                    personalQuestions,
                    contact?.questionsVars ?? {},
                    contact,
                );
            case QuestionCategory.pets:
                if (petQuestions.length == 0) return null;

                return genericQuestionsValidator(
                    petQuestions,
                    contact?.questionsVars ?? {},
                );
        }
    };

    const validatePredecessors = (question: Question, answers: { [key: string]: any }): boolean => {
        for (const pre of question.nullPredecessors) {
            if (answers[pre] && answers[pre] !== null) {
                return false;
            }
        }

        for (const pre of question.notNullPredecessors) {
            if (!answers[pre]) {
                return false;
            }
        }

        return true;
    }

    const genericQuestionsValidator = (
        questions: Question[],
        answers: { [key: string]: any }
    ): Question | null => {
        for (const question of questions) {
            let toAsk = validatePredecessors(question, answers);
            if (toAsk) {
                return question;
            }
        }
        return null;
    }

    const meetQuestionsValidator = (
        questions: Question[],
        answers: { [key: string]: any }
    ): Question | null => {
        for (const question of questions) {
            let toAsk = validatePredecessors(question, answers);
            if (toAsk) {
                switch (question.id) {
                    case "910":
                        if (answers["firstmetdate"] && answers["firstmetdate"] !== null && answers["firstmetdate"] === "") {
                            toAsk = false;
                        }
                        break;
                    case "920":
                        if (answers["firstmetdate"] && answers["firstmetdate"] !== null && answers["firstmetdate"] !== "") {
                            toAsk = false;
                        }
                        break;
                    case "940":
                        if (answers["introducedbyyesno"] === "No") {
                            toAsk = false;
                        }
                        break;
                    default:
                        break;
                }
            }
            if (toAsk) {
                return question;
            }
        }
        return null;
    }

    const originQuestionsValidator = (
        questions: Question[],
        answers: { [key: string]: any },
        sportsTeamOptions: string[]
    ): Question | null => {
        for (const question of questions) {
            let toAsk = validatePredecessors(question, answers);
            if (toAsk) {
                switch (question.id) {
                    case "100":
                        if (answers["isCurrentLivingZoneItsHometown"] === "Yes") {
                            toAsk = false;
                        }
                        break;
                    case "110":
                        if (!(answers["homecountry"] !== null &&
                            ([
                                "usa",
                                "america",
                                "us",
                                "the states",
                                "the us",
                                "u.s.a.",
                                "the u.s.",
                                "u.s.",
                            ].includes(
                                (answers["homecountry"] as string).toLowerCase()
                            ) ||
                                answers["homecountry"] === "Canada"))) {
                            toAsk = false;
                        }
                        break;
                    case "141":
                    case "142":
                        if (answers["parentsfromhometown"] === "Yes") {
                            toAsk = false;
                        }
                        break;
                    case "160":
                        if (sportsTeamOptions.length > 0) {
                            question.type = QuestionType.checkbox;
                            question.possibleAnswers = sportsTeamOptions;
                        }
                        break;
                    default:
                        break;
                }
            }
            if (toAsk) {
                return question;
            }
        }
        return null;
    }

    const relationshipsQuestionsValidator = (
        questions: Question[],
        answers: { [key: string]: any }
    ): Question | null => {
        for (const question of questions) {
            let toAsk = validatePredecessors(question, answers);
            if (toAsk) {
                switch (question.id) {
                    case "210":
                    case "220":
                    case "230":
                    case "240":
                        if (answers["relationshipnow"] !== "married") {
                            toAsk = false;
                        }
                        break;
                    case "250":
                        if (answers["relationshipnow"] !== "dating") {
                            toAsk = false;
                        }
                        break;
                    case "260":
                        if (answers["relationshipnow"] !== "common-law married") {
                            toAsk = false;
                        }
                        break;
                    case "265":
                        if (answers["relationshipnow"] !== "engaged") {
                            toAsk = false;
                        }
                        break;
                    case "270":
                        if (
                            ![
                                "domestic partnership",
                                "civil union",
                                "long-term relationship",
                            ].includes(answers["relationshipnow"])
                        ) {
                            toAsk = false;
                        }
                        break;
                    case "280":
                        if (
                            ![
                                "dating",
                                "common-law married",
                                "engaged",
                                "domestic partnership",
                                "civil union",
                                "long-term relationship",
                            ].includes(answers["relationshipnow"])
                        ) {
                            toAsk = false;
                        }
                        break;
                    default:
                        break;
                }
            }
            if (toAsk) {
                return question;
            }
        }
        return null;
    }

    const familyQuestionsValidator = (
        questions: Question[],
        answers: { [key: string]: any }
    ): Question | null => {
        for (const question of questions) {
            let toAsk = validatePredecessors(question, answers);
            if (toAsk) {
                switch (question.id) {
                    case "310":
                    case "390":
                        if (!(parseInt(answers["totalchildren"]) === 1)) {
                            toAsk = false;
                        }
                        break;
                    case "330":
                        if (!(parseInt(answers["totalchildren"]) > 0)) {
                            toAsk = false;
                        }
                        break;
                    case "320":
                    case "340":
                    case "410":
                        if (!(parseInt(answers["totalchildren"]) > 1)) {
                            toAsk = false;
                        }
                        break;
                    case "350":
                        if (!(parseInt(answers["totalchildren"]) > 2)) {
                            toAsk = false;
                        }
                        break;
                    case "360":
                        if (!(parseInt(answers["totalchildren"]) > 3)) {
                            toAsk = false;
                        }
                        break;
                    case "370":
                        if (!(parseInt(answers["totalchildren"]) > 4)) {
                            toAsk = false;
                        }
                        break;
                    case "380":
                        if (!(parseInt(answers["totalchildren"]) > 5)) {
                            toAsk = false;
                        }
                        break;
                    case "400":
                        if (answers["hobbywithchildrenyesno"] !== "Yes") {
                            toAsk = false;
                        }
                        break;
                    case "420":
                    case "430":
                    case "440":
                    case "445":
                    case "450":
                    case "455":
                    case "460":
                    case "465":
                    case "470":
                    case "475":
                    case "480":
                    case "485":
                        if (answers["hobbywithchildrenyesno"] !== "Yes" || parseInt(answers["totalchildren"]) <= 1) {
                            toAsk = false;
                        }
                        break;
                    case "435":
                        if (answers["hobbywithchildrenyesno"] !== "No" || parseInt(answers["totalchildren"]) <= 0) {
                            toAsk = false;
                        }
                        break;
                    default:
                        break;
                }
            }
            if (toAsk) {
                return question;
            }
        }
        return null;
    }

    const sportsQuestionsValidator = (
        questions: Question[],
        answers: { [key: string]: any }
    ): Question | null => {
        for (const question of questions) {
            let toAsk = validatePredecessors(question, answers);
            if (toAsk) {
                switch (question.id) {
                    case "501":
                        if (answers["followssportsteamsyesno"] === "No") {
                            toAsk = false;
                        }
                        break;
                    case "571":
                        if (answers["playesfavoriteteamsportyesno"] !== "No") {
                            toAsk = false;
                        }
                        break;
                    case "572":
                        if (answers["playedsportname"] !== "Something else") {
                            toAsk = false;
                        }
                        break;
                    case "573":
                        if (answers["playedsportname"] === "No") {
                            toAsk = false;
                        }
                        break;
                    case "580":
                        if (!["Football", "Soccer", "Baseball", "Basketball", "Hockey", "Volleyball", "Rugby"].includes(answers["playedsportname"])) {
                            toAsk = false;
                        }
                        break;
                    case "595":
                        if (answers["coolinjurystoryyesno"] !== "Yes") {
                            toAsk = false;
                        }
                        break;
                    case "600":
                        if (!(parseInt(answers["totalchildren"]) === 1) || !(answers["favoriteteamlive"] === "Yes")) {
                            toAsk = false;
                        }
                        break;
                    case "605":
                        if (!(parseInt(answers["totalchildren"]) > 1) || !(answers["favoriteteamlive"] === "Yes")) {
                            toAsk = false;
                        }
                        break;
                    default:
                        break;
                }
            }
            if (toAsk) {
                return question;
            }
        }
        return null;
    }

    const educationQuestionsValidator = (
        questions: Question[],
        answers: { [key: string]: any },
    ): Question | null => {
        for (const question of questions) {
            const linkedInBachelors = getBachelors(contact?.linkedInProfileData?.education);
            answers['school1'] = answers['school1'] ?? linkedInBachelors?.school;

            let toAsk = validatePredecessors(question, answers);
            if (toAsk) {
                switch (question.id) {
                    case "700":
                    case "705":
                    case "710":
                    case "730":
                    case "750":
                        toAsk = linkedInBachelors === null || linkedInBachelors === undefined;
                        break;
                    case "705":
                        if (answers["wenttocollegeyesno"] === "No") {
                            toAsk = false;
                        }
                        break;
                    case "720":
                        if (answers["schoolgreeksyesno"] === "No") {
                            toAsk = false;
                        }
                        break;
                    default:
                        break;
                }
            }
            if (toAsk) {
                return question;
            }
        }
        return null;
    }

    const hobbyQuestionsValidator = (
        questions: Question[],
        answers: { [key: string]: any }
    ): Question | null => {
        for (const question of questions) {
            let toAsk = validatePredecessors(question, answers);
            if (toAsk) {
                switch (question.id) {
                    case "800":
                        if (answers["hobbywithchildrenyesno"] === "Yes") {
                            toAsk = false;
                        }
                        break;
                    case "805":
                        if (answers["hobbywithchildrenyesno"] !== "Yes") {
                            toAsk = false;
                        }
                        break;
                    case "806":
                        if (answers["unsharedhobbies"] !== "Yes") {
                            toAsk = false;
                        }
                        break;
                    default:
                        break;
                }
            }
            if (toAsk) {
                return question;
            }
        }
        return null;
    }

    const personalQuestionsValidator = (
        questions: Question[],
        answers: { [key: string]: any },
        contact: any
    ): Question | null => {
        for (const question of questions) {
            let toAsk = true;
            for (const pre of question.nullPredecessors) {
                if (answers[pre] !== null) {
                    toAsk = false;
                    break;
                }
            }
            if (!toAsk) continue;
            for (const pre of question.notNullPredecessors) {
                if (answers[pre] === null) {
                    toAsk = false;
                    break;
                }
            }
            if (toAsk) {
                switch (question.id) {
                    case "1400":
                        if (contact?.birthday !== null) {
                            toAsk = false;
                        }
                        break;
                    case "1410":
                        if (answers["birthdayistoday"] === "No" || contact?.birthdayYear !== null) {
                            toAsk = false;
                        }
                        break;
                    case "1420":
                        if (contact?.birthday !== null) {
                            toAsk = false;
                        }
                        break;
                    default:
                        break;
                }
            }
            if (toAsk) {
                return question;
            }
        }
        return null;
    }

    const saveAnswer = async (
        response: string,
        question: Question,
        selectedContact?: any,
    ) => {
        if (contact) {
            // List answer keys for this question
            let updatedKeys: string[] = [];

            // Deep Copy of Question Vars to avoid messing with other async questions
            let questionsVars = JSON.parse(JSON.stringify(contact.questionsVars ?? {}));

            if (question.isMultipleAnswer) {
                // Parse one input, multiple answers separated by comma
                const regex = /[,&]|(?:\sand\s)/;
                const answersSplit: string[] = response.split(regex).filter(Boolean);

                for (let i = 0; i < question.answer.length; i++) {
                    if (i < answersSplit.length) {
                        questionsVars[question.answer[i]] = answersSplit[i].trim();
                        updatedKeys.push(question.answer[i]);
                    } else {
                        break;
                    }
                }
            } else {
                // Assuming context and _editContactCubit are available
                let now: Date;

                for (const toAnswer of question.answer) {
                    // Flag for Saving Current Date as Answer
                    if (toAnswer === "firstmetdateistoday" && response === "Yes") {
                        questionsVars["firstmetdate"] = new Date().toLocaleDateString(
                            "en-US",
                            { month: 'short', day: 'numeric', year: 'numeric' }
                        );
                        updatedKeys.push("firstmetdate");
                    } else if (toAnswer === "birthdayistoday" && response === "Yes") {
                        now = new Date();
                        questionsVars["birthdaydate"] = now.toLocaleDateString(
                            "en-US",
                            { month: 'short', day: 'numeric' }
                        );
                        updatedKeys.push("birthdaydate");

                        // _editContactCubit.updateContactsBirthday(
                        //     now.getDate(),
                        //     now.getMonth() + 1, // Months are 0-based in JavaScript Date object
                        //     null
                        // );
                    } else if (toAnswer === "birthdaydate") {
                        const date = new Date(response.replaceAll('on', '').trim());

                        // _editContactCubit.updateContactsBirthday(
                        //     date.getDate(),
                        //     date.getMonth() + 1, // Months are 0-based in JavaScript Date object
                        //     date.getFullYear()
                        // );
                    } else if (toAnswer === "howold") {
                        const years = parseInt(response);

                        if (!isNaN(years)) {
                            now = new Date();
                            // _editContactCubit.updateContactsBirthday(
                            //     contact.birthdayDay ?? now.getDate(),
                            //     contact.birthdayMonth ?? now.getMonth() + 1, // Months are 0-based in JavaScript Date object
                            //     now.getFullYear() - years
                            // );
                        }
                    } else if (toAnswer === "isCurrentLivingZoneItsHometown" && response === "Yes") {
                        questionsVars["hometown"] = questionsVars["currentLivingZone"];
                        updatedKeys.push("hometown");
                    }

                    // Conditional to handle plurals
                    if (toAnswer === "degrees") {
                        const matches = (response.match(/,/g) || []).length;
                        question.conditionalAnswerFormats = {
                            "None": "",
                            "default": `Went to {school1} for their {degrees} degree${matches > 0 ? 's' : ''}`
                        };

                        response = response.replaceAll("Masters", "Master's");
                    }

                    questionsVars[toAnswer] = selectedContact?.name ?? response;
                    updatedKeys.push(toAnswer);
                }
            }

            // Manage Conditional Answer Formats
            let answerText = question.getAnswerText(questionsVars);
            question = question.copyWith({ answerFormat: answerText });

            // Clean DatePicker Questions
            if (question.type === QuestionType.datePicker) {
                // let preposition: string | undefined;
                let preposition: string = "on ";

                if (response.includes('in ')) {
                    preposition = 'in ';
                } else if (response.includes('on ')) {
                    preposition = 'on ';
                }

                if (preposition !== undefined) {
                    response = response.replace(preposition, '');
                }

                if (question.answer.length > 0) {
                    const firstAnswer = question.answer[0];
                    questionsVars[firstAnswer] = response;
                    updatedKeys.push(firstAnswer);

                    question = question.copyWith({
                        answerFormat: question.answerFormat.replace(
                            `{${firstAnswer}}`,
                            `${preposition ?? ''}{${firstAnswer}}`
                        )
                    });
                }
            }

            // Save Knowledge
            let newKnowledge: any;
            if (question.answerFormat.trim() !== "") {
                if (question.answer.length == 1 &&
                    ["none", "nobody", ""].includes((questionsVars[question.answer[0]] as String)?.toLowerCase())) {
                    // Do Nothing
                } else {
                    let renderedAnswer = question.renderAnswer(questionsVars, question.answerFormat, contact);

                    newKnowledge = new Knowledge({
                        knowledgeCustomId: `activeListening${question.id}`,
                        questionId: question.id.toString(),
                        answer: renderedAnswer,
                        label: renderedAnswer,
                        contact: contact,
                        contactRef: contact.ref,
                        // relatedContacts: [selectedContact?.ref?.path],
                        type: KnowledgeType.suggestedQuestions,
                        visible: true,
                        createdBy: memberRef ?? userRef,
                        encryptedBy: memberRef ?? userRef,
                    });

                    await knowledgeApi.createKnowledge(
                        contact,
                        newKnowledge,
                        !contact?.isOrganizationContact,
                    );
                }
            }

            // Update Question Vars in Firestore
            let updatedQuestionVars: { [key: string]: any } = {};
            for (const key of updatedKeys) {
                updatedQuestionVars[key] = questionsVars[key];
            }

            await contactsFirestore.saveQuestionsVars(updatedQuestionVars, contact.ref);

            // if ([
            //     QuestionType.openFIDB,
            //     QuestionType.open,
            // ].includes(question.type) && newKnowledge != null) {
            //     let searchTerms = await contactsApi.analyzeKnowledgeFunc(
            //         newKnowledge.answer,
            //         newKnowledge.answer
            //     );

            //     // Update Knowledge
            // }
        }
    }

    return (
        <ListenContext.Provider
            value={{
                questions,
                originQuestions,
                relationshipsQuestions,
                familyQuestions,
                sportsQuestions,
                educationQuestions,
                hobbyQuestions,
                meetQuestions,
                helpQuestions,
                commitmentQuestions,
                entertainmentQuestions,
                religionQuestions,
                petQuestions,
                personalQuestions,
                getQuestion,
                saveAnswer,
            }}
        >
            {children}
        </ListenContext.Provider>
    );
};