import { Done } from "@mui/icons-material";
import { Avatar, Chip, Stack } from "@mui/material";
import MDTypography from "components/MDTypography";
import { AuthContext } from "context";
import ContactCard from "forge/core/components/ContactCard";
import ShareActions from "forge/core/components/ShareActions";
import { forgeFooter } from "forge/core/constants";
import { parseKnowledge } from "forge/core/knowledgeParsing";
import KnowledgeApi from "forge/knowledge/services/api";
import { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { News } from "types/news/news";
import { SearchTerm } from "forge/knowledge/schemas/search-term";
import { NewsContext } from "../../services/NewsContext";
import { Knowledge } from "forge/knowledge/schemas/knowledge";
import { KnowledgeType } from "forge/knowledge/schemas/knowledge-type";
import { OrganizationContext } from "forge/organization/services/OrganizationContext";

const styles = createUseStyles({
    avatar: {
        backgroundColor: "white",
        color: "black",
        height: "fit-content",
        width: "fit-content",
    },
    chipIcon: { color: "black", width: "0.5em", height: "0.5em", margin: "4px" },
});

function NewsAffectedContactCard({
    contact,
    article,
    knowledgeMap,
    onContactAdded,
}: {
    contact: any,
    article: News,
    knowledgeMap: { [key: string]: any[]; },
    onContactAdded: (contact: any, knowledge: any) => void,
}) {
    const classes = styles();

    // Context
    const { getCurrentUser } = useContext(AuthContext);
    const { createShareLog } = useContext(NewsContext);
    const { organization } = useContext(OrganizationContext);

    // Services
    let { user, encryptionService, userProfileData, userRef, memberRef } = getCurrentUser();
    const knowledgeApi = new KnowledgeApi(user, userProfileData, organization, encryptionService)

    // State
    const [activeSearchTerms, setActiveSearchTerms] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        setActiveSearchTerms(article.allSearchTerms.reduce((acc: any, key) => {
            acc[key.searchTerm] = true;
            return acc;
        }, {}));
    }, []);

    const knowledgeLabel = (knowledge: any) => {
        let text = knowledge.answer ?? '';
        text = text.replaceAll("\n\n", "\n");
        const searchTerms = Array.from(new Set(knowledge.searchTerm))
            .filter((term: any) => article.allSearchTerms.map(e => e.searchTerm).includes(term))
            .map(term => `${term}`)
            .join(', ');

        if (knowledge.type === "KnowledgeType.linkedinEducation") {
            text = `Education: ${searchTerms}`;
        } else if (knowledge.type === "KnowledgeType.linkedinExperience") {
            text = `Experience: ${searchTerms}`;
        } else if (knowledge.type === "KnowledgeType.summary") {
            text = `Summary: ${searchTerms}`;
        }

        knowledge.answer = text;
        return knowledge;
    }

    const getInitialText = () => {
        const intros = ["FYI", "Saw this and thought of you", "Is this relevant to you?", "Hope you're doing well"];
        const randomIntro = intros[Math.floor(Math.random() * intros.length)];
        return `${randomIntro}\n\n`;
    }

    const handleEmailAction = (contact: any) => {
        let mailTitle = article.title;
        let mailBody = getInitialText() + (article.description != null
            ? `${article.description}\n\n`
            : '') +
            `${article.url}` +
            `\n\n${forgeFooter}`;

        window.location.href = `mailto:${contact.emailStrings[0]}?subject=${mailTitle}&body=${encodeURIComponent(mailBody)}`;
        createShareLog(article.newsTrendingId, contact.ref?.id);
    }

    const handleSmsAction = (contact: any) => {
        let body = getInitialText() + (article.title != null
            ? `${article.title}\n\n`
            : '') + (article.description != null
                ? `${article.description}\n\n`
                : '') +
            `${article.url}` +
            `\n\n${forgeFooter}`;

        window.location.href = `sms:${contact.phoneStrings[0]}?body=${encodeURIComponent(body)}`;
        createShareLog(article.newsTrendingId, contact.ref?.id);
    }

    const onChipTapped = (searchTerm: SearchTerm) => {
        // Make a copy of the data object
        const updatedData = { ...activeSearchTerms };

        // Toggle the value of the specified key
        updatedData[searchTerm.searchTerm] = !updatedData[searchTerm.searchTerm];

        // Set the state with the updated object
        setActiveSearchTerms(updatedData);
    }

    const handleAddAction = async () => {
        let activeKeys = Object.entries(activeSearchTerms).filter(([key, value]) => value).map(([key, value]) => key);
        let searchTerms = article.allSearchTerms.filter((e) => activeKeys.includes(e.searchTerm));
        let text = `Interested in ${searchTerms.map((e) => e.searchTerm).join(", ")}`;
        let label = `Interested in {${searchTerms.map((e) => e.searchTerm).join("}, {")}}`;

        let newKnowledge = new Knowledge({
            contactRef: contact.ref,
            answer: text,
            optionsSelected: searchTerms,
            options: searchTerms,
            category: searchTerms.map((e) => e.category),
            searchTerm: searchTerms.map((e) => e.searchTerm),
            entity: searchTerms.map((e) => e.searchTerm),
            label: label,
            type: KnowledgeType.user,
            createdBy: memberRef ?? userRef,
            encryptedBy: memberRef ?? userRef,
        });

        await knowledgeApi.createKnowledge(
            contact,
            newKnowledge,
            !contact?.isOrganizationContact,
        );
        onContactAdded(contact, newKnowledge);
    }

    return <ContactCard
        contact={contact}
        isFavoriteEnabled={false}
        enableScoreBadge={false}
        subtitle={
            <Stack direction="row">
                {Object.keys(knowledgeMap).includes(contact.ref!.id) ?
                    <Stack direction="column" style={{ width: "100%" }}>
                        {(knowledgeMap[contact.ref!.id] ?? []).map((knowledge) =>
                            <MDTypography
                                sx={{ "white-space": "break-spaces" }}
                                variant="body2"
                            >
                                • {parseKnowledge(knowledgeLabel(knowledge))}
                            </MDTypography>)}
                    </Stack>
                    : <Stack
                        direction="row"
                        useFlexGap
                        flexWrap="wrap"
                        justifyContent="center"
                        alignItems="center"
                        spacing={0.7}
                        style={{ width: "100%" }}
                    >
                        {article.allSearchTerms.map((searchTerm: SearchTerm) => {
                            let isActive = activeSearchTerms[searchTerm.searchTerm];
                            return <Chip
                                size="small"
                                label={searchTerm.searchTerm}
                                style={isActive ? { backgroundColor: "black", color: "white" } : { backgroundColor: "white", color: "black", textDecoration: 'line-through', border: "1px solid black" }}
                                onClick={() => onChipTapped(searchTerm)}
                                onDelete={() => onChipTapped(searchTerm)}
                                deleteIcon={isActive ?
                                    <Avatar className={classes.avatar}>
                                        <Done className={classes.chipIcon} />
                                    </Avatar> : <></>}
                            />
                        })}
                    </Stack>}
                <ShareActions
                    handleEmailAction={() => handleEmailAction(contact)}
                    handleSmsAction={() => handleSmsAction(contact)}
                    enableAdd={!Object.keys(knowledgeMap).includes(contact.ref!.id)}
                    handleAddAction={handleAddAction}
                />
            </Stack>
        }
    />;
}

export default NewsAffectedContactCard;