import { createContext, useState, useEffect, useContext, useRef } from "react";
import { News } from "types/news/news";
import NewsApi from './api'
import { AuthContext, ContactsContext } from "context";
import { SearchTerm } from "forge/knowledge/schemas/search-term";
import { NewsSortBy } from "types/news/news-filter";
import { useParams } from "react-router-dom";
import NewsFirestoreService from "./firestore";

interface NewsContextType {
    news: News[],
    newsFiltered: News[],
    searchTerms: SearchTerm[],
    availableSearchTerms: SearchTerm[],
    categories: string[],
    loadingNews: boolean,
    getNewsForSearchTerm(originSearchTerm: string): News[],
    sortBy: NewsSortBy,
    setSortBy: React.Dispatch<React.SetStateAction<NewsSortBy>>,
    selectedCategories: string[],
    setSelectedCategories: React.Dispatch<React.SetStateAction<string[]>>,
    createShareLog: (newsId: number, contactId: string) => Promise<void>,
}

export const NewsContext = createContext<NewsContextType>({
    news: [],
    newsFiltered: [],
    searchTerms: [],
    availableSearchTerms: [],
    categories: [],
    loadingNews: false,
    getNewsForSearchTerm: (originSearchTerm: string): News[] => [],
    sortBy: NewsSortBy.recency,
    setSortBy: () => { },
    selectedCategories: [],
    setSelectedCategories: () => { },
    createShareLog: async () => {},
});

export const NewsContextProvider = ({ contact, children }: { contact?: any, children: any }) => {
    const { id } = useParams();
    const { getCurrentUser } = useContext(AuthContext);
    const { contacts, getContacts, getActiveSearchTerms } = useContext(ContactsContext);
    const contactsContextRef = useRef(getContacts); // Ref to hold the context value

    // Services
    const { user, encryptionService, userProfileData } = getCurrentUser();
    const newsFirestoreService = new NewsFirestoreService(user, userProfileData, encryptionService);

    // State
    const [loadingNews, setLoadingNews] = useState<boolean>(true);
    const [news, setNews] = useState<News[]>([]);
    const [affectingTrendingNews, setAffectingTrendingNews] = useState<News[]>([]);
    const [generalTrendingNews, setGeneralTrendingNews] = useState<News[]>([]);

    // Filtering
    const [newsFiltered, setNewsFiltered] = useState<News[]>([]);
    const [sortBy, setSortBy] = useState<NewsSortBy>(NewsSortBy.contacts);
    const [searchTerms, setSearchTerms] = useState<SearchTerm[]>([]);
    const [availableSearchTerms, setAvailableSearchTerms] = useState<SearchTerm[]>([]);
    const [categories, setCategories] = useState<string[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

    useEffect(() => {
        if (id) {
            (async () => {
                if (contact) {
                    let news = await NewsApi.getNews(contact.searchTermsInUse);
                    setNews(news);
                    setLoadingNews(false);
                }
            })()
        } else {
            (async () => {
                // Get Trending News for user
                const response = await NewsApi.getTrendingNews();

                // Match affected contacts
                let news = matchContacts(response);
                setAffectingTrendingNews(news);

                // Get general Trending News & Remove duplicates
                let genericNews = await NewsApi.getTrendingNews(false);
                const ids: Set<number> = new Set(news.map(obj => obj.newsTrendingId));
                genericNews = genericNews.filter((e) => !ids.has(e.newsTrendingId));
                setGeneralTrendingNews(genericNews);

                // Combine News Arrays
                setNews([...news, ...genericNews]);
                setLoadingNews(false);
            })()
        }
    }, [contact]);

    useEffect(() => {
        if (!contact) {
            contactsContextRef.current = getContacts; // Update the ref whenever the context value changes
            setNews([...matchContacts(affectingTrendingNews), ...generalTrendingNews]);
        }
    }, [contacts]);

    useEffect(() => {
        // SearchTerms
        const searchTerms = news.map((e) => e.allSearchTerms).flat();
        const seen = new Set<string>();
        const uniqueSearchTerms = searchTerms.filter((searchTerm) => {
            const term = searchTerm.searchTerm;
            if (!seen.has(term)) {
                seen.add(term);
                return true;
            }
            return false;
        });

        setSearchTerms(
            uniqueSearchTerms
                .sort((a: any, b: any) => a.searchTerm.localeCompare(b.searchTerm))
        );

        // Categories
        // Create a Set to store unique categories
        const uniqueCategories = new Set<string>();

        // Iterate through the array of objects
        uniqueSearchTerms.forEach(searchTerm => {
            // Add the category of each object to the Set
            uniqueCategories.add(searchTerm.category);
        });

        setCategories(Array.from(uniqueCategories));
        applyFilters();
    }, [news]);

    useEffect(() => {
        applyFilters();
    }, [sortBy, selectedCategories, searchTerms]);

    const applyFilters = () => {
        let newsSorted: News[] = [];

        if (sortBy === NewsSortBy.recency) {
            newsSorted = news.sort((a, b) => b.publishedAt - a.publishedAt);
        } else if (sortBy === NewsSortBy.contacts) {
            newsSorted = news.sort((a, b) => b.affectedContacts.length - a.affectedContacts.length);
        }

        let newsFiltered = newsSorted.filter(e => selectedCategories.length == 0 ||
            e.allSearchTerms.some((searchTerm) => selectedCategories.includes(searchTerm.category))
        );

        setAvailableSearchTerms(searchTerms.filter((searchTerm) => selectedCategories.length === 0 || selectedCategories.includes(searchTerm.category)));
        setNewsFiltered(newsFiltered);
    }

    const matchContacts = (news: News[]): News[] => {
        var searchTerms = getActiveSearchTerms();
        return news.map((e: News) => {
            let affectedContacts: any[] = [];

            for (const searchTerm of e.allSearchTerms) {
                if (searchTerms[searchTerm.searchTerm]) {
                    affectedContacts.push(...searchTerms[searchTerm.searchTerm]!);
                }
            }

            e.affectedContacts = Array.from(new Set(affectedContacts));
            e.affectedContactsRecords = contactsContextRef.current(e.affectedContacts);
            return e;
        });
    }

    const getNewsForSearchTerm = (originSearchTerm: string): News[] => {
        return news.filter((e) => e.originSearchTerm == originSearchTerm);
    };

    const createShareLog = (newsId: number, contactId: string): Promise<void> => {
        return newsFirestoreService.createShareLog(newsId, contactId);
    }

    return (
        <NewsContext.Provider
            value={{
                news,
                newsFiltered,
                searchTerms,
                availableSearchTerms,
                categories,
                loadingNews,
                getNewsForSearchTerm,
                sortBy,
                setSortBy,
                selectedCategories,
                setSelectedCategories,
                createShareLog,
            }}
        >
            {children}
        </NewsContext.Provider>
    );
};