import { ForgeOpportunity } from "types/pipeline/opportunity";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { UserProfileData } from "types/user/user-profile-data";
import { Commitment } from "types/commitment";
import { collection, doc, setDoc } from "firebase/firestore";
import { firestoreDb } from "firebase.init";
import { User } from "firebase/auth";
import HttpService from "forge/core/services/http.service";
import { ForgeEncryption } from "forge/core/services/encryption";
import { ForgeOrganization } from "forge/organization/types/organization";

class CommitmentsApi {
    private user: User;
    private userProfileData: UserProfileData;
    private organization: ForgeOrganization;
    private encryptionService: ForgeEncryption;

    constructor(
        user: User,
        userProfileData: UserProfileData,
        organization: ForgeOrganization,
        encryptionService: ForgeEncryption
    ) {
        this.user = user;
        this.userProfileData = userProfileData;
        this.organization = organization;
        this.encryptionService = encryptionService;
    }

    createCommitment = async (
        contact: any,
        commitment: Commitment,
        opportunity?: ForgeOpportunity
    ): Promise<any> => {
        try {
            const functions = getFunctions();
            if (process.env.REACT_APP_DEV === "true") {
                connectFunctionsEmulator(functions, "0.0.0.0", 5001);
            }
            const callable = httpsCallable(functions, "pipeline-createtask");

            if (opportunity) {
                try {
                    let result = await callable({
                        "task": await commitment.toMap(),
                        "opportunity": opportunity.toMap(),
                        "integration": this.userProfileData.pipelineUserConfig.integration,
                    });

                    if (!result) {
                        return;
                    }

                    // Create a reference to the new commitment
                    commitment.ref = doc(firestoreDb, `users/${this.user.uid}/commitments/${result.data}`)
                } catch (error) {
                    console.error(error);
                }
            }

            const endpoint = `commitments?contactId=${contact?.ref.id}`;
            var result = await HttpService.post(endpoint, {
                commitment: await commitment.toMap({
                    encryptionConfig: {
                        encrypted: true,
                        service: this.encryptionService,
                        userProfileData: this.userProfileData,
                        organization: this.organization,
                    }
                })
            });

            return result.success;
        } catch (error) {
            console.warn(error);
        }
    };

    updateCommitment = async (
        contact: any,
        commitment: Commitment,
        opportunity?: ForgeOpportunity
    ): Promise<any> => {
        const functions = getFunctions();
        if (process.env.REACT_APP_DEV === "true") {
            connectFunctionsEmulator(functions, "0.0.0.0", 5001);
        }
        const callable = httpsCallable(functions, "pipeline-updatetask");

        if (opportunity) {
            try {
                let result = await callable({
                    "task": await commitment.toMap(),
                    "opportunity": opportunity.toMap(),
                    "integration": this.userProfileData.pipelineUserConfig.integration,
                });

                if (!result) {
                    return;
                }
            } catch (error) {
                console.error(error);
            }
        }

        const endpoint = `commitments?contactId=${contact?.ref.id}`;
        var result = await HttpService.patch(endpoint, {
            commitment: await commitment.toMap({
                encryptionConfig: {
                    encrypted: true,
                    service: this.encryptionService,
                    userProfileData: this.userProfileData,
                    organization: this.organization,
                }
            })
        });

        return result.success;
    };
}

export default CommitmentsApi;