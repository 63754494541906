import { Close, Done } from "@mui/icons-material";
import { Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function PermissionTile({ permission }: { permission: any }): JSX.Element {
    return (
        <MDBox
            bgColor={"grey-100"}
            borderRadius="lg"
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "row", sm: "row" }}
            py={1}
            pl={2}
            pr={1}
        >
            <Stack direction="row" alignItems="center" spacing={1}>
                {permission.allowed ? <Done style={{ "color": "green" }} /> : <Close style={{ "color": "red" }} />}
                <MDBox>
                    <MDTypography variant="body2" fontWeight="medium" color="text">
                        {permission.label ?? ""}
                    </MDTypography>
                    <MDTypography variant="body2" fontSize="small" color="text">
                        {permission.description}
                    </MDTypography>
                </MDBox>
            </Stack>
        </MDBox>
    );
}

export default PermissionTile;