// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Dialog } from "@mui/material";
import { useState } from "react";
import ConfirmationDialog from "forge/core/components/ConfirmationDialog";

function SettingsDeleteAccount(): JSX.Element {
    // State
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const handleCloseDialogDelete = async (result: boolean) => {
        setOpenDeleteDialog(false);
        if (result) {
            // let deleteResult = await contactsApi.deleteContact(contact);
            // if (deleteResult) {
            //     navigate("/people/relationships");
            // }
        }
    };

    return (
        <Card id="delete-account">
            <ConfirmationDialog
                openDialog={openDeleteDialog}
                handleCloseDialog={handleCloseDialogDelete}
                title="Are you sure you want to delete your account?"
                description="Please note that this action is irreversible."
                confirmText="Delete"
                confirmColor="error"
            />
            <MDBox
                pr={3}
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
            >
                <MDBox p={3} lineHeight={1}>
                    <MDBox mb={1}>
                        <MDTypography variant="h5">Delete Account</MDTypography>
                    </MDBox>
                    <MDTypography variant="button" color="text">
                        Once you delete your account, there is no going back. Please be certain.
                    </MDTypography>
                </MDBox>
                <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                    <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                        <MDButton
                            variant="gradient"
                            color="error"
                            sx={{ height: "100%" }}
                            onClick={() => setOpenDeleteDialog(true)}
                        >
                            delete account
                        </MDButton>
                    </MDBox>
                </MDBox>
            </MDBox>
        </Card>
    );
}

export default SettingsDeleteAccount;