/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState, useContext } from "react";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 PRO React TS examples components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import { createUseStyles } from "react-jss";
import { TableContext } from "forge/core/services/TableContext";
import { Clear } from "@mui/icons-material";

// Declaring props types for DataTable
interface Props {
  tableId: string,
  entriesPerPage?:
  | false
  | {
    defaultValue: number;
    entries: number[];
  };
  canSearch?: boolean;
  showTotalEntries?: boolean;
  table: {
    columns: { [key: string]: any }[];
    rows: { [key: string]: any }[];
  };
  pagination?: {
    variant: "contained" | "gradient";
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
  };
  enablePagination?: boolean,
  isSorted?: boolean;
  noEndBorder?: boolean;
  onRowClick?: (row: any, rowIndex: number) => void;
}

const styles = createUseStyles({
  tableRow: {
    "&:hover": {
      cursor: "pointer"
    }
  },
});

function DataTable({
  tableId,
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  enablePagination = true,
  isSorted,
  noEndBorder,
  onRowClick,
}: Props): JSX.Element {
  let defaultValue: any;
  let entries: any[];

  // Context
  const { tablesState, updateTableState } = useContext(TableContext);
  const tableState = useMemo<any>(() => tablesState[tableId] || {
    data: [],
    currentPage: 0,
    filters: {},
  }, [tableId]);

  if (entriesPerPage) {
    defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : "10";
    entries = entriesPerPage.entries ? entriesPerPage.entries : ["10", "25", "50", "100"];
  }

  const columns = useMemo<any>(() => table.columns, [table]);
  const data = useMemo<any>(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: tableState.currentPage ?? 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  }: any = tableInstance;

  // Custom CSS
  const classes = styles();

  // Set the default value for the entries per page when component mounts
  useEffect(() => {
    let entriesPerPage = localStorage.getItem("entriesPerPage");
    if (entriesPerPage && !isNaN(parseInt(entriesPerPage))) {
      return setPageSize(parseInt(entriesPerPage));
    }

    return setPageSize(defaultValue || 10);
  }, [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value: any) => {
    localStorage.setItem("entriesPerPage", value.toString());
    return setPageSize(value);
  };

  // Render the paginations
  const renderPagination = pageOptions.map((option: any) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }: any) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option: any) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }: any) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(tableState.filters?.globalFilter ?? globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value: any) => {
    setGlobalFilter(value || undefined);
  }, 100);

  useEffect(() => {
    onSearchChange(search);
    updateTableState(tableId, {
      filters: {
        globalFilter: search,
        // columnSorted: tableState.filters?.columnSorted,
        // isSortedDesc: tableState.filters?.isSortedDesc,
      },
    });
  }, [search]);

  // console.log(tableState);
  // useEffect(() => {
  //   // console.log(tableState);
  // }, [tableState]);

  useEffect(() => {
    updateTableState(tableId, { currentPage: pageIndex });
  }, [pageIndex]);

  // useEffect(() => {
  //   if (headerGroups.length > 0 && tableState.filters.columnSorted) {
  //     let column = headerGroups[0].headers?.find((column: any) => column.id === tableState.filters.columnSorted);

  //     if (column) {
  //       column.toggleSortBy(
  //         tableState.filters?.isSortedDesc ?? undefined,
  //         false,
  //       );
  //     }
  //   }
  // }, []);

  // console.log(tableState);
  // const handleSort = (e: any, column: any) => {
  //   column
  //     .getHeaderProps(column.getSortByToggleProps())
  //     .onClick(e);

  //   updateTableState(tableId, {
  //     filters: {
  //       globalFilter: tableState.filters?.globalFilter,
  //       // columnSorted: column.id,
  //       // isSortedDesc: column.isSortedDesc,
  //     },
  //   });
  // };

  // A function that sets the sorted value for the table
  const setSortedValue = (column: any, initialSortedValue?: string) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {enablePagination && (entriesPerPage || canSearch) ? (
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: "5rem" }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </MDTypography>
            </MDBox>
          )}
          {canSearch && (
            <MDBox width="12rem" ml="auto">
              <MDInput
                placeholder="Search..."
                value={search}
                size="small"
                fullWidth
                onChange={({ currentTarget }: any) => {
                  setSearch(currentTarget.value);
                }}
                InputProps={{
                  endAdornment: search
                    ? <Clear
                      fontSize="small"
                      style={{ cursor: "pointer" }}
                      onClick={() => setSearch("")}
                    />
                    : undefined
                }}
              />
            </MDBox>
          )}
        </MDBox>
      ) : null}
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup: any, key: any) => (
            <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, key: any) => {
                // let tmp = column.getHeaderProps(isSorted && column.getSortByToggleProps());
                // delete tmp.onClick;
                return (
                  <DataTableHeadCell
                    key={key}
                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                    // {...tmp}
                    // onClick={(e: any) => handleSort(e, column)}
                    width={column.width ? column.width : "auto"}
                    padding={column.paddingHeader}
                    align={column.align ? column.align : "left"}
                    sorted={column.sorted && setSortedValue(column)}
                    style={{ textTransform: "capitalize", fontSize: "small" }}
                  >
                    {column.render("Header")}
                  </DataTableHeadCell>
                );
              })}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row: any, key: any) => {
            prepareRow(row);
            return (
              <TableRow key={key} onClick={() => onRowClick(row, key)} className={onRowClick ? classes.tableRow : {}} {...row.getRowProps()} >
                {row.cells.map((cell: any, key: any) => {
                  return (
                    <DataTableBodyCell
                      key={key}
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      padding={cell.column.padding}
                      widthSetting={cell.column.widthSetting}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      {enablePagination && <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Showing {entriesStart} to {entriesEnd > rows.length ? rows.length : entriesEnd} of {rows.length} entries
            </MDTypography>
          </MDBox>
        )}
        {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <MDPagination item onClick={() => previousPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(event: any) => {
                    handleInputPagination(event);
                    handleInputPaginationValue(event);
                  }}
                />
              </MDBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <MDPagination item onClick={() => nextPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>}
    </TableContainer>
  );
}

// Declaring default props for DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: ["5", "10", "15", "20", "25", "50", "100"] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

export default DataTable;
