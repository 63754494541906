import rgba from 'assets/theme/functions/rgba';
import { createUseStyles } from 'react-jss';

const styles = createUseStyles({
    circle: {
        width: "10px",
        height: "10px",
        borderRadius: "50%", /* Makes the div circular */
    },
});

const Circle = ({ active, color = "#fff" }: { active: boolean, color?: string }) => {
    const classes = styles();

    return <div
        className={classes.circle}
        style={{ background: active ? color: rgba(color, 0.25) }}></div>;
};

export default Circle;
