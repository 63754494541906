import { Stack } from "@mui/material";
import Circle from "../Circle";

function OnboardingProgress({ size, currentIndex }: { size: number, currentIndex: number }) {

    return (
        <Stack direction="row" spacing={1}>
            {Array.from({ length: size }, (_, index) =>
                <Circle
                    key={index}
                    color={currentIndex === 0 || currentIndex === 5 ? '#fff' : '#000'}
                    active={index <= currentIndex}
                />
            )}
        </Stack>
    );
};

export default OnboardingProgress;