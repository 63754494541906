import { createContext, useState, useEffect, useContext, useRef } from "react";
import { AuthContext, ContactsContext } from "context";
import { ForgeOpportunity } from "types/pipeline/opportunity";
import PipelineFirestoreService from "./firestore";
import PipelineApi from "./api";

interface OpportunitiesContextType {
    opportunities: ForgeOpportunity[],
    loading: boolean,
    showClosed: boolean,
    setShowClosed: React.Dispatch<React.SetStateAction<boolean>>,
    getOpportunity(opportunityId: string): ForgeOpportunity,
    getOpportunities(): Promise<void>,
}

export const OpportunitiesContext = createContext<OpportunitiesContextType>({
    opportunities: [],
    loading: false,
    showClosed: false,
    setShowClosed: () => false,
    getOpportunity: () => undefined,
    getOpportunities: () => undefined,
});

export const OpportunitiesContextProvider = ({ children }: { children: any }) => {
    // Context
    const { getCurrentUser, isEncryptionInitialized } = useContext(AuthContext);
    const { contacts, getContacts } = useContext(ContactsContext);
    const contactsContextRef = useRef(getContacts); // Ref to hold the context value

    // Services
    const { user, encryptionService, userProfileData } = getCurrentUser();
    const pipelineFirestoreService = new PipelineFirestoreService(user, userProfileData, encryptionService);
    const pipelineApi = new PipelineApi(user, userProfileData, encryptionService);

    // State
    const [loading, setLoading] = useState<boolean>(true);
    const [showClosed, setShowClosed] = useState<boolean>(false);
    const [opportunities, setOpportunities] = useState<ForgeOpportunity[]>([]);
    const [personalOpportunities, setPersonalOpportunities] = useState<ForgeOpportunity[]>([]);
    const [organizationOpportunities, setOrganizationOpportunities] = useState<ForgeOpportunity[]>([]);

    useEffect(() => {
        setOpportunities([...personalOpportunities, ...organizationOpportunities]);
    }, [personalOpportunities, organizationOpportunities]);

    useEffect(() => {
        if (isEncryptionInitialized) {
            const unsubscribe = pipelineFirestoreService.getPersonalOpportunitiesLive(
                (newOpportunities) => {
                    setPersonalOpportunities(matchContacts(newOpportunities))
                },
            );

            return () => unsubscribe();
        }
    }, [isEncryptionInitialized]);

    useEffect(() => {
        if (isEncryptionInitialized && userProfileData?.organization?.id) {
            const unsubscribe = pipelineFirestoreService.getOrganizationOpportunitiesLive(
                (newOpportunities) => {
                    setOrganizationOpportunities(matchContacts(newOpportunities))
                },
            );

            return () => unsubscribe();
        }
    }, [isEncryptionInitialized, userProfileData?.organization?.id]);

    useEffect(() => {
        contactsContextRef.current = getContacts; // Update the ref whenever the context value changes
        setOpportunities(matchContacts(opportunities));
    }, [contacts]);

    const matchContacts = (opportunities: ForgeOpportunity[]): ForgeOpportunity[] => {
        return opportunities.map((e: ForgeOpportunity) => {
            e.teamContacts = contactsContextRef.current(e.teamContactsIds);
            e.clientContacts = contactsContextRef.current(e.clientContactsIds);
            return e;
        });
    }

    const getOpportunity = (opportunityId?: string) => {
        if (opportunityId) {
            return opportunities.find((opportunity) => opportunity.id === opportunityId);
        }

        return;
    }

    const getOpportunities = async () => {
        return await pipelineApi.getOpportunities();
    }

    return (
        <OpportunitiesContext.Provider
            value={{
                opportunities,
                loading,
                showClosed,
                setShowClosed,
                getOpportunity,
                getOpportunities,
            }}
        >
            {children}
        </OpportunitiesContext.Provider>
    );
};