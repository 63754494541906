// @mui material components
import CardMedia from "@mui/material/CardMedia";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Avatar } from "@mui/material";
import { SchoolOutlined } from "@mui/icons-material";
import { getKnowledgeTypeIcon } from "forge/knowledge/schemas/knowledge-type";
import { parseKnowledge } from "forge/core/knowledgeParsing";
import { timeFrame } from "types/contact/linkedin-data";

function EducationTile({ education, knowledge, onClick }: { education: any, knowledge: any, onClick?: () => void }): JSX.Element {
    const subtitle = (): string => {
        if (education.degree_name != null && education.field_of_study != null) {
            return education.degree_name! + ", " + education.field_of_study!;
        } else {
            return education.degree_name ?? education.field_of_study;
        }
    }

    return (
        <MDBox
            display="flex"
            alignItems="start"
            lineHeight={1}
            mb={1.5}
            style={{ cursor: "pointer" }}
            onClick={onClick}
        >
            <Avatar sx={{ bgcolor: "lightgray", width: 44, height: 44, marginTop: "4px" }} variant="rounded" src={education?.logo_url} >
                <SchoolOutlined style={{ color: "white" }} />
            </Avatar>
            <MDBox ml={1}>
                {parseKnowledge(knowledge, education.school, "bold")}
                <MDTypography
                    variant="body2"
                    color="text"
                    sx={{ fontSize: "0.9rem", lineHeight: 1.4 }}
                >
                    {subtitle()}
                </MDTypography>
                <MDTypography
                    variant="body2"
                    sx={{ fontSize: "0.8rem", lineHeight: 1.4, color: "grey"  }}
                >
                    {timeFrame(education)}
                </MDTypography>
                <MDTypography
                    variant="body2"
                    color="text"
                    sx={{ fontSize: "0.8rem", lineHeight: 1.4, color: "grey"  }}
                >
                    {education.location}
                </MDTypography>
            </MDBox>
            <MDBox flex={1} />
            {getKnowledgeTypeIcon(knowledge?.type)}
        </MDBox>
    );
}

// Setting default values for the props of NewsCard
EducationTile.defaultProps = {
    education: null,
};

export default EducationTile;