/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts

// Material Dashboard 2 PRO React TS components

// @mui icons
import Icon from "@mui/material/Icon";

// Forge
import Home from "forge/home";

// People
import ContactsManagement from "forge/people/contacts";
import OrganizationManagement from "forge/organization";
import OpportunitiesManagement from "forge/pipeline/opportunities";
import { HandshakeOutlined } from "@mui/icons-material";
import NewsOverview from "forge/outreach/news";
import Settings from "forge/settings";

const routes = [
  {
    type: "collapse",
    noCollapse: true,
    name: "Home",
    key: "/home",
    route: "/home",
    component: <Home />,
    icon: <Icon fontSize="medium">home</Icon>,
  },
  { type: "divider", key: "divider-people" },
  // { type: "title", title: "People", key: "title-people" },
  {
    type: "collapse",
    noCollapse: true,
    name: "People",
    key: "/people/relationships",
    icon: <Icon fontSize="medium">people_outlined</Icon>,
    route: "/people/relationships",
    component: <ContactsManagement />,
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    noCollapse: true,
    name: "Pipeline",
    key: "/pipeline/opportunities",
    icon: <HandshakeOutlined fontSize="medium" />,
    route: "/pipeline/opportunities",
    component: <OpportunitiesManagement />,
  },
  { type: "divider", key: "divider-1" },
  // { type: "title", title: "Outreach", key: "title-outreach" },
  {
    type: "collapse",
    noCollapse: true,
    name: "Outreach",
    key: "/outreach/news",
    route: "/outreach/news",
    component: <NewsOverview />,
    icon: <Icon fontSize="medium">newspaper</Icon>,
  },
  { type: "divider", key: "divider-2" },
  {
    type: "collapse",
    noCollapse: true,
    name: "Organization",
    key: "/organization",
    icon: <Icon fontSize="medium">groups</Icon>,
    route: "/organization",
    component: <OrganizationManagement />,
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Settings",
    key: "/settings",
    route: "/settings",
    component: <Settings />,
    icon: <Icon fontSize="medium">settings</Icon>,
  },
  {
    type: "collapse",
    name: "Logout",
    key: "logout",
    icon: <Icon fontSize="medium">logout</Icon>,
    noCollapse: true,
  },
];

export default routes;
