import { EThree } from "@virgilsecurity/e3kit-browser";
import HttpService from "../http.service";
import { Auth, getAuth } from "firebase/auth";
import { UserProfileData } from "types/user/user-profile-data";

let eThreeInstance: EThree;
export const getEThreeInstance = (): EThree => eThreeInstance

const instantiateVirgilSDK = async (tokenCallback: () => Promise<string>) => {
    eThreeInstance = await EThree.initialize(tokenCallback);
}

export class VirgilService {
    private firebaseAuth: Auth;
    private userProfileData: UserProfileData;
    private userId: string;
    private appId: string;

    constructor() {
        this.firebaseAuth = getAuth();
        instantiateVirgilSDK(this.getVirgilToken);
        this.userId = getAuth().currentUser.uid;
        this.appId = process.env.REACT_APP_VIRGIL_APP_ID;
    }

    getVirgilToken = async () => {
        let token = await this.firebaseAuth.currentUser.getIdToken();
        const tokenEndpoint = "encryption/token";

        // Have to pass auth token because Axios is not initialized yet
        let tokenRequest = await HttpService.get(
            tokenEndpoint,
            undefined,
            { "Authorization": token }
        );

        return tokenRequest.token;
    }

    createIdentity = async ({ password }: { password: string }): Promise<string> => {
        try {
            await eThreeInstance.register();
            await eThreeInstance.backupPrivateKey(password);
            return password;
        } catch (error) {
            console.warn(error);
            return;
        }
    }

    retrieveIdentity = async ({ password }: { password: string }): Promise<void> => {
        try {
            await eThreeInstance.cleanup();
            await eThreeInstance.restorePrivateKey(password);
            // await write(`${firebaseAuth.currentUser?.uid}_PrivateKey`, password);
        } catch (error) {
            console.warn(error);
        }
    }

    // --------------------------------ENCRYPTION-------------------------------

    encrypt = async (data: string, organizationId?: string): Promise<any> => {
        if (organizationId) {
            return this.encryptForOrganization(data, organizationId);
        }

        return this.encryptForIndividual(data);
    }

    private encryptForIndividual = async (data: string): Promise<any> => {
        try {
            let cards = await eThreeInstance?.findUsers([this.userId, this.appId]);
            return await eThreeInstance?.authEncrypt(data, cards);
        } catch (error) {
            try {
                return await eThreeInstance?.authEncrypt(data);
            } catch (error) {
                return null;
            }
        }
    }

    private encryptForOrganization = async (data: string, organizationId: string): Promise<any> => {
        try {
            let cards = await eThreeInstance?.findUsers(this.appId);
            const group = await eThreeInstance?.loadGroup(organizationId, cards);
            return await group.encrypt(data);
        } catch (error) {
            return null;
        }
    }

    // --------------------------------DECRYPTION-------------------------------

    decrypt = async (
        data: string,
        organizationId?: string,
        senderId?: string,
    ): Promise<any> => {
        if (organizationId) {
            return this.decryptForOrganization(data, organizationId, senderId);
        }

        return this.decryptForIndividual(data);
    }

    private decryptForIndividual = async (data: string): Promise<any> => {
        try {
            let cards = await eThreeInstance?.findUsers(this.appId);
            return await eThreeInstance?.authDecrypt(data, cards);
        } catch (error) {
            try {
                return await eThreeInstance?.authDecrypt(data);
            } catch (error) {
                return null;
            }
        }
    }

    private decryptForOrganization = async (
        data: string,
        organizationId: string,
        senderId: string,
    ): Promise<any> => {
        try {
            // Get Group
            let groupCreator = await eThreeInstance?.findUsers(this.appId);
            const group = await eThreeInstance?.loadGroup(organizationId, groupCreator);

            // Get Cards
            let cards = await eThreeInstance?.findUsers(senderId);
            return await group.decrypt(data, cards);
        } catch (error) {
            return null;
        }
    }
}