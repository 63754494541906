
// MUI
import {
    Backdrop,
    Divider,
    Icon,
} from "@mui/material";

// Material Dashboard
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

// Services
import SelectContact from "forge/core/components/SelectContact";

// Images
import spinningSphere from "assets/forge/gifs/spinning-sphere.gif";

function AddContactToOpportunity({
    handleCloseDrawer,
    clientContacts,
    teamContacts,
    addContactType,
}: {
    handleCloseDrawer: (contact: any) => void,
    clientContacts: any[],
    teamContacts: any[],
    addContactType: string,
}) {

    return (
        <div style={{ display: "contents" }}>
            <Backdrop
                sx={{ background: 'rgba(255, 255, 255, 0.5)', zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute' }}
                open={false}
            >
                <img src={spinningSphere} style={{ width: "200px" }} alt="Loading..." />
            </Backdrop>

            {/* Header */}
            < MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="baseline"
                pt={4}
                pb={0.5}
                px={3}
            >
                <MDBox>
                    <MDTypography variant="h5">
                        {"Who do you want to add?"}
                    </MDTypography>
                </MDBox>
                <Icon
                    sx={({ typography: { size }, palette: { dark, white } }) => ({
                        fontSize: `${size.lg} !important`,
                        color: dark.main,
                        stroke: "currentColor",
                        strokeWidth: "2px",
                        cursor: "pointer",
                        transform: "translateY(5px)",
                    })}
                    onClick={() => handleCloseDrawer(null)}
                >
                    close
                </Icon>
            </MDBox >
            <Divider />

            {/* Body */}
            <SelectContact
                onContactSelected={(contact) => handleCloseDrawer(contact)}
                filteredIds={addContactType === "client" ? clientContacts.map((e: any) => e.ref?.id) : teamContacts.map((e: any) => e.ref?.id)}
            />
        </div>

    );
}

// Setting default values for the props of AddContactToOpportunity
AddContactToOpportunity.defaultProps = {
    handleCloseDrawer: null,
};

export default AddContactToOpportunity;