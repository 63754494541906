import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormField from "layouts/pages/account/components/FormField";
import { useContext, useState } from 'react';
import { Autocomplete, Backdrop, Box, MenuItem, Select, Stack, TextField } from '@mui/material';
import MDButton from 'components/MDButton';

// Images
import spinningSphere from "assets/forge/gifs/spinning-sphere.gif";
import { AuthContext } from 'context';
import { MembersContext } from '../../services/MembersContext';
import { ForgeOrganizationMember } from 'forge/organization/types/member';
import { capitalize } from 'lodash';
import { createUseStyles } from 'react-jss';

const styles = createUseStyles({
    formIcon: { alignSelf: "center", height: "1.5em", width: "1.5em", marginRight: "16px" },
    formColor: { alignSelf: "center", height: "1.5em", width: "1.5em" },
    formTile: { display: "flex", alignItems: "flex-end", marginBottom: "12px" },
    root: {
        "& .MuiFormLabel-root": {
            paddingTop: "4px"
        }
    },
    accordionRoot: {
        '&:before': {
            display: 'none',
        },
    },
});

export default function InviteMemberDialog({ open, handleClose }: { open: boolean, handleClose: () => void }) {
    // UI
    const classes = styles();

    // Context
    const { getCurrentUser } = useContext(AuthContext);
    const { inviteMember } = useContext(MembersContext);
    const { user, encryptionService, userProfileData } = getCurrentUser();

    // State
    const [loading, setLoading] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [role, setRole] = useState<string>("representative");
    const [email, setEmail] = useState<string>();

    const onSubmit = async (event: any) => {
        event.preventDefault();
        setLoading(true);

        const organization = new ForgeOrganizationMember({
            email,
            firstName,
            lastName,
            role,
        });
        await inviteMember(organization);

        setLoading(false);
        internalHandleClose();
    }

    const internalHandleClose = () => {
        setFirstName(undefined);
        setLastName(undefined);
        setRole("representative");
        setEmail(undefined);
        setLoading(false);
        handleClose();
    }

    return (
        <Dialog
            open={open}
            onClose={internalHandleClose}
            fullWidth
        >
            <Backdrop
                sx={{ background: 'rgba(255, 255, 255, 0.5)', zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute' }}
                open={loading}
            >
                <img src={spinningSphere} style={{ width: "200px" }} alt="Loading..." />
            </Backdrop>
            <DialogTitle>Invite Member</DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={2}>
                    <FormField
                        label="First Name"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e: any) => {
                            setFirstName(e.target.value);
                        }}
                    /><FormField
                        label="Last Name"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e: any) => {
                            setLastName(e.target.value);
                        }}
                    />
                    <FormField
                        label="Email"
                        placeholder="Email"
                        value={email}
                        onChange={(e: any) => {
                            setEmail(e.target.value);
                        }}
                    />
                    <Autocomplete
                        id="role-select"
                        sx={{ width: "100%" }}
                        style={{ flex: 1 }}
                        autoHighlight
                        options={["representative", "manager", "admin"]}
                        value={role}
                        getOptionLabel={(option) => capitalize(option)}
                        onChange={(event: any, newValue: string) => setRole(newValue)}
                        renderOption={(props, option) => (
                            <Box
                                component="li"
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                {...props}
                            >
                                {option}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className={classes.root}
                                label="Role"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password", // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <MDButton variant="outlined" autoFocus onClick={internalHandleClose} color={"dark"}>
                    Cancel
                </MDButton>
                <MDButton disabled={!(email && role)} onClick={onSubmit} color={"dark"}>
                    Invite
                </MDButton>
            </DialogActions>
        </Dialog>
    );
}