import { LinkedIn } from "@mui/icons-material";
import { Avatar, Card, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { createUseStyles } from "react-jss";
import { LinkedInProfileOption } from "types/contact/linkedin-profile-option";

const styles = createUseStyles({
    lightGray: { color: "darkgray" },
});

function LinkedInOptionCard({
    linkedInOption,
    onClick,
}: {
    linkedInOption: LinkedInProfileOption,
    onClick: () => void
}) {
    const classes = styles();

    const onLinkedInLogoTapped = (e: any) => {
        e.preventDefault();
        window.open(linkedInOption.link, "_blank");
    }

    return (
        <Card style={{ marginBottom: 16, cursor: "pointer" }} onClick={onClick}>
            <Stack direction="row" alignItems="center" spacing={0.5} style={{ margin: 8 }}>
                <Avatar
                    src={linkedInOption.profilePicture}
                    style={{ backgroundColor: "black", fontSize: "1rem" }}
                >
                    {linkedInOption.initials}
                </Avatar>
                <Stack direction="column" alignItems="start" >
                    <MDTypography variant="body2" ml={1} >
                        {linkedInOption.fullName}
                    </MDTypography>
                    <MDTypography variant="body2" fontSize="small" ml={1} className={classes.lightGray} >
                        {linkedInOption.cleanTitle}
                    </MDTypography>
                </Stack>
                <MDBox flex={1} />
                <LinkedIn fontSize="large" onClick={onLinkedInLogoTapped} />
            </Stack>
        </Card>
    );
};

export default LinkedInOptionCard;