import { createContext, useEffect, useState } from "react";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { remoteConfig } from "firebase.init";

interface RemoteConfigContextType {
    commonEmailDomains: string[],
    warmthAlgorithm: any,
}

export const RemoteConfigContext = createContext<RemoteConfigContextType>({
    commonEmailDomains: [],
    warmthAlgorithm: {},
});

export const RemoteConfigContextProvider = ({ children }: { children: any }) => {
    // State
    const [commonEmailDomains, setCommonEmailDomains] = useState<string[]>([]);
    const [warmthAlgorithm, setWarmthAlgorithm] = useState<any>({});

    useEffect(() => {
        fetchAndActivate(remoteConfig)
            .then(() => {
                console.log("Remote Config", "fetchAndActivate", "Success");
                getCommonEmailDomains();
                getWarmthAlgorithm();
            })
            .catch((err) => {
                console.warn("Remote Config", "fetchAndActivate", "Error");
            });
    }, []);


    const getCommonEmailDomains = () => {
        let commonEmailDomains: string[] = [];
        const commonEmailDomainsValue = getValue(remoteConfig, "commonEmailDomains");
        const commonEmailDomainsString = commonEmailDomainsValue.asString();
        if (commonEmailDomainsString) {
            const regex1 = /\[|\]/g;
            const regex2 = /"|"/g;
            commonEmailDomains = commonEmailDomainsString
                .replace(regex1, "")
                .split("\",\"")
                .map((e: any) => e.replace(regex2, ""));
        }

        setCommonEmailDomains(commonEmailDomains);
    };

    const getWarmthAlgorithm = () => {
        let warmthAlgorithm = getValue(remoteConfig, "relationship_score_v2");
        setWarmthAlgorithm(JSON.parse(warmthAlgorithm.asString()));
    }

    return (
        <RemoteConfigContext.Provider
            value={{ commonEmailDomains, warmthAlgorithm }}
        >
            {children}
        </RemoteConfigContext.Provider>
    );
};