import MDBox from "components/MDBox";
import { createUseStyles } from "react-jss";

const styles = createUseStyles({
    diagonal: {
        '.diagonal-line': {
            content: '',
            position: "absolute",
            width: "60px",
            height: "2px",
            transform: "translateY(-100%) rotate(-45deg)",
            backgroundColor: "white",
        }
    },
});

function ContactHeaderHotButton({ enabled, onClick, children }: { enabled: boolean, onClick: () => void, children: any }) {
    const classes = styles();

    return (
        <MDBox
            variant="gradient"
            bgColor={enabled ? "white" : "dark"}
            color={enabled ? "dark" : "white"}
            width="3rem"
            height="3rem"
            borderRadius="md"
            display="flex"
            justifyContent="center"
            alignItems="center"
            shadow="md"
            style={{ cursor: "pointer", border: "2px solid white" }}
            onClick={onClick}
            className={classes.diagonal}
        >
            {!enabled && <div style={{
                content: '',
                position: "absolute",
                width: "60px",
                height: "2px",
                transform: "translateY(-100%) rotate(-45deg)",
                backgroundColor: "white",
            }}></div>}
            {children}
        </MDBox>
    );
}

export default ContactHeaderHotButton;