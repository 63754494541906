import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import MDBox from "components/MDBox";

import bgImage from "assets/forge/images/forge.png";
import { Avatar, AvatarGroup, Card } from "@mui/material";
import { News } from "types/news/news";
import MDTypography from "components/MDTypography";
import { ArrowForward } from "@mui/icons-material";

const formatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
});

function NewsHorizontalCard({ article }: { article: News }) {

    return (
        <Card sx={{ height: "100%" }}>
            <MDBox display="flex" alignItems="center">
                <MDBox >
                    <CardMedia
                        sx={{ height: 70, width: 70, marginTop: 1 }}
                        style={{
                            backgroundSize: article.image ? "100%" : "70%",
                            backgroundColor: "black",
                        }}
                        image={article.image ?? bgImage}
                        title={article.title}
                    />
                </MDBox>
                <MDBox ml={0.25} mr={1} mt={0.5} lineHeight={1.4}>
                    <MDTypography
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "1",
                            WebkitBoxOrient: "vertical",
                        }}
                        mt={1}
                        fontSize="0.7rem"
                        variant="body2"
                        color="gray">
                        {article.source} - {formatter.format(new Date(article.publishedAt * 1000))}
                    </MDTypography>
                    <MDTypography
                        sx={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 3,
                            "white-space": "break-spaces",
                        }}
                        display="block"
                        variant="h5"
                        fontSize="medium"
                        fontWeight="medium">
                        {article.title}
                    </MDTypography>
                </MDBox>
            </MDBox>
            <CardContent sx={{ height: "100%", paddingBottom: 0 }}>
                <Typography
                    sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        "white-space": "break-spaces",
                    }}
                    variant="body2"
                    fontSize="small"
                    color="text.secondary">
                    {article.description}
                </Typography>
            </CardContent>
            <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                lineHeight={1}
                ml={3}                
            >
                <AvatarGroup max={4}>
                    {article.affectedContactsRecords.map((contact) => (
                        <Avatar
                            className={"MuiAvatar-root"}
                            key={contact?.id}
                            src={contact?.linkedInProfileData?.profile_pic_url}
                        />
                    ))}
                </AvatarGroup>
                <CardActions>
                    <Button size="small" onClick={() => window.open(article.url, '_blank')} style={{ textTransform: "none", fontWeight: "400" }}>Read More <ArrowForward /></Button>
                </CardActions>
            </MDBox>
        </Card>
    );
}

// Setting default values for the props of NewsHorizontalCard
NewsHorizontalCard.defaultProps = {
    article: null,
};

export default NewsHorizontalCard;