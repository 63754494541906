import { Box, DialogContent, DialogContentText } from "@mui/material";
import bro from "assets/forge/images/bro.png";

function Relationship5Slide({ }: {}) {

    return (
        <DialogContent>
            <DialogContentText color="white" fontWeight="bold" align='center'>
                {"Pretty simple, huh?"}
            </DialogContentText>
            <DialogContentText color="white" align='center'>
                {"Now let's put it into practice!"}
            </DialogContentText>
            <Box height={16}/>
            <img
                src={bro}
                style={{
                    width: "45%",
                    display: "block",
                    marginLeft: "16%",
                    marginRight: "auto",
                }}
            />
        </DialogContent>
    );
};

export default Relationship5Slide;